import { lowerCase, startCase, upperFirst } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import Icon from '../../display/icons/Icon';
import Link from '../../Link';
import P from '../../text/P';
import { SecondaryRedirectProps } from './BreadcrumbTypes';

export type Props = {
  currentPageText: string | React.ReactNode;
  className?: string;
  redirectPath: string;
  redirectText: string;
  isRedirectTextSentenceCase?: boolean;
  secondClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
} & SecondaryRedirectProps;

const Breadcrumb = styled.ol`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerSm3} 0`};
  margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  list-style: none;
  align-items: center;
`;

const StyledBreadcrumbItem = styled.li`
  display: inline;
  cursor: default;
  padding-bottom: ${({ theme }) => theme.constants.spacerSm2};
  p {
    display: inline;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding-bottom: 0;
  }
`;

const BreadcrumbIcon = styled(Icon)`
  display: inline;
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Breadcrumbs = (props: Props) => {
  const {
    currentPageText,
    className = '',
    secondClassName = '',
    redirectPath,
    redirectText,
    isRedirectTextSentenceCase = true,
    secondRedirectPath,
    secondRedirectText,
    isSecondRedirectTextSentenceCase = true,
  } = props;

  return (
    <nav id='breadcrumbs' title='breadcrumbs'>
      <Breadcrumb className={className}>
        <StyledBreadcrumbItem>
          <Link
            color='monochrome'
            href={redirectPath}
            text={
              isRedirectTextSentenceCase
                ? upperFirst(lowerCase(redirectText))
                : startCase(redirectText)
            }
          />
        </StyledBreadcrumbItem>
        {secondRedirectPath && secondRedirectText && (
          <>
            <BreadcrumbIcon name='chevron-right' size='2xs' weight='regular' />
            <StyledBreadcrumbItem className={secondClassName || className || ''}>
              <Link
                color='monochrome'
                href={secondRedirectPath}
                text={
                  isSecondRedirectTextSentenceCase
                    ? upperFirst(lowerCase(secondRedirectText))
                    : startCase(secondRedirectText)
                }
              />
            </StyledBreadcrumbItem>
          </>
        )}
        <BreadcrumbIcon name='chevron-right' size='2xs' weight='regular' />
        <StyledBreadcrumbItem className={`active ${secondClassName || className || ''}`}>
          <P text={currentPageText} />
        </StyledBreadcrumbItem>
      </Breadcrumb>
    </nav>
  );
};

export default Breadcrumbs;
