import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import fetchFilenameFromResponseHeader from '../../../../../../lib/document_import/fetchFilenameFromResponseHeader';
import getFileExportUrl from '../../../../../../lib/document_import/getFileExportUrl';
import { DocumentImportImage } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import InputField from '../../../../../design_system/Triage/InputField';
import {
  ACCEPTED_FILE_TYPES,
  FILE_EXTENSIONS_REGEX,
  GOOGLE_DRIVE_SCOPE,
} from '../shared/constants/documentImport';
import { DocumentImportProps, FormValues } from '../types';
import {
  DropZoneContainer,
  Dropzone,
  DropzoneAssistiveText,
  DropzoneDescription,
  DropzoneHint,
  DropzoneImage,
} from './styles';

const t = initTranslations('document_import_modal');

const FileUpload = ({ documentImportData, setDocumentImportData }: DocumentImportProps) => {
  const {
    splitFeatures: { googleDocUrlImportEnabled },
  } = useCurrentAccount();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormikContext<FormValues>();
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      setDocumentImportData({
        ...documentImportData,
        file: acceptedFiles[0],
        title: acceptedFiles[0].name.replace(FILE_EXTENSIONS_REGEX, ''),
      });
    },
    // We don't want to change this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    multiple: false,
    accept: ACCEPTED_FILE_TYPES,
    disabled: !!values.documentLink,
    onDrop,
  });

  const handleOAuthSuccess = async (googleUser: TokenResponse) => {
    const accessToken = googleUser.access_token;
    const exportUrl = getFileExportUrl(values.documentLink);

    const response = await fetch(exportUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    if (response.status === 200) {
      const blob = await response.blob();
      const file = new File([blob], 'tmpFile.html', { type: 'text/html' });
      const contentDispositionHeader = response.headers.get('Content-Disposition') || '';

      setDocumentImportData({
        ...documentImportData,
        file,
        title: fetchFilenameFromResponseHeader(contentDispositionHeader),
      });
    } else if (response.status === 404) {
      setFieldError('documentLink', t('errors.file_not_found'));
    } else {
      setFieldError('documentLink', t('errors.unauthorized'));
    }
  };

  const fetchFileByDocumentLink = useGoogleLogin({
    scope: GOOGLE_DRIVE_SCOPE,
    onSuccess: handleOAuthSuccess,
    onError: () => setFieldError('documentLink', t('errors.unauthorized')),
  });

  useEffect(() => {
    if (isSubmitting && isValid && googleDocUrlImportEnabled) {
      fetchFileByDocumentLink();
    }
  }, [isSubmitting, isValid, fetchFileByDocumentLink, googleDocUrlImportEnabled]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      {googleDocUrlImportEnabled && (
        <>
          <InputField
            id='google-document-link'
            label={t('document_import.input_label')}
            name='documentLink'
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={t('document_import.input_placeholder')}
            type='text'
            value={values.documentLink}
          />
          {touched.documentLink && errors.documentLink && (
            <AssistiveText
              id='google-document-link-error'
              text={errors.documentLink}
              type='error'
            />
          )}
        </>
      )}
      <DropZoneContainer isDisabled={!!values.documentLink}>
        <Dropzone {...getRootProps({ className: 'dropzone' })}>
          <input className='dz-hidden-input' {...getInputProps()} />
          <DropzoneImage src={DocumentImportImage} />
          <DropzoneDescription>{t('document_import.dropzone_description')}</DropzoneDescription>
          <DropzoneHint>{t('document_import.dropzone_hint_text')}</DropzoneHint>
        </Dropzone>
      </DropZoneContainer>
      <DropzoneAssistiveText>{t('document_import.dropzone_assistive_text')}</DropzoneAssistiveText>
    </>
  );
};

export default FileUpload;
