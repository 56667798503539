import React, { ChangeEvent, ReactNode } from 'react';
import { Place } from 'react-tooltip';
import styled, { css } from 'styled-components';

import { deprecated, fontSm5 } from '../../../../styled/TypeSystem';
import ControlLabel, {
  IconHoverTooltipProps,
} from '../../../core/Labels/ControlLabel/ControlLabel';
import {
  TooltipNeverCondition,
  TooltipPositiveCondition,
} from '../../../core/Labels/SharedLabelTypes';
import Icon from '../../../display/icons/Icon';
import Tooltip from '../../../display/Tooltip/Tooltip';
import { TruncatedText } from '../../../Triage/TruncatedText';

const CheckboxContainer = styled.label<{
  bottomMargin: string;
  disabled: boolean;
  truncate: boolean;
  centeredCheckboxes: boolean;
}>(
  ({ centeredCheckboxes, bottomMargin, truncate, disabled }) => css`
    display: flex;
    align-items: ${centeredCheckboxes ? 'center' : 'flex-start'};
    margin-bottom: ${bottomMargin};
    cursor: pointer;
    position: relative;

    ${truncate &&
    css`
      overflow: hidden;
    `}

    ${disabled &&
    css`
      cursor: 'not-allowed';
    `}

  &:last-child {
      margin-bottom: 0;
    }

    ${fontSm5};
  `
);

const InputWrapper = styled.span(
  ({ theme: { constants } }) => css`
    /* These cannot use our variables. Please don't edit this. */
    width: 1.25rem;
    margin-right: ${constants.spacerSm3};
  `
);

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  /* this hides the input so we can add our own styles */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type CheckboxStyle = 'full' | 'half';
const StyledCheckbox = styled.div<{
  checkboxStyle: CheckboxStyle;
  checked: boolean;
  invalid?: boolean;
  disabled: boolean;
}>(
  ({ theme: { constants, vars }, checkboxStyle, checked, invalid, disabled }) => css`
    /* These cannot use our variables. Please don't edit this. */
    margin-left: 4px;
    width: 1.09375rem;
    height: 1.09375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${constants.borderRadiusSm};
    box-sizing: border-box;
    color: ${checkboxStyle === 'full' ? vars.foundationSurface1 : vars.textDisabled};
    border: ${constants.borderWidthLg} solid ${disabled ? vars.borderDisabled : vars.borderDefault};

    ${checked &&
    !disabled &&
    checkboxStyle === 'full' &&
    css`
      border-color: ${vars.accentPrimaryDefault};
      background-color: ${vars.accentPrimaryDefault};
    `}

    ${checked &&
    disabled &&
    css`
      border-color: ${vars.borderDisabled};
      background-color: ${vars.borderDisabled};
    `}

    ${invalid &&
    css`
      border-color: ${vars.stateError};
    `}

  //This is needed to add focus state only when keyboard is tabbing. It will look to see if focus is for the hidden checkbox. This is needed for A11y.
  ${HiddenCheckbox}:focus-visible + & {
      outline: ${constants.borderWidthLg} solid ${vars.focusOutlineColor};
    }

    ${HiddenCheckbox}:focus:not(:focus-visible) {
      outline: none;
      box-shadow: 1px 1px 5px rgba(1, 1, 0, 0.7);
    }
  `
);

//We will add this typescript type once we figure out what the ariaLabels will be
// export type LabelProps =
//   | {
//       ariaLabel: string;
//       label?: undefined;
//     }
//   | {
//       ariaLabel?: never;
//       label: ReactNode;
//     };

const SubLabel = styled.label(
  ({ theme: { constants, vars } }) => css`
    display: block;
    font-weight: ${constants.fontRegular};
    color: ${vars.textSubdued};
    margin-top: ${constants.spacerSm1};
    ${deprecated.fontSm3};
  `
);

const LabelWrapper = styled.div<{
  truncate: boolean;
  fullWidthLabel: boolean;
  subLabel?: string | undefined;
}>(
  ({ theme: { constants }, truncate, fullWidthLabel, subLabel }) => css`
    width: ${fullWidthLabel && '100%'};
    /* 1.75rem because 1.25rem is the width of InputWrapper and 0.5rem is the margin right of this element */
    max-width: calc(100% - 1.75rem);
    margin-bottom: ${subLabel && constants.spacerSm3};

    ${truncate && TruncatedText({})}
  `
);

const ChildrenWrapper = styled.div(
  ({ theme: { constants } }) => css`
    margin-top: ${constants.spacerSm3};
  `
);

type CheckboxTooltipProps =
  | (TooltipNeverCondition & {
      tooltipHoverCheckbox?: never;
      tooltipHoverIcon?: never;
      tooltipHoverCheckboxPlacement?: never;
    })
  | (TooltipPositiveCondition & {
      tooltipHoverCheckbox?: never;
      tooltipHoverIcon: boolean;
      tooltipHoverCheckboxPlacement?: Place;
    })
  | (TooltipPositiveCondition & {
      tooltipHoverCheckbox: boolean;
      tooltipHoverIcon?: never;
      tooltipHoverCheckboxPlacement?: Place;
    });

export type Props = {
  label?: ReactNode;
  ariaLabel?: string;
  id: string;
  name: string;
  invalid?: boolean;
  fullWidthLabel?: boolean;
  checked: boolean;
  bottomMargin?: string;
  onCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  checkboxStyle?: CheckboxStyle;
  truncate?: boolean;
  centeredCheckboxes?: boolean;
  subLabel?: string | undefined;
  children?: ReactNode;
} & CheckboxTooltipProps;

const Checkbox = (props: Props) => {
  const {
    ariaLabel,
    name,
    label,
    id,
    invalid = false,
    fullWidthLabel = false,
    checked,
    onCheck,
    bottomMargin,
    className = '',
    disabled = false,
    tooltipText,
    tooltipId,
    checkboxStyle = 'full',
    tooltipHoverCheckbox = false,
    tooltipHoverCheckboxPlacement,
    tooltipHoverIcon = false,
    truncate = false,
    centeredCheckboxes = false,
    subLabel,
    children,
  } = props;

  const isStringDisplay = typeof label === 'string';
  const botMargin = bottomMargin || (isStringDisplay ? '1.563rem' : '0');

  //this is needed to optionally spread the tooltip props based on if they are passed.
  const tooltipIconProps: IconHoverTooltipProps =
    tooltipId && tooltipText && tooltipHoverIcon
      ? { tooltipId, tooltipText, tooltipHoverIcon }
      : {};
  const tooltipHoverCheckboxProps =
    tooltipId && tooltipText && tooltipHoverCheckbox && !tooltipHoverIcon
      ? { 'data-for': tooltipId }
      : {};

  return (
    <CheckboxContainer
      bottomMargin={botMargin}
      centeredCheckboxes={centeredCheckboxes}
      className={className}
      disabled={disabled}
      truncate={truncate}
    >
      <InputWrapper>
        {tooltipHoverCheckboxProps && tooltipText && (
          <Tooltip id={tooltipId} place={tooltipHoverCheckboxPlacement} text={tooltipText} />
        )}
        <>
          <HiddenCheckbox
            aria-label={ariaLabel}
            checked={checked}
            {...tooltipHoverCheckboxProps}
            data-tip={!!tooltipHoverCheckboxProps}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onCheck}
            type='checkbox'
          />
          <StyledCheckbox
            checkboxStyle={checkboxStyle}
            checked={checked}
            className='checkbox-container'
            data-testid={`checkbox-${id}`}
            {...tooltipHoverCheckboxProps}
            data-tip={!!tooltipHoverCheckboxProps}
            disabled={disabled}
            invalid={invalid}
          >
            {checked && (
              <Icon
                {...tooltipHoverCheckboxProps}
                data-tip={!!tooltipHoverCheckboxProps}
                name={checkboxStyle === 'full' ? 'check' : 'minus'}
                weight='solid'
              />
            )}
          </StyledCheckbox>
        </>
      </InputWrapper>

      {isStringDisplay && label && !tooltipHoverCheckbox ? (
        <LabelWrapper fullWidthLabel={fullWidthLabel} subLabel={subLabel} truncate={truncate}>
          <ControlLabel
            disabled={disabled}
            htmlFor={id}
            text={label}
            truncate={truncate}
            {...tooltipIconProps}
          />
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
          {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
        </LabelWrapper>
      ) : (
        <>
          {label && (
            <LabelWrapper fullWidthLabel={fullWidthLabel} truncate={truncate}>
              {label}
            </LabelWrapper>
          )}
        </>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
