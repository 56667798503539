import styled, { css } from 'styled-components';

import { deprecated, fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';
import AvatarsGroup from '../../../../shared/AvatarsGroup/AvatarsGroup';
import { SpaceBetweenWrapper } from '../../../shared/styles';
import { CardTitle, TertiaryButton } from '../shared/styles';

export const StyledAvatarsGroup = styled(AvatarsGroup)`
  /* 7.625rem is the width of the avatar group on hover. this is to prevent resizing problems */
  min-width: 7.625rem;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ActionsWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: auto;
`;

export const CardFooter = styled(SpaceBetweenWrapper)`
  flex-wrap: wrap;
`;

export const Divider = styled.div`
  /* constants.spacerMd2 * 2 to include padding of the CardWrapper */
  width: ${({ theme: { constants } }) => `calc(100% + ${constants.spacerMd2} * 2)`};
  height: 1px;
  /* negative left margin to make the divider full width of the CardWrapper */
  margin-left: -1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: ${({ theme: { vars } }) => vars.borderSurface1};
`;

export const ManageSeatsButton = styled(TertiaryButton)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PerBillingPeriodText = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm4};
`;

export const PerSeatText = styled(PerBillingPeriodText)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const PlanPrice = styled.span<{ strike?: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${({ strike, theme: { vars } }) =>
    strike &&
    css`
      text-decoration-line: line-through;
      color: ${vars.textDisabled};
      font-weight: ${({ theme: { constants } }) => constants.fontRegular};
      margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
    `}
  ${fontMd1};
`;

export const PlanPricePerSeat = styled(PlanPrice)<{ strike?: boolean }>`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${({ strike, theme: { vars } }) =>
    strike &&
    css`
      color: ${vars.textDisabled};
    `}
`;

export const PlanPriceWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const PlanText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  ${deprecated.fontMd2};
`;

export const StyledPlanCardTitle = styled(CardTitle)<{
  planBrandColor: string;
}>`
  color: ${({ planBrandColor }) => planBrandColor};
`;

export const PlanCardBody = styled.div`
  margin-bottom: auto;
`;

export const PlanCardTitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => `calc(${constants.spacerSm1} + ${constants.spacerSm2})`};
  color: ${({ theme: { vars } }) => vars.borderDefault};
  align-items: center;
`;

export const AddonsSectionWrapper = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerMd1} 0;
`;
