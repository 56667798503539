import React from 'react';
import styled from 'styled-components';

import { useContentModal } from '../../../../../contexts/ContentModalContext';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../shared/helpers';

const LinkStyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd1};
`;

const StyledChevronIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledLockIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('curriculums_modal.advanced_settings');

type AdvancedSettingsButtonProps = {
  setShowAdvancedSettings: React.Dispatch<React.SetStateAction<boolean>>;
  showAdvancedSettings: boolean;
  advancedSettingsLocked: boolean;
};

const AdvancedSettingsButton = ({
  advancedSettingsLocked,
  setShowAdvancedSettings,
  showAdvancedSettings,
}: AdvancedSettingsButtonProps) => {
  const { contentTerm } = useContentModal();

  return (
    <LinkStyledButton onClick={() => setShowAdvancedSettings((prev) => !prev)} type='button'>
      {t('title', { contentType: contentTerm.toLowerCase() })}
      <StyledChevronIcon
        name={getChevronIconName({ isActive: showAdvancedSettings, initialDirection: 'up' })}
        weight='solid'
      />
      {advancedSettingsLocked && <StyledLockIcon name='lock' size='2xs' weight='regular' />}
    </LinkStyledButton>
  );
};

export default AdvancedSettingsButton;
