import React, { useCallback } from 'react';

import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import MenuOption from '../../core/MenuOption/MenuOption';
import { MenuOptions } from '../../core/MenuOption/shared';
import Icon from '../../display/icons/Icon';
import OutsideClickHandler from '../../OutsideClickHandler';
import useActiveMenuHandler from '../../useActiveMenuHandler';
import { IconProps } from '../DefaultButton/ButtonTypes';
import {
  ButtonsWrapper,
  DropdownButton,
  Loader,
  MainButton,
  MenuOptionsWrapper,
  MenuWrapper,
} from './styles';
import { SplitButtonProps } from './types';

const t = initTranslations('aria');

const SplitButton = ({
  mainButtonOnClick,
  ref,
  text,
  size,
  buttonType = 'primary',
  loading,
  disabled,
  menuOptions,
  className = '',
  iconName,
  iconWeight = 'regular',
  dropDownClassName = '',
  mainButtonType = 'submit',
  mainMenuButtonId,
  menuId,
}: SplitButtonProps) => {
  const { closeMenu, isMenuOpen, handleMenuClick } = useActiveMenuHandler({ menuId });
  const iconButtonId: RegisteredId = `${mainMenuButtonId}-${
    isMenuOpen ? 'close-menu' : 'open-menu'
  }`;
  const iconProps: IconProps = iconName && iconWeight ? { iconName, iconWeight } : {};
  const triggerClassName = 'menu-options-trigger';

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(e.target as Element).parentElement?.classList.contains(triggerClassName)) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  return (
    <MenuWrapper>
      <ButtonsWrapper>
        <MainButton
          buttonType={buttonType}
          className={className}
          disabled={disabled}
          id={mainMenuButtonId}
          onClick={mainButtonOnClick}
          ref={ref}
          size={size}
          text={text}
          type={mainButtonType}
          {...iconProps}
        />
        {loading ? (
          <Loader mainButtonSize={size} mainButtonType={buttonType}>
            <Icon name='spinner-third' size='xs' spinSpeed='fast' weight='solid' />
          </Loader>
        ) : (
          <div className={triggerClassName}>
            <DropdownButton
              ariaLabel={t('aria_label_split_button_dropdown')}
              buttonSize='sm'
              className={dropDownClassName}
              disabled={disabled}
              id={iconButtonId}
              mainButtonSize={size}
              mainButtonType={buttonType}
              name={`chevron-${isMenuOpen ? 'up' : 'down'}`}
              onClick={handleMenuClick}
              weight='regular'
            />
          </div>
        )}
      </ButtonsWrapper>
      {isMenuOpen && (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <MenuOptionsWrapper>
            <MenuOptions id={menuId}>
              {menuOptions.map((option, index) => {
                return (
                  <MenuOption
                    key={`menu-option-${index}`}
                    {...option}
                    onClick={(e) => {
                      option.onClick(e);
                      closeMenu();
                    }}
                  />
                );
              })}
            </MenuOptions>
          </MenuOptionsWrapper>
        </OutsideClickHandler>
      )}
    </MenuWrapper>
  );
};

export default SplitButton;
