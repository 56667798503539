import styled from 'styled-components';

import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { deprecated, fontMd1, fontSm5 } from '../../../../../styled/TypeSystem';
import RouterLink from '../../../../publicApplication/RouterLink';

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserDetailsWrapper = styled.div`
  max-width: 12rem;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};

  * {
    ${TruncatedText({})};
  }
`;

export const UserTitle = styled.p`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  ${deprecated.fontSm3}
`;

export const CompletionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CompletionScore = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  text-align: end;
  width: 3.5rem;
  ${fontMd1}
`;

export const ProgressBarWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 3.25rem;
`;

export const BoldText = styled.p`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontMd1}
`;

export const StyledRow = styled.div`
  display: flex;
  white-space: pre-wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledDateColumn = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const StyledTime = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5}
`;

export const GroupElement = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerSm2}
    ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  width: max-content;
  cursor: default;
`;

export const AvatarWrapper = styled(RouterLink)`
  padding-right: ${({ theme }) => theme.constants.spacerSm2};
`;
