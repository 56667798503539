import { map } from 'lodash';
import queryString from 'query-string';

import { Users } from '../../../../components/application/curriculums/modals/PeopleAssignedModal';
import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { GroupResponse } from '../../../../redux/services/resourceApis/groups/types';
import { PdfExportResponse } from '../../../../redux/services/resourceApis/publicApplication/types';
import {
  Curriculum,
  CurriculumShowResponse,
  DetailedCurriculum,
  PublicCurriculum,
  SharedCurriculum,
  SimpleCurriculum,
} from '../../../../types';
import { AssignedSubject } from '../../../../types/AssignedSubject';
import { ManageCompletionResponse } from '../../../../types/Curriculum';
import { GroupSubject } from '../../../../types/GroupSubject';
import { trainualApi } from '../../trainualService';
import { curriculumElementListTags } from '../curriculumElements/helpers';
import { CompletionNotification, DoneEditingCurriculumParams } from '../shareChanges/types';
import { assignUsersListTags, detailedCurriculumListTags } from './helpers';
import {
  AdminUpdateCurriculumParams,
  CreateCurriculumParams,
  CurriculumCardsResponse,
  CurriculumEdit,
  CurriculumRequestAccess,
  CurriculumShow,
  CurriculumsAndTopicsResponse,
  DuplicateCurriculumParams,
  FilterParams,
  JobIdsResponse,
  ManageCompletionParams,
  MoveCurriculumData,
  MoveCurriculumSectorParams,
  RequestAccessParams,
  SubjectIdArray,
  ToggleAssignedResponse,
  ToggleAssignmentParams,
  ToggleFavoriteResponse,
  TopicIdArray,
  TopicTag,
  UnassignedSubject,
  UpdateCurriculumParams,
  UpdateCurriculumServiceParams,
  UpdateManageCompletionParams,
} from './types';

export const curriculumsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createCurriculum: builder.mutation<CurriculumShowResponse, CreateCurriculumParams>({
      query: (curriculumValues: CreateCurriculumParams) => ({
        url: 'ajax/curriculums',
        method: 'POST',
        body: { curriculum: { ...curriculumValues } },
      }),
      invalidatesTags: () => [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'User', id: 'CURRENT_USER' },
        { type: 'TaskLists', id: 'LIST' },
      ],
    }),
    updateCurriculum: builder.mutation<undefined, UpdateCurriculumParams>({
      query: ({ id, sector, ...curriculumValues }: UpdateCurriculumParams) => ({
        url: `ajax/curriculums/${id}${sector ? `?sector=${sector}` : ''}`,
        method: 'PUT',
        body: { curriculum: { ...curriculumValues, sector } },
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
    updateCurriculumWithoutInvalidatingTags: builder.mutation<undefined, UpdateCurriculumParams>({
      query: ({ id, sector, ...curriculumValues }: UpdateCurriculumParams) => ({
        url: `ajax/curriculums/${id}${sector ? `?sector=${sector}` : ''}`,
        method: 'PUT',
        body: { curriculum: { ...curriculumValues, sector } },
      }),
    }),
    adminUpdateCurriculum: builder.mutation<undefined, AdminUpdateCurriculumParams>({
      query: ({
        id,
        title,
        emoji,
        description,
        contextQuestions,
      }: AdminUpdateCurriculumParams) => ({
        url: `ajax/curriculums/admin/${id}/update`,
        method: 'PUT',
        body: toSnakeCase({ title, emoji, description, contextQuestions }),
      }),
      onQueryStarted({ id, description }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          curriculumsApi.util.updateQueryData('getCurriculumForEdit', id, (draft) => {
            if (description) {
              draft.description = description;
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Curriculum', id: arg.id },
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
    updateCurriculumService: builder.mutation<undefined, UpdateCurriculumServiceParams>({
      query: ({ id, published, finishCourses = false, description }) => ({
        url: `ajax/curriculums/${id}`,
        method: 'PUT',
        body: { curriculum: { published, finish_courses: finishCourses, description } },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Curriculum', id: arg.id },
        { type: 'TaskLists', id: 'LIST' },
        { type: 'CurriculumAssignment', id: 'LIST' },
        ...curriculumElementListTags({ curriculumId: arg.id }),
      ],
    }),
    getAssignableCurriculums: builder.query<GroupSubject[], void>({
      query: () => ({ url: 'ajax/curriculums/assignable_curriculums' }),
      providesTags: (result) => [
        ...map(result, ({ id }) => ({ type: 'Curriculum', id } as const)),
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
    getCurriculums: builder.query<GroupSubject[], void>({
      query: () => ({ url: 'ajax/curriculums' }),
      providesTags: (result) => [
        ...map(result, ({ id }) => ({ type: 'Curriculum', id } as const)),
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
    getCurriculum: builder.query<CurriculumShow, number | string>({
      query: (id) => ({ url: `ajax/curriculums/${id}` }),
      transformResponse: (response: CurriculumShow) => {
        return {
          /* TODO: Fully transform to camelCase instead of partially */
          ...response,
          curriculum_elements: toCamelCase(response.curriculum_elements),
        };
      },
      providesTags: (result) => {
        return result
          ? [{ type: 'Curriculum', id: result.id } as const, { type: 'Curriculum', id: 'LIST' }]
          : [];
      },
    }),
    getCurriculumPdfExport: builder.query<
      PdfExportResponse,
      { id: string | number; courseIds?: string[] }
    >({
      query: ({ id, courseIds }) => ({
        url: `ajax/curriculums/${id}/pdf_export?${queryString.stringify(
          { course_ids: courseIds },
          { arrayFormat: 'bracket' }
        )}`,
      }),
    }),
    getCurriculumRequestAccess: builder.query<CurriculumRequestAccess, number>({
      query: (id) => ({ url: `ajax/curriculums/${id}/request_access` }),
      providesTags: (result) => {
        return result
          ? [{ type: 'Curriculum', id: result.id } as const, { type: 'Curriculum', id: 'LIST' }]
          : [];
      },
    }),
    getCurriculumForEdit: builder.query<CurriculumEdit, number>({
      query: (id) => ({ url: `ajax/curriculums/admin/${id}/edit` }),
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        return result
          ? [
              { type: 'Curriculum', id: result.id } as const,
              { type: 'Curriculum', id: 'LIST' },
              ...map(result.groups_assigned, ({ id }) => ({ type: 'Group', id } as const)),
              { type: 'Group', id: 'LIST' },
              { type: 'User', id: 'LIST' },
              { type: 'CurriculumAssignment', id: 'LIST' },
              { type: 'Topic', id: 'LIST' },
            ]
          : [];
      },
    }),
    getCurriculumForEditModal: builder.query<CurriculumShowResponse, number>({
      query: (id) => ({ url: `ajax/curriculums/${id}/edit` }),
      providesTags: (result) => {
        return result
          ? [{ type: 'Curriculum', id: result.id } as const, { type: 'Curriculum', id: 'LIST' }]
          : [];
      },
    }),
    getCurriculumCandidates: builder.query<Users, number>({
      query: (id) => ({ url: `ajax/fetch_users_with_candidates/${id}` }),
      providesTags: (result, errors, id) => assignUsersListTags(id),
    }),
    getSimpleCurriculums: builder.query<SimpleCurriculum[], void>({
      query: () => ({ url: 'ajax/curriculums/simple_curriculums' }),
      providesTags: (result) => {
        return result ? [{ type: 'Curriculum', id: 'LIST' as const }] : [];
      },
    }),
    getUserAssignedSubjects: builder.query<AssignedSubject[], { id: number; limit?: number }>({
      query: ({ id, limit }) => ({
        url: `ajax/users/${id}/fetch_assigned_curriculums`,
        params: { limit },
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Curriculum', id: 'LIST' as const }] : [];
      },
      extraOptions: {
        redirectUnauthorized: false,
      },
    }),
    getUnassignedSubjects: builder.query<UnassignedSubject[], { id: number }>({
      query: ({ id }) => ({ url: `ajax/users/${id}/fetch_unassigned_curriculums` }),
      providesTags: (result) => {
        return result ? [{ type: 'Curriculum', id: 'LIST' as const }] : [];
      },
    }),
    updateScoreForUser: builder.mutation<JobIdsResponse, TopicIdArray>({
      query: ({ userId, markCompleteTopicIds, markIncompleteTopicIds }) => ({
        url: `ajax/users/${userId}/update_score_assigned_curriculums`,
        method: 'PUT',
        body: {
          mark_completed_curriculum_element_ids: markCompleteTopicIds,
          mark_incomplete_curriculum_element_ids: markIncompleteTopicIds,
        },
      }),
      invalidatesTags: () => {
        return [
          { type: 'Curriculum', id: 'LIST' as const },
          { type: 'Group', id: 'LIST' },
        ];
      },
    }),
    subjectAssignmentForUser: builder.mutation<JobIdsResponse, SubjectIdArray>({
      query: ({ userId, addSubjectIds, removeSubjectIds, trainingPathSetUuid }) => ({
        url: `ajax/users/${userId}/assign_curriculums`,
        method: 'PUT',
        body: {
          add_curriculum_ids: addSubjectIds,
          remove_curriculum_ids: removeSubjectIds,
          training_path_set_uuid: trainingPathSetUuid,
        },
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'Curriculum', id: 'LIST' as const },
        { type: 'Group', id: 'LIST' },
        { type: 'TrainingPath', id: userId },
      ],
    }),
    toggleUsersAssigned: builder.mutation<ToggleAssignedResponse, ToggleAssignmentParams>({
      query: ({ curriculumId, users }) => ({
        url: `ajax/curriculums/${curriculumId}/toggle_user_assignment`,
        method: 'PUT',
        body: toSnakeCase({ users }),
      }),
      transformResponse: (returnValue: ToggleAssignedResponse) => {
        return toCamelCase<ToggleAssignedResponse>(returnValue);
      },
      invalidatesTags: (result, error, { curriculumId }) => assignUsersListTags(curriculumId),
    }),
    createPublicCurriculum: builder.mutation<undefined, { id: number }>({
      query: ({ id }) => ({
        url: `ajax/curriculums/${id}/public_curriculums`,
        method: 'POST',
        body: { curriculum_id: id },
      }),
      invalidatesTags: (_result, _error, arg) => {
        return [
          { type: 'Curriculum', id: 'LIST' },
          { type: 'Curriculum', id: arg.id },
        ];
      },
    }),
    updatePublicCurriculum: builder.mutation<PublicCurriculum, PublicCurriculum>({
      query: ({ active, copy_curriculum_enabled, id, curriculum_id }: PublicCurriculum) => ({
        url: `ajax/curriculums/${curriculum_id}/public_curriculums/${id}`,
        method: 'PUT',
        body: {
          active,
          copy_curriculum_enabled,
        },
      }),
      invalidatesTags: (_result, _error, arg) => {
        return [
          { type: 'Curriculum', id: 'LIST' },
          { type: 'Curriculum', id: arg.id },
        ];
      },
    }),
    refreshPublicShareLink: builder.mutation<
      SharedCurriculum,
      { id: string; curriculum_id: number }
    >({
      query: ({ id, curriculum_id }) => ({
        url: `ajax/curriculums/${curriculum_id}/public_curriculums/${id}/refresh`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, arg) => {
        return [
          { type: 'Curriculum', id: 'LIST' },
          { type: 'Curriculum', id: arg.id },
        ];
      },
    }),
    duplicateSubject: builder.mutation<{ sector: string }, DuplicateCurriculumParams>({
      query: ({ id, title, description }) => ({
        url: `ajax/curriculums/${id}/copy`,
        body: { title, description },
        method: 'POST',
      }),
      invalidatesTags: ({ sector }: { sector: string }) => {
        return sector
          ? [
              { type: 'Curriculum', id: `CURRICULUM_LIST_${sector}` },
              { type: 'User', id: 'CURRENT_USER' },
              { type: 'Curriculum', id: 'LIST' },
            ]
          : [];
      },
    }),
    getCardCurriculums: builder.query<CurriculumCardsResponse, FilterParams>({
      query: ({ sector, filtersQuery, page }) => ({
        url: `ajax/curriculums/card_view_curriculums?${filtersQuery}`,
        method: 'GET',
        params: { sector, page },
      }),
      providesTags: (_, _error, { sector }) => {
        return [
          { type: 'Curriculum', id: `CURRICULUM_LIST_${sector}` as const },
          { type: 'Curriculum', id: 'LIST' as const },
        ];
      },
      transformResponse: (returnValue: CurriculumCardsResponse) =>
        toCamelCase<CurriculumCardsResponse>(returnValue),
    }),
    getCurriculumGroups: builder.query<GroupResponse[], number>({
      query: (curriculumId) => ({
        url: `ajax/curriculums/${curriculumId}/groups`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Group', id: 'LIST' as const }] : [];
      },
    }),
    exportPdf: builder.mutation<Curriculum, number>({
      query: (id: number) => ({
        url: `ajax/curriculums/${id}/print`,
        method: 'POST',
      }),
    }),
    toggleArchiving: builder.mutation<DetailedCurriculum, number>({
      query: (id: number) => ({
        url: `ajax/curriculums/${id}/toggle_archiving`,
        method: 'PUT',
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
    deleteSubject: builder.mutation<
      DetailedCurriculum & { redirect_url: string },
      { id: number; isEditPageRequest?: boolean }
    >({
      query: ({ id }) => ({
        url: `ajax/curriculums/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, { isEditPageRequest }) => {
        if (isEditPageRequest) {
          return [];
        }
        return detailedCurriculumListTags(result);
      },
    }),
    requestAccess: builder.mutation<DetailedCurriculum, RequestAccessParams>({
      query: ({ curriculumId }: RequestAccessParams) => ({
        url: 'ajax/requests',
        method: 'POST',
        params: { curriculum_id: curriculumId },
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
    getMoveCurriculumData: builder.query<MoveCurriculumData, { id: number }>({
      query: ({ id }) => ({ url: `ajax/curriculums/${id}/move_curriculum_data`, method: 'GET' }),
      providesTags: (result) => {
        return result ? [{ type: 'Curriculum', id: 'LIST' as const }] : [];
      },
    }),
    moveCurriculum: builder.mutation<DetailedCurriculum, MoveCurriculumSectorParams>({
      query: ({ id, params }) => ({
        url: `ajax/curriculums/${id}/move`,
        method: 'POST',
        body: params,
      }),
    }),
    getCurriculumsAndTopics: builder.query<CurriculumsAndTopicsResponse[], void>({
      query: () => ({
        url: `ajax/curriculums/curriculums_and_topics`,
        method: 'GET',
      }),
      providesTags: (result) => {
        const topicTags: TopicTag[] = [];

        return result
          ? [
              { type: 'Curriculum', id: 'LIST' as const },
              { type: 'Topic', id: 'LIST' as const },
              ...map(result, ({ id, courses }) => {
                topicTags.push(...map(courses, ({ id }) => ({ type: 'Topic', id } as TopicTag)));
                return { type: 'Curriculum', id } as const;
              }),
              ...topicTags,
            ]
          : [];
      },
      transformResponse: (returnValue: CurriculumsAndTopicsResponse[]) => {
        return toCamelCase<CurriculumsAndTopicsResponse[]>(returnValue);
      },
    }),
    doneEditingCurriculum: builder.mutation<undefined, DoneEditingCurriculumParams>({
      query: ({
        id,
        clearCompletions,
        completions,
        message,
        shareChangesImmediately,
        dataSource,
      }) => ({
        url: `ajax/curriculums/${id}/done_editing`,
        method: 'POST',
        body: {
          clear_completions: clearCompletions,
          completions,
          message,
          share_changes_immediately: shareChangesImmediately,
          data_source: dataSource,
        },
      }),
      onQueryStarted({ id, clearCompletions }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          curriculumsApi.util.updateQueryData('getCurriculumForEdit', id, (draft) => {
            if (clearCompletions === CompletionNotification.ActionableUpdate) {
              draft.completion_percentage = 0;
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    uploadWithAi: builder.mutation<undefined, FormData>({
      query: (params: FormData) => ({
        url: 'ajax/curriculums/upload_with_ai',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'DocumentUploads', id: 'LIST' }] : [];
      },
    }),
    getManageCurriculum: builder.query<ManageCompletionResponse, ManageCompletionParams>({
      query: ({ id, search, page }) => ({
        url: `ajax/curriculums/${id}/manage_completion_curriculum`,
        method: 'GET',
        params: { search, page },
      }),
      transformResponse: (returnValue: ManageCompletionResponse) => {
        return toCamelCase<ManageCompletionResponse>(returnValue);
      },
      providesTags: (_result, _error, { id }) => [
        { type: 'Curriculum', id } as const,
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
    updateCompletions: builder.mutation<undefined, UpdateManageCompletionParams>({
      query: ({ id, userIds, actionType }) => ({
        url: `ajax/curriculums/${id}/manage_completions`,
        method: 'PUT',
        body: {
          user_ids: userIds,
          action_type: actionType,
        },
      }),
      onQueryStarted({ id, actionType, userIds, search, page }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          curriculumsApi.util.updateQueryData(
            'getManageCurriculum',
            { id, search, page },
            (draft) => {
              draft.users = draft.users.map((user) => {
                if (userIds.includes(user.id)) {
                  user.completion = {
                    ...user.completion,
                    score: actionType === 'clear' ? 0 : 100,
                    completed_at: null,
                  };
                }
                return user;
              });
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    markContentVerified: builder.mutation<undefined, { id: number }>({
      query: ({ id }) => ({
        url: `ajax/curriculums/${id}/mark_content_verified`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, arg) => {
        return [
          { type: 'Curriculum', id: 'LIST' },
          { type: 'Curriculum', id: arg.id },
        ];
      },
    }),
    toggleFavoriteCurriculum: builder.mutation<ToggleFavoriteResponse, number>({
      query: (id: number) => ({ url: `ajax/curriculums/${id}/toggle_favorite`, method: 'PUT' }),
      invalidatesTags: (result) => {
        return result
          ? [
              { type: 'AssignmentsCurriculums', id: 'LIST' as const },
              { type: 'Curriculum', id: 'LIST' as const },
              { type: 'TrainingPath' },
            ]
          : [];
      },
    }),
  }),
});

export const {
  useCreateCurriculumMutation,
  useGetCardCurriculumsQuery,
  useGetCurriculumQuery,
  useGetCurriculumPdfExportQuery,
  useGetAssignableCurriculumsQuery,
  useGetCurriculumForEditQuery,
  useGetCurriculumForEditModalQuery,
  useGetSimpleCurriculumsQuery,
  useGetUserAssignedSubjectsQuery,
  useGetCurriculumGroupsQuery,
  useUpdateScoreForUserMutation,
  useSubjectAssignmentForUserMutation,
  useGetUnassignedSubjectsQuery,
  useCreatePublicCurriculumMutation,
  useUpdatePublicCurriculumMutation,
  useRefreshPublicShareLinkMutation,
  useDuplicateSubjectMutation,
  useExportPdfMutation,
  useDeleteSubjectMutation,
  useRequestAccessMutation,
  useUpdateCurriculumMutation,
  useUpdateCurriculumWithoutInvalidatingTagsMutation,
  useAdminUpdateCurriculumMutation,
  useMoveCurriculumMutation,
  useUpdateCurriculumServiceMutation,
  useGetCurriculumCandidatesQuery,
  useToggleArchivingMutation,
  useGetCurriculumsAndTopicsQuery,
  useToggleUsersAssignedMutation,
  useGetCurriculumRequestAccessQuery,
  useDoneEditingCurriculumMutation,
  useUploadWithAiMutation,
  useGetManageCurriculumQuery,
  useUpdateCompletionsMutation,
  useMarkContentVerifiedMutation,
  useToggleFavoriteCurriculumMutation,
} = curriculumsApi;
