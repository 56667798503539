import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { deprecated, fontLg1 } from '../../../../styled/TypeSystem';

export const AccountStatusBannerWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ backgroundColor, theme: { vars } }) =>
    backgroundColor ? backgroundColor : vars.foundationBase2};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
`;

export const AccountStatusBannerContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountStatusBannerEmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.875rem;
  height: 2.75rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  background: transparent;
  ${fontLg1};
`;

export const AccountStatusBannerMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const AccountStatusBannerMessageTitle = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
  ${deprecated.fontMd2};
`;

export const AccountStatusBannerActionButton = styled(DefaultButton)`
  flex-shrink: 0;
`;
