import React, { useState } from 'react';

import { WatchVideoSvg } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { Size } from '../../../../../types/design_system/Size';
import LightboxVideoPlayer from '../LightboxVideoPlayer/LightboxVideoPlayer';
import {
  Preview,
  VideoPreviewContainer,
  VideoPreviewPlayButton,
  VideoPreviewPlayButtonWrapper,
  VideoPreviewWatchSvg,
} from './styles';

export type VideoPreviewProps = {
  videoUrl: string;
  videoPreviewSrc: string;
  openPlayerCallback?: () => void;
  closePlayerCallback?: () => void;
  showWatchSvg?: boolean;
  size?: Size;
  isResponsive?: boolean;
};

const t = initTranslations('video_preview');

const VideoPreview = ({
  showWatchSvg,
  videoUrl,
  videoPreviewSrc,
  openPlayerCallback,
  closePlayerCallback,
  size = 'md',
  isResponsive = false,
}: VideoPreviewProps) => {
  const [displayVideoPlayer, setDisplayVideoPlayer] = useState(false);

  const handlePlayButtonClick = () => {
    setDisplayVideoPlayer(true);
    openPlayerCallback && openPlayerCallback();
  };

  const handleCloseRequest = () => {
    setDisplayVideoPlayer(false);
    closePlayerCallback && closePlayerCallback();
  };

  return (
    <>
      <VideoPreviewContainer isResponsive={isResponsive} size={size}>
        <Preview
          autoPlay
          className='chromatic-ignore'
          controls={false}
          loop
          muted
          onClick={handlePlayButtonClick}
          preload='auto'
          src={videoPreviewSrc}
        />
        {showWatchSvg && <VideoPreviewWatchSvg src={WatchVideoSvg} />}
        <VideoPreviewPlayButtonWrapper showWatchSvg={!!showWatchSvg} size={size}>
          <VideoPreviewPlayButton
            ariaLabel={t('aria_label')}
            buttonSize='sm'
            className='video-empty-state-play-button'
            name='play'
            onClick={handlePlayButtonClick}
            weight='solid'
          />
        </VideoPreviewPlayButtonWrapper>
      </VideoPreviewContainer>
      {displayVideoPlayer && (
        <LightboxVideoPlayer onCloseRequest={handleCloseRequest} videoUrl={videoUrl} />
      )}
    </>
  );
};

export default VideoPreview;
