import { useFinchConnect } from '@tryfinch/react-connect';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import { CloseButtonIcon } from '../../../../lib/gcsImages';
import humanizeConnectedProvider from '../../../../lib/humanizeConnectedProvider';
import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../redux/domains/modalsSlice/modalsSlice';
import { useFinchExchangeMutation } from '../../../../redux/services/resourceApis/integrations/integrationsApi';
import { FinchProvider } from '../../../../types/IntegrationProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import Hoverable from '../../../design_system/Hoverable';
import { useFlashNotification } from '../../../FlashNotificationContext';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';

const StyledButton = styled(DefaultButton)`
  justify-content: center;
  &:disabled {
    pointer-events: none;
    width: 100%;
  }
`;

const TooltipOverlay = styled.span`
  display: inline-block;
  width: 100%;
`;

interface CloseButtonProps {
  closeToast: () => void;
}

interface CanDisable {
  disabled: boolean;
}

const t = initTranslations('finch_connect');

type FinchConnectProps = {
  canOpen: boolean;
  connectedProvider: string;
  disabled: boolean;
  isFinchSandboxEnabled?: boolean;
  provider: FinchProvider;
};

const FinchConnect = ({
  canOpen,
  connectedProvider,
  disabled,
  isFinchSandboxEnabled,
  provider,
}: FinchConnectProps) => {
  const { flash } = useFlashNotification();
  const { permission } = useCurrentUser();
  const isBillingAdmin = permission === 'billing_admin';
  const { status } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const dispatchShowModal = useDispatchSetShowModal();
  const [finchExchange] = useFinchExchangeMutation();
  const canUpdateBillingPlan = ability.can('update', 'BillingPlan');
  const showUpdateCardButton = isBillingAdmin && status === 'past_due';

  const CloseButton = ({ closeToast }: CloseButtonProps) => (
    <img
      alt='Close Button Icon'
      className='Toastify__close-button Toastify__close-button--info'
      onClick={closeToast}
      src={CloseButtonIcon}
    />
  );

  const [disabledButton, setDisabledButton] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const tooltipText =
    disabledButton || connectedProvider === provider
      ? t('current_provider_disabled_button_tooltip')
      : t('disabled_button_tooltip', { provider: humanizeConnectedProvider(connectedProvider) });

  const onSuccess = ({ code }: { code: string }) => {
    setDisabledButton(true);
    flash('info', t('success'), { autoClose: 20000, closeButton: CloseButton });
    return finchExchange({ code, provider });
  };
  const onError = () => {
    setDisabledButton(false);
    flash('warn', t('error'), { autoClose: 13000 });
  };
  const onClose = () => {
    setDisabledButton(false);
    console.log('User exited Finch Connect');
  };
  const { configs } = usePrivateConfigs();
  const finchClientID = configs['FINCH_CLIENT_ID'];
  const { open } = useFinchConnect({
    clientId: finchClientID || '',
    products: ['directory', 'individual', 'employment'],
    payrollProvider: isFinchSandboxEnabled ? undefined : provider,
    sandbox: isFinchSandboxEnabled ? 'finch' : false,
    onSuccess,
    onError,
    onClose,
  });

  const DefaultButtonComponent = ({ disabled }: CanDisable) => {
    return (
      <StyledButton
        className='finch-connect-btn'
        disabled={disabled}
        fullWidth
        id={`hris-enabled-${humanizeConnectedProvider(provider)}` as RegisteredId}
        onClick={() => open()}
        size='lg'
        text={t('connect', { provider: t(`provider.${provider}`) })}
        type='button'
      />
    );
  };

  const PaywalledButtonComponent = () => {
    const theme = useTheme();

    return (
      <>
        <PaywallTooltip
          description={t(`paywall_tooltip_description`)}
          modifiers={[{ name: 'offset', options: { offset: [135, 0] } }]}
          shouldBeVisible={isButtonHovered}
          title={t(`provider.${provider}`)}
        />
        <Hoverable setIsHovered={(value) => setIsButtonHovered(value)}>
          <StyledButton
            className='finch-connect-btn'
            disabled={!canUpdateBillingPlan}
            fullWidth
            iconColor={theme.vars.textDefault}
            iconName='lock'
            iconWeight='regular'
            id='paywalled-finch-connect-btn'
            onClick={() => canUpdateBillingPlan && navigateToBillingOverlay({ plan: 'scale' })}
            size='lg'
            text={t('connect', { provider: t(`provider.${provider}`) })}
            type='button'
          />
        </Hoverable>
      </>
    );
  };

  const DisabledDefaultButtonComponent = (
    <>
      <Tooltip id='disabled-finch-connect-button-tooltip' text={tooltipText} />
      <TooltipOverlay data-for='disabled-finch-connect-button-tooltip' data-tip>
        {DefaultButtonComponent({ disabled: true })}
      </TooltipOverlay>
    </>
  );

  const UpdateCardButton = (
    <StyledButton
      buttonType='primary'
      id='update-payment-method-button'
      onClick={() => dispatchShowModal('updatePaymentMethod', true)}
      text={t('update_card')}
    />
  );

  if (disabled || disabledButton) return DisabledDefaultButtonComponent;
  if (showUpdateCardButton) return UpdateCardButton;
  if (canOpen) return DefaultButtonComponent({ disabled: false });

  return PaywalledButtonComponent();
};

export default FinchConnect;
