import styled from 'styled-components';

import { mediaBreakpointPxLg, mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { MainSectionContent } from '../../../../shared/FullScreenOverlay/styles';
import { PaymentSummaryWrapper } from '../../../shared/BillingFullScreenOverlay/styles';

const PAYMENT_SECTION_WIDTH_MEDIUM = '20rem';
const PAYMENT_SECTION_WIDTH_LARGE = '26.5rem';

export const StyledMainSectionContent = styled(MainSectionContent)`
  max-width: 36.25rem;
  gap: 4rem;
`;

export const StyledMainSectionWrapper = styled.div`
  display: flex;
  flex-basis: ${({ theme: { constants } }) =>
    `calc(100% - ${PAYMENT_SECTION_WIDTH_MEDIUM} - ${constants.spacerMd3})`};
  justify-content: center;
  margin-left: 0.5rem;
  padding: ${({ theme: { constants } }) =>
    `4.625rem ${constants.spacerMd2} ${constants.spacerLg1}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${({ theme: { constants } }) =>
      `calc(100% - ${PAYMENT_SECTION_WIDTH_LARGE} - ${constants.spacerMd3})`};
  }

  @media (min-width: ${mediaBreakpointPxXl}) {
    padding: ${({ theme: { constants } }) =>
      `4.625rem ${constants.spacerLg3} ${constants.spacerLg1} 0`};
  }
`;

export const StyledPaymentSummaryWrapper = styled(PaymentSummaryWrapper)`
  position: fixed;
  width: ${PAYMENT_SECTION_WIDTH_MEDIUM};
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: ${PAYMENT_SECTION_WIDTH_LARGE};
  }
`;

export const getTrainualPlusBgImg = (color: string, width: number, height: number) =>
  `url('data:image/svg+xml;utf8,${encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 165 59" fill="none"><path d="M94.1667 2.50596C64.8474 0.311334 4.50264 15.1784 2.07881 31.4881C-0.345019 47.7978 47.3297 56.7456 108.44 47.5355C169.55 38.3253 175.629 13.8707 145.701 7.89152C115.773 1.91236 50.1466 8.34929 23.9485 29.4154C-2.24963 50.4814 55.8603 61.583 89.3824 56.1123" stroke="${color}" stroke-width="3" stroke-linecap="round"/></svg>`
  )}') no-repeat center`;
