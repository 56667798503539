import initTranslations from '../../../../../lib/initTranslations';

const t = initTranslations('curriculums_modal.advanced_settings.radio_options');

export const dueDateOptions = [
  { value: '7', text: `7 ${t('days')}` },
  { value: '14', text: `14 ${t('days')}` },
  { value: 'custom', text: t('custom') },
];

export const expirationOptions = [
  { value: '30', text: `30 ${t('days')}` },
  { value: '90', text: `90 ${t('days')}` },
  { value: '365', text: `1 ${t('year')}` },
  { value: 'custom', text: t('custom') },
];

export const verifyContentOptions = [
  {
    value: '30',
    name: 'every',
    text: `${t('every')} 30 ${t('days')}`,
  },
  {
    value: '365',
    name: 'every',
    text: `${t('every')} ${t('year')}`,
  },
  { value: 'custom', name: 'custom-frequency', text: t('custom') },
];
