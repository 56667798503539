import styled from 'styled-components';

import { deprecated, fontSm5 } from '../../../styled/TypeSystem';

export const TextFragment = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: 0;
  font-weight: 400;
  ${deprecated.fontMd2};
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const EmptyActivityCardsListText = styled.section`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm5};
`;
