import React, { useMemo } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../hooks/useWindowResize';
import { DefaultCollapsedLogo } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { useCheckSidebarState } from '../../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { mediaBreakpointXl } from '../../../styled/Breakpoint';
import { routes } from '../../publicApplication/applicationRouter';
import isReactRouterAvailable from './shared/isReactRouterAvailable';
import { LogoWithRoute, LogoWithoutRoute, StyledImage } from './styles';

const t = initTranslations('logo');

type LogoProps = {
  hideLogo: boolean;
};

const Logo = ({ hideLogo }: LogoProps) => {
  const { logoUrl, withDefaultLogo, logoBackgroundColor, slug } = useCurrentAccount();
  const { width } = useWindowResize();
  const isMobile = useMemo(() => width < mediaBreakpointXl, [width]);
  const isExpandedByButtonOrDefaultState = useCheckSidebarState('isExpanded');
  const isExpandedBySidebarHover = useCheckSidebarState('isExpandedBySidebarHover');
  const isExpandedSidebar = isExpandedByButtonOrDefaultState || isExpandedBySidebarHover;
  const homeRoute = routes.home({ slug });
  const logoSrc = useMemo(() => {
    if (isMobile) return logoUrl;

    return !isExpandedSidebar && withDefaultLogo ? DefaultCollapsedLogo : logoUrl;
  }, [isExpandedSidebar, isMobile, logoUrl, withDefaultLogo]);

  return (
    <>
      {isReactRouterAvailable ? (
        <LogoWithRoute hidden={hideLogo} logoBackgroundColor={logoBackgroundColor} to={homeRoute}>
          <StyledImage alt={t('alt_text_logo')} src={logoSrc} />
        </LogoWithRoute>
      ) : (
        <LogoWithoutRoute
          hidden={hideLogo}
          href={homeRoute.href}
          logoBackgroundColor={logoBackgroundColor}
        >
          <StyledImage alt={t('alt_text_logo')} src={logoSrc} />
        </LogoWithoutRoute>
      )}
    </>
  );
};

export default Logo;
