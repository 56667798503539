import { darken } from 'polished';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import { ComposeRoleChartSvg, ComposeSvg } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { useAcceptAiTermsMutation } from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import CoreModal from '../../../design_system/core/CoreModal';
import Icon from '../../../design_system/display/icons/Icon';
import BaseP from '../../../design_system/text/P';
import { deprecated } from '../../../styled/TypeSystem';
import { ComposeModalType } from '../ComposeModalFactory/ComposeModalFactory';
import { Button, H1, ModalHeaderContainer, P } from '../shared/modals/shared';

const TermsP = styled(BaseP)`
  display: inline;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const TermsWrapper = styled.span`
  display: inline-block;
  text-align: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};

  &,
  ${TermsP} {
    font-weight: ${({ theme: { constants } }) => constants.fontMedium};
    ${deprecated.fontSm3};
    /* DS Override: Needed different line-height than type system to look correct */
    line-height: 1.1rem;
  }
`;

const Link = styled.a`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};

  &:active,
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => darken(0.2, vars.trainualBrandPurpleMedium)};
  }
`;

const SvgImage = styled.img`
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('compose.terms_modal');

export type Props = {
  closeModal: () => void;
  composeModalType: ComposeModalType;
};

const ComposeTermsModal = ({ closeModal, composeModalType }: Props) => {
  const { configs } = usePublicConfigs();
  const [acceptTerms, result] = useAcceptAiTermsMutation();
  const { isLoading } = result;

  const termsImage = useMemo(() => {
    if (composeModalType === 'autogenerateRoleChart') {
      return ComposeRoleChartSvg;
    }
    return ComposeSvg;
  }, [composeModalType]);

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='lg'
      heapModalName='compose-terms-modal'
      onCloseRequest={closeModal}
    >
      <ModalHeaderContainer>
        <H1>
          <Icon name='magic-wand-sparkles' />
          {t('title')}
        </H1>
        <P text={t('description')} />
      </ModalHeaderContainer>
      <SvgImage src={termsImage} />
      <Button
        disabled={isLoading}
        fullWidth
        iconName='magic-wand-sparkles'
        id='compose-button-submit'
        loading={isLoading}
        onClick={() => acceptTerms()}
        text={t('submit_title')}
      />
      <TermsWrapper>
        <TermsP text={t('terms')} />
        <Link href={configs['TERMS_URL']} rel='noreferrer' target='_blank'>
          {t('terms_link')}
        </Link>
      </TermsWrapper>
    </CoreModal>
  );
};

export default ComposeTermsModal;
