import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle, css } from 'styled-components';

import useAccountCookies from '../../../../hooks/useAccountCookies';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useSlideout from '../../../../hooks/useSlideout';
import useWindowResize from '../../../../hooks/useWindowResize';
import {
  collapseSidebar,
  expandSidebar,
} from '../../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { STEPS_SLIDEOUT } from '../../../helpers/slideOut';
import {
  mediaBreakpointPxLg,
  mediaBreakpointPxXl,
  mediaBreakpointXl,
} from '../../../styled/Breakpoint';
import Scrollbar from '../../../styled/Scrollbar';
import { fontMd3 } from '../../../styled/TypeSystem';
import {
  navigationHeightBreakpointXl,
  navigationHeightDefault,
} from '../../navigation/TopLevelNavigation';
import CloseSlideoutButton from './CloseSlideoutButton';

type DockMode = 'left' | 'right';

const AdjustContentAreaWidth = createGlobalStyle<{ slideoutWidth: string; dock: DockMode }>(
  ({ dock, slideoutWidth }) => css`
    #main-body-wrapper {
      ${dock === 'left' &&
      css`
        margin-left: 10rem;
      `}

      ${dock === 'right' &&
      css`
        margin-right: ${slideoutWidth};
      `}
    }

    .fixed-banner {
      ${dock === 'left' &&
      css`
        left: 10rem !important;
      `}

      ${dock === 'right' &&
      css`
        right: ${slideoutWidth} !important;
      `}
    }

    .editor-step,
    .editor-toolbar,
    .consumption-step,
    .consumption-toolbar {
      transition: all 1.5s ease;
      width: calc(100% - ${slideoutWidth}) !important;
    }

    .editor-step,
    .consumption-step {
      left: ${dock === 'left' ? 'unset' : '0'};
      right: ${dock === 'left' ? '0' : 'unset'};
    }
  `
);

const Overlay = styled.div<{ open: boolean }>(
  ({ theme: { vars }, open }) => css`
    background-color: ${vars.shadowBackground3};
    backdrop-filter: blur(0.75px);
    inset: 0;
    position: fixed;
    display: ${open ? 'block' : 'none'};
    z-index: 1000;
    @media (min-width: ${mediaBreakpointPxLg}) {
      display: none;
    }
  `
);

const Body = styled.div`
  height: 100%;
  width: 100%;
  min-height: 0;
`;

const Slideout = styled.div<{
  open: boolean;
  slideoutWidth: string;
  dock: DockMode;
  maxHeight: string;
  displayInOverlay: boolean;
}>(
  ({ theme: { vars }, open, slideoutWidth, dock, maxHeight, displayInOverlay }) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    height: 100%;
    width: ${slideoutWidth};
    /* It is important that this is less that the z-index for modals */
    z-index: 1050;
    transform: translateY(0);
    background: ${vars.foundationSurface1};
    transition: left 0.5s ease, right 0.5s ease;

    ${dock === 'left' &&
    css`
      left: -${open ? 0 : slideoutWidth};
      box-shadow: ${open ? `0 20px 40px 0 ${vars.borderDisabled}` : 0};
    `}

    ${dock === 'right' &&
    css`
      right: -${open ? 0 : slideoutWidth};
      box-shadow: ${open ? `0 20px 40px 0 ${vars.borderDisabled}` : 0};
    `};

    /* "Panel" mode: User can interact with the page and content is not covered */
    @media (min-width: ${mediaBreakpointPxLg}) {
      z-index: ${displayInOverlay
        ? 1050
        : 15}; /* Menus should show over the panel in this mode. Higher z-index when in overlay. */
      top: initial;
      max-height: ${displayInOverlay ? '100%' : maxHeight};
    }

    /* "Panel" mode: User can interact with the page and content is not covered */
    @media (min-width: ${mediaBreakpointPxXl}) {
      max-height: ${displayInOverlay ? '100%' : maxHeight};
    }
  `
);

const SlideoutContainer = styled.div<{ scrollable: boolean }>(
  ({ scrollable }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    overflow: hidden ${scrollable ? 'auto' : 'hidden'};
    ${Scrollbar};
  `
);

const HeaderText = styled.h1(
  ({ theme: { constants } }) => css`
    font-weight: ${constants.fontBold};
    ${fontMd3};
  `
);

const HeaderTextWrapper = styled.div<{ titleAlign: TitleAlign }>(
  ({ theme: { constants }, titleAlign }) => css`
    justify-content: ${titleAlign};
    align-items: center;
    padding-inline: ${constants.spacerMd2};
    margin-bottom: ${constants.spacerMd2};
  `
);

type TitleAlign = 'center' | 'flex-start' | 'flex-end';

type TitleProps =
  | { headerText: string; titleAlign?: TitleAlign }
  | { headerText?: never; titleAlign?: never };

type SlideoutPanelProps = {
  id?: string;
  slideoutType: string;
  slideoutWidth?: string;
  showClose?: boolean;
  children?: React.ReactElement;
  footer?: React.ReactElement;
  dock?: DockMode;
  scrollable?: boolean;
  displayInOverlay?: boolean;
  customCloseButtonIcon?: IconName;
} & TitleProps;

function SlideoutPanel({
  slideoutWidth = '22.5rem',
  slideoutType,
  showClose = true,
  children,
  dock = 'right',
  footer,
  titleAlign = 'center',
  headerText,
  scrollable = true,
  id,
  displayInOverlay = false,
  customCloseButtonIcon,
}: SlideoutPanelProps) {
  const {
    status: accountStatus,
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled },
  } = useCurrentAccount();
  const { showAccountStatusBanner } = useCurrentUser();
  const { isOpen, close } = useSlideout(slideoutType);
  const { isDesktop, isDesktopXl, width } = useWindowResize();
  const [cookies] = useAccountCookies(['is_expanded_sidebar']);
  const dispatch = useAppDispatch();
  const isTrialingOrTrialEnded = ['trialing', 'trial_ended'].includes(accountStatus);
  const isStepsSlideout = slideoutType === STEPS_SLIDEOUT;
  const isExpandedSidebar = ['true', undefined].includes(cookies.is_expanded_sidebar);

  useEffect(() => {
    if (isDesktopXl) {
      if (isOpen && !isStepsSlideout) {
        dispatch(collapseSidebar());
      } else if (isExpandedSidebar) {
        dispatch(expandSidebar());
      }
    }
  }, [isExpandedSidebar, dispatch, isDesktopXl, isOpen, isStepsSlideout]);

  const calculateMaxHeight = () => {
    const accountStatusBannerHeight =
      !isTrialingOrTrialEnded || (fullScreenManageTrialPlanOverlayEnabled && isTrialingOrTrialEnded)
        ? '5rem'
        : '3rem';
    let navigationHeight = '0rem';

    if (width >= mediaBreakpointXl) {
      navigationHeight = `${navigationHeightBreakpointXl}rem`;
    } else if (isDesktop) {
      navigationHeight = `${navigationHeightDefault}rem`;
    }

    return `calc(100% - ${navigationHeight} - ${
      showAccountStatusBanner ? accountStatusBannerHeight : '0rem'
    })`;
  };

  return ReactDOM.createPortal(
    <>
      {isOpen && isDesktop && <AdjustContentAreaWidth dock={dock} slideoutWidth={slideoutWidth} />}
      <Overlay className='slideout-overlay' onClick={() => close()} open={isOpen}></Overlay>
      <Slideout
        displayInOverlay={displayInOverlay}
        dock={dock}
        id={id}
        maxHeight={calculateMaxHeight()}
        open={isOpen}
        slideoutWidth={slideoutWidth}
      >
        {showClose && (
          <CloseSlideoutButton
            customCloseButtonIcon={customCloseButtonIcon}
            slideoutType={slideoutType}
          />
        )}
        {headerText && (
          <HeaderTextWrapper titleAlign={titleAlign}>
            <HeaderText>{headerText}</HeaderText>
          </HeaderTextWrapper>
        )}
        <Body>
          <SlideoutContainer scrollable={scrollable}>{children}</SlideoutContainer>
        </Body>
        {footer}
      </Slideout>
    </>,
    document.getElementById('slideout-wrapper') as Element
  );
}

export default SlideoutPanel;
