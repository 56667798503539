import React, { useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { NoInvoicesEmptyState as EmptyStateImage } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { useFetchBillingHistoryDataQuery } from '../../../../../redux/services/resourceApis/billing/billingApi';
import CoreModal, { ModalProps } from '../../../../design_system/core/CoreModal';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import { HeaderCell, HeaderRow } from '../../../groups/shared/table_styles';
import { Title } from '../../../shared/DetailedTitle/Title';
import ChargeRow from './ChargeRow/ChargeRow';
import { ModalContainer, ModalTable, ModalTableWrapper, ModalTbody, ModalTitle } from './styles';
import TableLoader from './TableLoader';
import { BillingHistoryModalProps } from './types';

const t = initTranslations('billing_history_modal');

const BillingHistoryModal = ({ onCloseRequest }: BillingHistoryModalProps) => {
  const [page, setPage] = useState(1);
  const result = useFetchBillingHistoryDataQuery({ page });
  const { data, isFetching } = result;

  const modalArgs: ModalProps = {
    closeIconAriaLabel: t('aria_label_close'),
    desktopSize: 'xl',
    heapModalName: 'billing-history-modal',
    onCloseRequest,
    disabledPadding: {
      horizontal: {
        all: true,
      },
    },
  };

  useDisplayFlashOnResponse({
    result,
    errorFunction: () => onCloseRequest(),
    errorMessage: t('error'),
  });

  const TableHeader = () =>
    [
      t('table_head.bill_date'),
      t('table_head.description'),
      t('table_head.value'),
      t('table_head.receipt'),
    ].map((title, index) => (
      <HeaderCell key={index}>
        <Title fontSize='sm4' fontWeight='bold' title={title} />
      </HeaderCell>
    ));

  return (
    <CoreModal {...modalArgs}>
      <ModalContainer>
        <ModalTitle>{t('title')}</ModalTitle>
        {isFetching ? (
          <TableLoader />
        ) : data?.charges.length ? (
          <>
            <ModalTableWrapper>
              <ModalTable id='billing-history-table'>
                <thead>
                  <HeaderRow className='header-row'>{TableHeader()}</HeaderRow>
                </thead>
                <ModalTbody>
                  {data.charges.map(({ createdAt, description, amount, downloadLink }, index) => (
                    <ChargeRow
                      charge={{ createdAt, description, amount, downloadLink }}
                      key={index}
                    />
                  ))}
                </ModalTbody>
              </ModalTable>
            </ModalTableWrapper>
            <Pagination
              activePage={page}
              itemsCountPerPage={data.limitValue}
              onChange={(page) => setPage(page)}
              totalItemsCount={data.totalChargesCount}
              totalPages={data.totalPages}
            />
          </>
        ) : (
          <NoResults
            darkImage={EmptyStateImage}
            heading={t('empty_state.heading')}
            iconWidth='220'
            lightImage={EmptyStateImage}
            minHeight='20rem'
            showBorder={false}
            subHeaderText={t('empty_state.action_text')}
          />
        )}
      </ModalContainer>
    </CoreModal>
  );
};

export default BillingHistoryModal;
