import React, { useCallback } from 'react';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { SendInviteOption } from '../InterfaceAndTypes';
import TeammateModal from '../TeammateModal';
import TeammateConfirmationModal from './ConfirmationModal';

type Props = {
  isDirectoryPage?: boolean;
  showInviteConfirmation?: boolean;
  showAddConfirmation?: boolean;
};

const AddTeammateWithConfirmation = ({
  isDirectoryPage = false,
  showAddConfirmation = false,
  showInviteConfirmation = false,
}: Props) => {
  const {
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();

  const {
    teammateConfirmationModalVisible,
    setTeammateConfirmationModalVisible,
    setTeammateState,
  } = useTeammateModal();

  const showConfirmationModal = useCallback(
    (sendInvite: SendInviteOption) => {
      if (isDirectoryPage) return;

      const laterInvite = sendInvite === 'later';

      const shouldShowModal = laterInvite
        ? showAddConfirmation || perUserPricingEnabled
        : showInviteConfirmation;

      shouldShowModal && setTeammateConfirmationModalVisible(true);
    },
    [
      isDirectoryPage,
      perUserPricingEnabled,
      setTeammateConfirmationModalVisible,
      showAddConfirmation,
      showInviteConfirmation,
    ]
  );

  return (
    <>
      <TeammateModal action='add' onSuccess={showConfirmationModal} />
      {teammateConfirmationModalVisible && (
        <TeammateConfirmationModal
          closeModal={() => {
            setTeammateConfirmationModalVisible(false);
            setTeammateState(null);
          }}
          setShowTeammateConfirmationModal={setTeammateConfirmationModalVisible}
        />
      )}
    </>
  );
};

export default AddTeammateWithConfirmation;
