import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { routes } from '../../../publicApplication/applicationRouter';
import { NotificationLink } from '../styles';
import { UserName } from './styles';
import { NotificationUserNameProps } from './types';

const NotificationUserName = ({ user: { is_active, id, name } }: NotificationUserNameProps) => {
  const { slug } = useCurrentAccount();
  return (
    <>
      {is_active ? (
        <NotificationLink className='notranslate' to={routes.userProfile({ slug, id })}>
          {name}
        </NotificationLink>
      ) : (
        <UserName className='notranslate'>{name}</UserName>
      )}
    </>
  );
};

export default NotificationUserName;
