import { skipToken } from '@reduxjs/toolkit/query/react';
import React from 'react';
import styled from 'styled-components';

import { ContentModalProvider } from '../../../../../contexts/ContentModalContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { formatUserOptions } from '../../../../../lib/formatUserOptions';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useGetCurriculumForEditModalQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useGetModalUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { ElementType } from '../../../../../types/CurriculumElement';
import { Sector } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import LoadingIndicator from '../../../../design_system/Triage/fields/SharedSelect/SelectLoadingIndicator/LoadingIndicator';
import AddTeammateWithConfirmation from '../../../people/TeammateModal/AddTeammateWithConfirmation';
import ContentModalFormikWrapper from './ContentModalFormikWrapper';

const CenteredDiv = styled.div`
  text-align: center;
`;

const t = initTranslations('curriculums_modal');

export type ContentModalTypes = ElementType | 'Curriculum';

export type ContentModalProps = {
  curriculumId?: number;
  contentType?: ContentModalTypes;
  advancedSettingsOpen?: boolean;
  closeRequest: () => void;
  sector?: Sector;
  providedFormTitle?: string;
  providedFormSubtitle?: string;
  afterCreateCurriculumAction?: (curriculumId: number, curriculumTitle: string) => void;
};

const ContentModal = ({
  curriculumId,
  contentType = 'Curriculum',
  advancedSettingsOpen = false,
  closeRequest,
  providedFormTitle,
  providedFormSubtitle,
  sector,
  afterCreateCurriculumAction,
}: ContentModalProps) => {
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetModalUsersQuery();
  const {
    data: curriculum,
    isLoading: isCurriculumLoading,
    error: curriculumError,
  } = useGetCurriculumForEditModalQuery(curriculumId ?? skipToken);
  const {
    curriculum: { singular: curriculumTermSingular },
    topic: { singular: courseTermSingular },
    flowchart: { singular: flowchartTermSingular },
  } = useAccountTerminology();
  const contentTerm = () => {
    switch (contentType) {
      case 'Curriculum':
        return curriculumTermSingular;
      case 'Course':
        return courseTermSingular;
      case 'Flowchart':
        return flowchartTermSingular;
      case 'Video':
        return t('terms.video');
      case 'Survey::Survey':
        return t('terms.survey');
    }
  };
  const { name } = useCurrentUser();
  const isUpdate = Boolean(curriculumId);
  const modalTitle =
    providedFormTitle ??
    t('title', {
      actionVerb: isUpdate ? t('edit') : t('create'),
      contentType: contentTerm().toLowerCase(),
    });
  const modalSubtitle =
    providedFormSubtitle ??
    (isUpdate
      ? t('update_subtitle', { contentType: contentTerm().toLowerCase() })
      : t(`create_subtitle.${contentType.toLowerCase()}`, {
          curriculum: curriculumTermSingular.toLowerCase(),
        }));
  const { teammateModalVisible, teammateConfirmationModalVisible } = useTeammateModal();
  const showAddTeammateWithConfirmation = teammateModalVisible || teammateConfirmationModalVisible;

  const taskModalArgs: TaskModalProps = {
    title: modalTitle,
    subtitle: modalSubtitle,
    onCloseRequest: closeRequest,
    heapModalName: 'curriculum-modal',
    primaryButtonText: isUpdate
      ? t('save_changes')
      : t('create_btn', { contentType: contentTerm().toLowerCase() }),
    primaryButtonTask: () => {},
    processing: false,
    isDisabled: true,
    secondaryButtonText: t('cancel'),
  };

  if (isCurriculumLoading || isUsersLoading)
    return (
      <TaskModal {...taskModalArgs} desktopSize='lg'>
        <CenteredDiv>
          <LoadingIndicator />
        </CenteredDiv>
      </TaskModal>
    );

  return (
    <>
      <ContentModalProvider
        advancedSettingsOpen={advancedSettingsOpen}
        closeRequest={closeRequest}
        contentTerm={contentTerm()}
        contentType={contentType}
        isUpdate={isUpdate}
        modalSubtitle={modalSubtitle}
        modalTitle={modalTitle}
        modalVisible={!showAddTeammateWithConfirmation}
        userOptions={formatUserOptions(users || [], name)}
      >
        <ContentModalFormikWrapper
          afterCreateCurriculumAction={afterCreateCurriculumAction}
          curriculum={curriculum}
          errorMessage={
            messageFromError(curriculumError)?.join(', ') ||
            messageFromError(usersError)?.join(', ')
          }
          sector={sector}
        />
      </ContentModalProvider>

      {showAddTeammateWithConfirmation && <AddTeammateWithConfirmation />}
    </>
  );
};

export default ContentModal;
