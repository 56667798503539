import { Form, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useContentModal } from '../../../../../contexts/ContentModalContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { isSector } from '../../../../../lib/isSector';
import { CurriculumShowResponse } from '../../../../../types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import FieldLabel from '../../../../design_system/core/FieldLabel';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../design_system/Triage/InputField';
import { TaskErrorDiv } from '../../../../styled/Modals';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { FormGroup } from '../../../shared/FormGroup';
import { FormValues } from './ContentModalFormikWrapper';
import EditSettings from './EditSettings';
import SectorChipSelect, { ProcessChipType } from './SectorChipSelect';

const SectorMoveRestrictedMessage = styled.p(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    background-color: ${vars.foundationBase1};
    margin-top: ${constants.spacerMd2};
    padding: ${constants.spacerMd2} ${constants.spacerLg1};
    ${fontSm5};
  `
);

const t = initTranslations('curriculums_modal');

type ContentModalFormProps = {
  curriculum: CurriculumShowResponse | undefined;
  selectedChip: ProcessChipType;
  setSelectedChip: React.Dispatch<React.SetStateAction<ProcessChipType>>;
  errorMessage: string | undefined;
  createOrUpdateResultLoading: boolean;
};

const ContentModalForm = ({
  curriculum,
  errorMessage,
  selectedChip,
  setSelectedChip,
  createOrUpdateResultLoading,
}: ContentModalFormProps) => {
  const { modalSubtitle, modalTitle, isUpdate, closeRequest, modalVisible, contentTerm } =
    useContentModal();
  const { signatures_required: signaturesRequired } = curriculum || {};
  const {
    curriculum: { plural: curriculumTermPlural },
    policy: { plural: policyTermPlural },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const { hasESignatureFeature } = useCurrentAccount();
  const editingIsDisabled = document.getElementById('curriculum-modal')?.dataset.disabled == 'true';
  const { handleChange, setFieldValue, values, touched, errors, dirty, handleSubmit } =
    useFormikContext<FormValues>();
  const { title: curriculumTitle } = values;
  const showSectorMoveRestrictedMessage = !!signaturesRequired && !hasESignatureFeature;

  const handleChipSelect = useCallback(
    (selectedChip: ProcessChipType) => {
      setFieldValue('sectorChip', selectedChip);
      setSelectedChip(selectedChip);
    },
    [setFieldValue, setSelectedChip]
  );

  const taskModalArgs: TaskModalProps = {
    title: modalTitle,
    subtitle: modalSubtitle,
    onCloseRequest: closeRequest,
    heapModalName: 'curriculum-modal',
    processing: createOrUpdateResultLoading,
    isDisabled: isUpdate && !dirty,
    primaryButtonText: isUpdate
      ? t('save_changes')
      : t('create_btn', { contentType: contentTerm.toLowerCase() }),
    primaryButtonTask: handleSubmit,
    secondaryButtonText: t('cancel'),
    visible: modalVisible,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <Form id='content-modal-form'>
        <FormGroup id='content-name-group'>
          <InputField
            autoFocus
            disabled={editingIsDisabled}
            disabledFieldTooltipId='disabled-by-e-signature-tooltip'
            disabledFieldTooltipText={t('disabled_by_e_signature')}
            errorText={touched.title && errors.title}
            id='title'
            label={t('content_type_name_label', { contentType: contentTerm })}
            name='title'
            onChange={handleChange}
            placeholder={t('content_type_name_placeholder', {
              contentType: contentTerm.toLowerCase(),
            })}
            required
            type='text'
            value={curriculumTitle}
          />
        </FormGroup>
        <FormGroup>
          <FieldLabel required text={t('sector_chip_select')} />
          <SectorChipSelect
            disabled={showSectorMoveRestrictedMessage}
            onChipSelect={handleChipSelect}
            sector={selectedChip && isSector(selectedChip) ? selectedChip : 'process'}
          />
          {showSectorMoveRestrictedMessage && (
            <SectorMoveRestrictedMessage
              dangerouslySetInnerHTML={{
                __html: t('sector_move_restricted', {
                  policies: policyTermPlural,
                  curriculums: curriculumTermPlural,
                  course: topicTermSingular.toLowerCase(),
                  curriculum: curriculumTitle,
                }),
              }}
            />
          )}
        </FormGroup>

        {isUpdate && <EditSettings curriculum={curriculum} />}

        {errorMessage && <TaskErrorDiv>{errorMessage}</TaskErrorDiv>}
      </Form>
    </TaskModal>
  );
};

export default ContentModalForm;
