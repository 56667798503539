import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useStatusBannerDataQuery } from '../../../../../../redux/services/resourceApis/accounts/accountsApi';
import { StatusBanner } from '../../../../../../redux/services/resourceApis/accounts/types';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.on_hold');

const OnHoldBanner = () => {
  const { data, isLoading } = useStatusBannerDataQuery({ type: StatusBanner.OnHold });

  if (isLoading || !data) return <></>;

  const { date } = data;
  const messageTitleTranslation = date ? t('title_with_date', { date }) : t('title');

  return (
    <AccountStatusBanner
      className='on-hold-banner'
      emoji='😴'
      messageBody={t('body')}
      messageTitle={messageTitleTranslation}
    />
  );
};

export default OnHoldBanner;
