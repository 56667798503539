import { Feature } from '../types/Feature';
import initTranslations from './initTranslations';

const t = initTranslations('per_user_pricing.billing_page.plans.compare_plans_overlay');
const productTerm = 'Trainual';
export const featuresListData: Feature[] = [
  {
    id: 1,
    title: t('features.feature_1.title'),
    tooltip: t('features.feature_1.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 2,
    title: t('features.feature_2.title'),
    tooltip: t('features.feature_2.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 3,
    title: t('features.feature_3.title'),
    tooltip: t('features.feature_3.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 4,
    title: t('features.feature_4.title'),
    tooltip: t('features.feature_4.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 5,
    title: t('features.feature_5.title'),
    tooltip: t('features.feature_5.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 6,
    title: t('features.feature_6.title'),
    tooltip: t('features.feature_6.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 7,
    title: t('features.feature_7.title'),
    tooltip: t('features.feature_7.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 8,
    title: t('features.feature_8.title'),
    tooltip: t('features.feature_8.tooltip', { productTerm }),
    train: true,
    scale: true,
  },
  {
    id: 9,
    title: t('features.feature_9.title'),
    tooltip: t('features.feature_9.tooltip'),
    train: true,
    scale: true,
  },
  {
    id: 10,
    title: t('features.feature_10.title'),
    tooltip: t('features.feature_10.tooltip', { productTerm }),
    train: true,
    scale: true,
  },
  {
    id: 11,
    title: t('features.feature_11.title'),
    tooltip: t('features.feature_11.tooltip'),
    train: false,
    scale: true,
  },
  {
    id: 12,
    title: t('features.feature_12.title'),
    tooltip: t('features.feature_12.tooltip', { productTerm }),
    train: false,
    scale: true,
  },
  {
    id: 13,
    title: t('features.feature_13.title'),
    tooltip: t('features.feature_13.tooltip'),
    train: false,
    scale: true,
  },
  {
    id: 14,
    title: t('features.feature_14.title'),
    tooltip: t('features.feature_14.tooltip', { productTerm }),
    train: false,
    scale: true,
  },
  {
    id: 15,
    title: t('features.feature_15.title'),
    tooltip: t('features.feature_15.tooltip'),
    train: false,
    scale: true,
  },
  {
    id: 16,
    title: t('features.feature_16.title'),
    tooltip: t('features.feature_16.tooltip', { productTerm }),
    train: false,
    scale: true,
  },
  {
    id: 17,
    title: t('features.feature_17.title'),
    tooltip: t('features.feature_17.tooltip', { productTerm }),
    train: false,
    scale: true,
  },
  {
    id: 18,
    title: t('features.feature_18.title'),
    tooltip: t('features.feature_18.tooltip'),
    train: false,
    scale: true,
  },
  {
    id: 19,
    title: t('features.feature_19.title'),
    tooltip: t('features.feature_19.tooltip', { productTerm }),
    train: false,
    scale: true,
  },
];
