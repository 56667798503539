import styled, { css } from 'styled-components';

import { deprecated } from '../../../../../../../styled/TypeSystem';

export const StyledPlanName = styled.span<{
  planBrandColor: string;
  isSelected: boolean;
  backgroundColor: string;
}>`
  position: relative;
  cursor: pointer;
  color: ${({ planBrandColor, isSelected, theme: { vars } }) =>
    isSelected ? planBrandColor : vars.textDisabled};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  z-index: 1;

  ${({ isSelected, backgroundColor }) =>
    isSelected &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: -3px;
        right: -3px;
        height: 16px;
        z-index: -1;
        background-color: ${backgroundColor};
      }
    `};

  ${deprecated.fontMd2};
`;
