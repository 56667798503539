import React, { FC } from 'react';

import { ResponsibilityEmptyStateImage as emptyStateImage } from '../../../../../../../lib/gcsImages';
import { Container, Image, Title } from './styles';
import { EmptyStateProps } from './types';

const EmptyState: FC<EmptyStateProps> = ({ title }) => {
  return (
    <Container>
      <Image src={emptyStateImage} />

      <Title>{title}</Title>
    </Container>
  );
};

export default EmptyState;
