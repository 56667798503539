import React, { useReducer, useState } from 'react';

import { getBillingPlansByInterval } from '../../../../../../lib/billing/getBillingPlansByInterval';
import { SimplePricingBillingPlanData } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { BillingInterval } from '../../../../../../types/BillingInterval';
import PlanCardsHeader from '../shared/PlanCardHeader/PlanCardHeader';
import PlanCard from './PlanCard/PlanCard';
import { upgradeButtonsReducer } from './reducer';
import { CardsWrapper } from './styles';
import { BillingPagePlansProps } from './types';

const BillingPagePlans = ({
  billingPlansPriceData,
  defaultSelectedInterval = 'year',
  isSimplePricingV3 = false,
}: BillingPagePlansProps) => {
  const initialState = {
    smallButtonIsHovered: false,
    growthButtonIsHovered: false,
    unlimitedButtonIsHovered: false,
    customButtonIsHovered: false,
    mediumButtonIsHovered: false,
  };
  const [upgradeButtonsState, dispatch] = useReducer(upgradeButtonsReducer, initialState);
  const [selectedInterval, setSelectedInterval] =
    useState<BillingInterval>(defaultSelectedInterval);
  const plans = getBillingPlansByInterval(
    selectedInterval,
    billingPlansPriceData.billingPlans
  ) as SimplePricingBillingPlanData[];

  return (
    <div className='billing-page-plans'>
      <PlanCardsHeader
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <CardsWrapper isSimplePricingV3={isSimplePricingV3}>
        {plans.map((billingPlan) => {
          return (
            <PlanCard
              billingPlan={billingPlan}
              dispatch={dispatch}
              isHovered={upgradeButtonsState[`${billingPlan.name}ButtonIsHovered`]}
              isSimplePricingV3={isSimplePricingV3}
              key={billingPlan.name}
            />
          );
        })}
      </CardsWrapper>
    </div>
  );
};

export default BillingPagePlans;
