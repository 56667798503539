import React from 'react';
import styled from 'styled-components';

import { NoResultsGraphic, NoResultsGraphicDark } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import NoResults from '../../../../../design_system/Triage/NoResults';
import { EmbedlyItemsSections } from '../EmbedlyItems';
import EmbedlyMenuSection from '../EmbedlyMenuSection/EmbedlyMenuSection';

const StyledHr = styled.hr`
  border: 0;
  height: 1px;
  background: ${({ theme: { vars } }) => vars.borderSurface2};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};
  width: 100%;
`;

const StyledNoResults = styled(NoResults)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 18rem;
`;

export type EmbedlyMenuItemSource =
  | 'generic'
  | 'file'
  | 'video'
  | 'maps'
  | 'engagement'
  | 'socialMedia'
  | 'design'
  | 'project'
  | 'trainual'
  | 'trainual_capture'
  | 'development'
  | 'embed-iframe';

export type EmbedlyMenuItemProps = {
  svgSrc: string;
  svgClassName: string;
  title: string;
  caption?: string;
  source: EmbedlyMenuItemSource;
  id: string;
};

export type EmbedlySectionsKeys = keyof EmbedlyItemsSections;
export type SearchResult = Partial<Record<EmbedlySectionsKeys, EmbedlyMenuItemProps[]>>;

export type Props = {
  items: EmbedlyItemsSections | SearchResult;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

const t = initTranslations('editor.embedly.search');

const EmbedlyMenu = ({ items, setSearchValue }: Props) => {
  const itemsKeys = Object.keys(items) as EmbedlyMenuItemSource[];

  return (
    <>
      {itemsKeys.length ? (
        itemsKeys.map((key: EmbedlySectionsKeys, index) => {
          const sectionItems = items[key];

          if (sectionItems) {
            return (
              <div className={`${key}-section`} key={key}>
                {index !== 0 && <StyledHr />}
                <EmbedlyMenuSection items={sectionItems} source={key} />
              </div>
            );
          }
        })
      ) : (
        <StyledNoResults
          darkImage={NoResultsGraphicDark}
          heading={t('no_result_found')}
          lightImage={NoResultsGraphic}
          minHeight='18.5rem'
          showBorder={false}
          subHeaderCta={{
            action: () => setSearchValue(''),
            text: t('clear'),
          }}
        />
      )}
    </>
  );
};

export default EmbedlyMenu;
