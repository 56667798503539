import React from 'react';
import styled from 'styled-components';

import { fontSm2 } from '../../../../../styled/TypeSystem';
import { EmbedlyMenuItemProps } from '../EmbedlyMenu/EmbedlyMenu';

const EmbedlyIconContainer = styled.div`
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
  display: flex;
  align-items: center;
  width: 2.5rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const EmbedlyButton = styled.button`
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  height: ${({ theme: { constants } }) => constants.heightLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
  }
  &:focus {
    outline: none;
  }
`;

const EmbedlyList = styled.li`
  display: flex;
`;

const EmbedlyDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  justify-content: center;
`;

const EmbedlyItemTitle = styled.div`
  display: flex;
  justify-content: left;
`;

const EmbedlyCaption = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.borderDefault};
  display: flex;
  ${fontSm2};
`;

const EmbedImage = styled.img`
  width: 100%;
`;

export type Props = {
  item: EmbedlyMenuItemProps;
  onClick: () => void;
};

const EmbedlyMenuItem = ({ item: { svgClassName, svgSrc, title, caption }, onClick }: Props) => {
  const itemName = title.split(' ').join('-').toLowerCase();

  return (
    <EmbedlyButton className='embed-item' id={`embed-${itemName}-button`} onClick={onClick}>
      <EmbedlyList>
        <EmbedlyIconContainer>
          <EmbedImage alt='embedly-menu-icon' className={svgClassName} src={svgSrc} />
        </EmbedlyIconContainer>
        <EmbedlyDetailsDiv>
          <EmbedlyItemTitle className='notranslate'>{title}</EmbedlyItemTitle>
          <EmbedlyCaption>{caption}</EmbedlyCaption>
        </EmbedlyDetailsDiv>
      </EmbedlyList>
    </EmbedlyButton>
  );
};

export default EmbedlyMenuItem;
