import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { forceCapitalization } from '../../../helpers/cssTranslation';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';

const sharedCellStyling = css`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
`;

const largeCellWidth = css`
  width: 11rem;
`;

const mediumCellWidth = css`
  width: 8rem;
`;

const smallCellWidth = css`
  width: 3rem;
`;

export const TableAndActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TableWrapper = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  position: relative;
  overflow: auto;
  white-space: nowrap;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const TableNoResultsWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const HeaderRow = styled.tr`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  text-align: left;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  ${fontSm4};
`;

export const HeaderCell = styled.th`
  ${sharedCellStyling};
`;

export const DataRow = styled.tr`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  height: 3.5rem;
  text-align: left;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};

  &:last-of-type {
    border-bottom: unset;
  }

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationBase1};
  }

  ${fontSm5};
`;

export const DataCell = styled.td`
  ${sharedCellStyling};
`;

export const LargeDataCell = styled(DataCell)`
  ${largeCellWidth};
`;

export const MediumDataCell = styled(DataCell)`
  ${mediumCellWidth};
  ${TruncatedText({})};
  ${forceCapitalization};
`;

export const SmallDataCell = styled(DataCell)`
  ${smallCellWidth};
`;

export const LargeHeaderCell = styled(HeaderCell)`
  ${largeCellWidth};
`;

export const MediumHeaderCell = styled(HeaderCell)`
  ${mediumCellWidth};
`;

export const SmallHeaderCell = styled(HeaderCell)`
  ${smallCellWidth};
`;

export const ActionMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
