import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useContentModal } from '../../../../../contexts/ContentModalContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin, useIsCurriculumOwner } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumShowResponse } from '../../../../../types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TextareaWithCharacterCount from '../../../../design_system/input/fields/TextareaWithCharacterCount';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import AddTeammateMenuList from '../../../people/AddTeammateMenuList';
import AdvancedSettings from './AdvancedSettings';
import { FormValues } from './ContentModalFormikWrapper';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const FormGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  user-select: none;
`;

const t = initTranslations('curriculums_modal');

type EditSettingsProps = {
  curriculum: CurriculumShowResponse | undefined;
};

const EditSettings = ({ curriculum }: EditSettingsProps) => {
  const { owner_id: ownerId, signatures_required: signaturesRequired } = curriculum || {};
  const { handleChange, setFieldValue, values } = useFormikContext<FormValues>();
  const { userOptions, contentType } = useContentModal();
  const ability = useCurrentUserAbilities();
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();
  const { paywalledFeatures } = useCurrentAccount();
  const { description: curriculumDescription, ownerAssignment } = values;
  const canInviteUser = ability.can('create', 'User');
  const isOwner = useIsCurriculumOwner(ownerId);
  const advancedSettingsLocked = paywalledFeatures.includes('curriculum_advanced_settings');
  const editingIsDisabled = document.getElementById('curriculum-modal')?.dataset.disabled == 'true';
  const isAdminPlus = useIsAdmin();
  const canAccessManagerialSettings = isOwner || isAdminPlus;

  return (
    <Wrapper id='content-modal-edit-settings'>
      <FormGroupsWrapper>
        {canAccessManagerialSettings && !advancedSettingsLocked && (
          <SingleSelectField
            MenuList={canInviteUser ? AddTeammateMenuList : undefined}
            className='content-owner-select'
            defaultValue={ownerAssignment || null}
            fieldLabelText={t('owner_label')}
            isClearable
            labelTooltipId='content-owner-tooltip'
            labelTooltipText={t('owner_tooltip', {
              curriculum: curriculumTermSingular.toLowerCase(),
            })}
            name='ownerAssignment'
            onNonDefaultSelected={(ownerAssignment: string) =>
              setFieldValue('ownerAssignment', ownerAssignment)
            }
            options={userOptions}
            placeholder={t('display_owner_placeholder')}
            required={false}
            value={ownerAssignment}
          />
        )}
        {contentType === 'Curriculum' && (
          <TextareaWithCharacterCount
            attributeName={t('description')}
            autofocus={false}
            disabled={editingIsDisabled}
            id='curriculumDescription'
            label={t('description_label')}
            maxCharacters={500}
            name='description'
            noTopMargin
            onChange={handleChange}
            placeholder={t('description_placeholder', {
              curriculum: curriculumTermSingular.toLowerCase(),
            })}
            required={false}
            resourceName={curriculumTermSingular}
            rows={3}
            tooltipId='description-tooltip'
            tooltipText={
              editingIsDisabled
                ? t('disabled_by_e_signature')
                : t('description_tooltip', { curriculum: curriculumTermSingular.toLowerCase() })
            }
            value={curriculumDescription}
          />
        )}
      </FormGroupsWrapper>

      {canAccessManagerialSettings && (
        <AdvancedSettings
          isAdminPlus={isAdminPlus}
          isOwner={isOwner}
          ownerAssignment={!!ownerAssignment}
          signatureLoss={signaturesRequired || false}
        />
      )}
    </Wrapper>
  );
};

export default EditSettings;
