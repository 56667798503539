import React from 'react';

import { EmptyStateBillingInfoSvg } from '../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../../redux/domains/modalsSlice/modalsSlice';
import {
  BillingInfoEmptyStateCta,
  BillingInfoEmptyStateImage,
  BillingInfoEmptyStateSubtitle,
  BillingInfoEmptyStateTitle,
  BillingInfoEmptyStateWrapper,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.information_card.empty_state');

const BillingInfoEmptyState = () => {
  const dispatchShowModal = useDispatchSetShowModal();

  return (
    <BillingInfoEmptyStateWrapper>
      <BillingInfoEmptyStateImage alt={t('image_alt')} src={EmptyStateBillingInfoSvg} />
      <BillingInfoEmptyStateTitle text={t('title')} />
      <BillingInfoEmptyStateSubtitle text={t('subtitle')} />
      <BillingInfoEmptyStateCta
        buttonType='tertiary'
        id='add-credit-card-button'
        onClick={() => dispatchShowModal('updatePaymentMethod', true)}
        text={t('cta')}
      />
    </BillingInfoEmptyStateWrapper>
  );
};

export default BillingInfoEmptyState;
