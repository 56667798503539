import { parse } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useTeammateModal } from '../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFieldErrorsFromFormik from '../../../../hooks/useFieldErrorsFromFormik';
import { TeammateState } from '../../../../hooks/useTeammateState';
import { HeaderImage } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import {
  useEditUserMutation,
  useInviteUserMutation,
} from '../../../../redux/services/resourceApis/users/usersApi';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import { HeaderBadge } from '../../../design_system/overlays/modals/TaskModal/TaskModalTypes';
import ActiveTeammateTab from './ActiveTeammateTab';
import { ModalProps, TeammateModalFormValues } from './InterfaceAndTypes';
import ModalTabs from './ModalTabs';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const FormWrapper = styled.div<{ action: string }>`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const t = initTranslations('teammate_modal');

export type TeammateModalTab = 'general' | 'advanced';

const Modal = ({ action, closeModal, onSuccess, prefillOptions, userId }: ModalProps) => {
  const { setTeammateState } = useTeammateModal();
  const { usedUserSlotsCount, totalUserSlotsCount, unlimitedUserSlots } = useCurrentAccount();
  const { timeZone } = useCurrentUser();
  const {
    values,
    isSubmitting,
    handleSubmit,
    isValid,
    setFieldError,
    setSubmitting,
    setStatus,
    dirty,
  } = useFormikContext<TeammateModalFormValues>();
  const isSubmissionDisabled = isSubmitting || !dirty;
  const [activeTab, setActiveTab] = useState<TeammateModalTab>('general');

  const [inviteUser, inviteUserResult] = useInviteUserMutation();
  const { data: inviteUserData, reset: inviteUserReset, error: inviteUserError } = inviteUserResult;

  const [editUser, editUserResult] = useEditUserMutation();
  const { data: editUserData, reset: editUserReset, error: editUserError } = editUserResult;

  const submit = useCallback(() => {
    const formattedDate = (dateString: string | null, hour: string) => {
      const datetime = `${dateString} ${hour} ${timeZone}`;
      const date = parse(datetime, 'yyyy-MM-dd hh:mm a x', new Date());
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

      return utcDate.toLocaleString('en-US');
    };

    const scheduled_invite_at = !values.schedule_invite
      ? null
      : formattedDate(values.scheduled_invite_at, values.scheduled_invite_at_hour);

    const scheduled_archive_at = !values.schedule_archive
      ? null
      : formattedDate(values.scheduled_archive_at, values.scheduled_archive_at_hour);

    const requestValues = {
      ...values,
      scheduled_invite_at,
      scheduled_archive_at,
    };

    if (action === 'edit' && userId) {
      editUser({ id: userId, values: requestValues });
    } else if (action === 'add') {
      inviteUser(requestValues);
    }
  }, [action, editUser, inviteUser, timeZone, userId, values]);

  const badgeProps: HeaderBadge = useMemo(() => {
    return {
      badgeText: t('shared.user_slots_used', {
        usedUserSlotsCount,
        totalUserSlotsCount: unlimitedUserSlots ? t('shared.unlimited') : totalUserSlotsCount,
      }),
      badgeType: 'general',
    };
  }, [totalUserSlotsCount, unlimitedUserSlots, usedUserSlotsCount]);

  useEffect(() => {
    if (isSubmitting && isValid) {
      submit();
    }
  }, [isSubmitting, isValid, submit]);

  useEffect(() => {
    const userData = inviteUserData || editUserData;
    const send_invite = values.send_invite;

    if (userData) {
      let newTeammateState: TeammateState = {
        user: userData,
      };

      if (action === 'add') {
        newTeammateState = {
          ...newTeammateState,
          sendInvite: send_invite,
        };
      }

      setTeammateState(newTeammateState);
      onSuccess && onSuccess(send_invite);

      if (inviteUserData) {
        inviteUserReset();
      } else if (editUserData) {
        editUserReset();
      }
      setSubmitting(false);
      closeModal();
    }
  }, [
    inviteUserReset,
    editUserReset,
    closeModal,
    onSuccess,
    inviteUserData,
    editUserData,
    action,
    setTeammateState,
    values.send_invite,
    values.scheduled_invite_at,
    setSubmitting,
  ]);

  useFieldErrorsFromFormik({
    error: inviteUserError || editUserError,
    setFieldError,
    setStatus,
    setSubmitting,
  });

  const taskModalArgs: TaskModalProps = {
    ...badgeProps,
    headerImage: action === 'add' ? HeaderImage : undefined,
    title: t(`${action}.title`),
    processing: isSubmitting,
    onCloseRequest: closeModal,
    primaryButtonText: t(`${action}.submit_button`),
    primaryButtonTask: handleSubmit,
    isDisabled: isSubmissionDisabled,
    secondaryButtonText: t('shared.cancel'),
    heapModalName: `${action}-teammate-modal`,
    disabledPadding: {
      vertical: { top: action === 'add' },
    },
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <BodyWrapper>
        <ModalTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <FormWrapper action={action}>
          <Form
            autoComplete='off'
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <ActiveTeammateTab
              action={action}
              prefillOptions={prefillOptions}
              tab={activeTab}
              userId={userId}
            />
          </Form>
        </FormWrapper>
      </BodyWrapper>
    </TaskModal>
  );
};

export default Modal;
