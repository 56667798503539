import React, { useEffect, useReducer } from 'react';

import { formatUserOptions } from '../../../../lib/formatUserOptions';
import initTranslations from '../../../../lib/initTranslations';
import { setActivityLogFilters } from '../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetActivityLogFiltersQuery } from '../../../../redux/services/resourceApis/reports/reportsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import { BaseOption } from '../../../design_system/core/SelectOption/types';
import Flyout from '../../../design_system/overlays/flyout/Flyout';
import MultiSelectField from '../../../design_system/Triage/fields/MultiSelectField';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';
import { SelectField, StyledFilterButton } from '../ReportTableFilter/ReportsFilterStyles';
import { ActivityFilterAction, ActivityFilterState } from './ActivityLogTab/ActivityLogTabTypes';

const ActivityLogFilter = () => {
  const t = initTranslations('reports.activity_log.filter');
  const dispatch = useAppDispatch();
  const { curriculum } = useAccountTerminology();
  const { userIds, curriculumIds, activityType } = useAppSelector(
    (state) => state.reports.activityLog.filters
  );
  const menuId = 'activity-log-filter-flyout';
  const { closeMenu } = useActiveMenuHandler({ menuId });

  const initialFilterState: ActivityFilterState = {
    selectedUsers: userIds,
    selectedCurriculums: curriculumIds,
    selectedActivity: activityType,
  };

  const appliedFiltersCount = curriculumIds.length + userIds.length + (activityType ? 1 : 0);

  const filterReducer = (
    state: ActivityFilterState,
    action: ActivityFilterAction
  ): ActivityFilterState => {
    switch (action.type) {
      case 'changeSelectedUsers': {
        return { ...state, selectedUsers: action.selectedUsers };
      }
      case 'changeSelectedCurriculums': {
        return { ...state, selectedCurriculums: action.selectedCurriculums };
      }
      case 'changeSelectedActivity': {
        return { ...state, selectedActivity: action.selectedActivity };
      }
      case 'restoreFiltersDefault': {
        return {
          ...state,
          selectedUsers: action.selectedUsers,
          selectedCurriculums: action.selectedCurriculums,
          selectedActivity: action.selectedActivity,
        };
      }
    }
  };

  const [filterState, dispatchState] = useReducer(filterReducer, initialFilterState);
  const { selectedUsers, selectedCurriculums, selectedActivity } = filterState;

  const curriculumSelectOptions = (curriculums: { id: number; title: string }[]) => {
    const optionsData: BaseOption[] = curriculums.map((option) => ({
      label: option.title,
      value: option.id.toString(),
    }));
    return formatOptions(optionsData);
  };

  const activitySelectOptions = (activities: string[]) => {
    const optionsData: BaseOption[] = activities.map((option) => ({
      label: option,
      value: option,
    }));
    return formatOptions(optionsData);
  };

  const applySelectedFilters = () => {
    closeMenu();
    dispatch(
      setActivityLogFilters({
        activityType: selectedActivity,
        userIds: selectedUsers,
        curriculumIds: selectedCurriculums,
      })
    );
  };

  const restoreFiltersDefaultState = () => {
    dispatchState({
      type: 'restoreFiltersDefault',
      selectedUsers: [],
      selectedCurriculums: [],
      selectedActivity: '',
    });
  };

  const clearFilters = () => {
    closeMenu();
    restoreFiltersDefaultState();
    dispatch(setActivityLogFilters({ activityType: '', userIds: [], curriculumIds: [] }));
  };

  const handleFilters = () => {
    if (!appliedFiltersCount) {
      restoreFiltersDefaultState();
    }
  };

  // Apply filter for filter_user_id if url has filter_user_id query param
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('filter_user_id');

    if (userId) {
      dispatchState({ type: 'changeSelectedUsers', selectedUsers: [userId] });
      dispatch(
        setActivityLogFilters({
          activityType: '',
          userIds: [userId],
          curriculumIds: [],
        })
      );

      const url = new URL(window.location.href);
      url.searchParams.delete('filter_user_id');
      window.history.replaceState(null, '', url);
    }
  }, [dispatch]);

  const { data } = useGetActivityLogFiltersQuery();

  const triggerFlyoutButton = (
    <StyledFilterButton
      buttonType='secondary'
      className='flyout-trigger'
      iconName='sliders'
      iconWeight='solid'
      id='activity-log-filters-button'
      onClick={handleFilters}
      size='md'
      text={t('filter_button_text', { count: appliedFiltersCount })}
    />
  );

  return (
    <Flyout
      id={menuId}
      placement='bottom-end'
      primaryButtonTask={applySelectedFilters}
      primaryButtonText={t('update')}
      secondaryButtonTask={clearFilters}
      secondaryButtonText={t('clear')}
      strategy='absolute'
      title={t('title')}
      triggerButton={triggerFlyoutButton}
    >
      <SelectField>
        <MultiSelectField
          className='activities-users-filter-select'
          defaultValue={selectedUsers}
          fieldLabelText={t('users_select_label')}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({ type: 'changeSelectedUsers', selectedUsers: value });
          }}
          options={formatUserOptions(data?.users || [])}
          placeholder={t('users_placeholder')}
          value={selectedUsers}
        />
      </SelectField>
      <SelectField>
        <MultiSelectField
          className='activities-curriculums-filter-select'
          defaultValue={[]}
          fieldLabelText={curriculum.singular}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({ type: 'changeSelectedCurriculums', selectedCurriculums: value });
          }}
          options={curriculumSelectOptions(data?.curriculums || [])}
          placeholder={t('curriculums_placeholder', {
            curriculum: curriculum.singular.toLowerCase(),
          })}
          value={selectedCurriculums}
        />
      </SelectField>
      <SelectField>
        <SingleSelectField
          className='activities-activity-filter-select'
          defaultValue={selectedActivity}
          fieldLabelText={t('activity_select_label')}
          onNonDefaultSelected={(value: string) => {
            dispatchState({ type: 'changeSelectedActivity', selectedActivity: value });
          }}
          options={activitySelectOptions(data?.activities || [])}
          placeholder={t('activity_placeholder')}
          value={selectedActivity}
        />
      </SelectField>
    </Flyout>
  );
};

export default ActivityLogFilter;
