import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.past_due');

const PastDueBanner = () => {
  const { productTerm } = useAccountTerminology();
  const dispatchShowModal = useDispatchSetShowModal();

  return (
    <>
      <AccountStatusBanner
        buttonOnClick={() => dispatchShowModal('updatePaymentMethod', true)}
        buttonText={t('button')}
        className='past-due-banner'
        emoji='😱'
        messageBody={t('body', { productTerm })}
        messageTitle={t('title')}
      />
    </>
  );
};

export default PastDueBanner;
