import styled from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const DescriptionWrapper = styled.div`
  span {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const Textarea = styled.div`
  width: 100%;
  height: 100%;
  max-height: 7rem;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  white-space: pre-wrap;

  ${fontSm4};
`;
