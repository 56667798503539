import React from 'react';
import styled, { css } from 'styled-components';

import { EmptyMediaPreview } from '../../../../lib/gcsImages';
import { fontSm4 } from '../../../styled/TypeSystem';

const EmptyPreviewStyles = css`
  background-image: url(${EmptyMediaPreview});
  background-position-x: center;
  background-position-y: 30%;
  background-repeat: no-repeat;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

const EmptyLinkPreview = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-top: ${({ theme }) => theme.constants.spacerLg1};
  height: 6.132rem;
  ${EmptyPreviewStyles};
`;

const EmptyLinkPreviewLabel = styled.p`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

interface Props {
  ariaLabel: string;
  label: string;
}

const NonClickablePreviewState = ({ ariaLabel, label }: Props) => {
  return (
    <>
      <EmptyLinkPreview aria-label={ariaLabel} />
      <EmptyLinkPreviewLabel>{label}</EmptyLinkPreviewLabel>
    </>
  );
};

export default NonClickablePreviewState;
