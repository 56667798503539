import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { ReferFriendModalHeader as HeaderImage } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useFetchReferralLinkDataQuery } from '../../../../../redux/services/resourceApis/billing/billingApi';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import P from '../../../../design_system/text/P';
import InputField from '../../../../design_system/Triage/InputField';
import Loader from '../../../../design_system/Triage/Loader';
import { ReferralCodeWrapper } from './styles';
import Terms from './Terms';

const t = initTranslations('refer_a_friend_modal');

export type ReferAFriendModalProps = {
  showModal: boolean;
  onCloseRequest: () => void;
};

type ReferralSectionProps = {
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  link: string;
  existsCompanyMemberMessage: boolean;
  message: string | undefined | null;
};

const ReferralSection = ({
  isLoading,
  error,
  link,
  existsCompanyMemberMessage,
  message,
}: ReferralSectionProps) => {
  if (isLoading) return <Loader />;
  if (error) return <BasicErrorDisplay error={error} />;

  return (
    <ReferralCodeWrapper>
      {link && <InputField id='referral-url-input' readOnly value={link} />}
      {existsCompanyMemberMessage && <P text={message} />}
    </ReferralCodeWrapper>
  );
};

const ReferAFriendModal = (props: ReferAFriendModalProps) => {
  const { onCloseRequest, showModal } = props;
  const [copiedCode, setCopiedCode] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [existsCompanyMemberMessage, setExistsCompanyMemberMessage] = useState(false);
  const { productTerm } = useAccountTerminology();
  const { data, isLoading, error, isError } = useFetchReferralLinkDataQuery(undefined, {
    skip: !showModal,
  });

  const primaryButtonArgs = existsCompanyMemberMessage
    ? {
        primaryButtonText: undefined,
        primaryButtonTask: undefined,
      }
    : {
        isDisabled: isLoading || isError,
        primaryButtonText: copiedCode ? t('copied') : t('copy_my_link'),
        primaryButtonTask: () => copyLink(),
      };

  const taskModalArgs: TaskModalProps = {
    title: t('offers.special_250.title'),
    subtitle: t('offers.special_250.subtitle', { productTerm }),
    onCloseRequest,
    processing: false,
    desktopSize: 'md',
    headerImage: HeaderImage,
    heapModalName: 'refer-a-friend-modal',
    disabledPadding: {
      vertical: { top: true },
    },
    ...primaryButtonArgs,
  };

  useEffect(() => {
    if (data) {
      const { message: referralMessage, link } = data;
      link && setReferralLink(link);
      setExistsCompanyMemberMessage(referralMessage === 'You are a member of this company');
    }
  }, [data]);

  const copyLink = () => {
    copy(referralLink);
    setCopiedCode(true);
  };

  return (
    <>
      {showModal && (
        <TaskModal {...taskModalArgs}>
          <ReferralSection
            error={error}
            existsCompanyMemberMessage={existsCompanyMemberMessage}
            isLoading={isLoading}
            link={referralLink}
            message={data?.message}
          />
          <Terms />
        </TaskModal>
      )}
    </>
  );
};

export default ReferAFriendModal;
