import styled from 'styled-components';

export const UniversalAddMenu = styled.div<{ nonEnglishLocale?: boolean }>`
  width: ${({ nonEnglishLocale }) => (nonEnglishLocale ? 'auto' : '210px')};
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  z-index: 10;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  overflow: hidden;
`;
