export type SwitchAccountResult = {
  redirectUrl: string;
};

export type StatusBannerDataResponse = {
  date: string | null;
};

export enum StatusBanner {
  PendingHold = 'pending_hold',
  OnHold = 'on_hold',
  PendingCancellation = 'pending_cancellation',
}

export interface StatusBannerDataParams {
  type: StatusBanner;
}
