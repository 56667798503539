import React from 'react';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import { EditUser } from '../../../../../hooks/useTeammateState';
import { daysFromNow } from '../../../../../lib/dateUtils';
import { FormikWrapper } from '../FormikWrapper';
import { EditFormValues, TeammateModalProps } from '../InterfaceAndTypes';

const EditTeammateModal = ({ onSuccess }: TeammateModalProps) => {
  const { prefillOptions, teammateModalVisible, setTeammateModalVisible } = useTeammateModal();
  const closeModal = () => setTeammateModalVisible(false);

  if (!teammateModalVisible || !prefillOptions) return <></>;

  const user = prefillOptions.user as EditUser;
  const { groups } = user;
  const roles = groups.filter(({ kind }) => kind === 'role');
  const teams = groups.filter(({ kind }) => kind === 'team');
  const tomorrowString = daysFromNow(1).toISOString().slice(0, 10);

  const getFormattedDate = (dateString: string | null) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const [month, day, year] = formattedDate.split('/');

      return `${year}-${month}-${day}`;
    }

    return tomorrowString;
  };

  const getHour = (dateString: string | null) => {
    if (dateString) {
      const date = new Date(dateString);

      return date.toLocaleTimeString('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
      });
    }

    return '5:00 AM';
  };

  const initialValues: EditFormValues = {
    schedule_archive: !!user.scheduled_archive_at,
    scheduled_archive_at: getFormattedDate(user.scheduled_archive_at),
    scheduled_archive_at_hour: getHour(user.scheduled_archive_at),
    scheduled_invite_at: getFormattedDate(user.scheduled_invite_at),
    scheduled_invite_at_hour: getHour(user.scheduled_invite_at),
    schedule_invite: !!user.scheduled_invite_at,
    name: user.name,
    email: user.email,
    title: user.title,
    permission: user.permission,
    parent_id: user.reports_to ? `${user.reports_to.id}` : null,
    group_ids: groups.map((group) => `${group.id}`),
    team_ids: teams.map((team) => `${team.id}`),
    role_ids: roles.map((role) => `${role.id}`),
    show_in_directory: user.show_in_directory,
    phone_number: user.phone_number,
    tomorrow_date: tomorrowString,
  };

  return (
    <FormikWrapper
      action='edit'
      closeModal={closeModal}
      initialValues={initialValues}
      onSuccess={onSuccess}
      prefillOptions={prefillOptions}
      userId={user.id}
    />
  );
};

export default EditTeammateModal;
