import React from 'react';

import {
  NoResultsGraphic as EmptyState,
  NoResultsGraphicDark as EmptyStateDark,
} from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import {
  setActivityLogFilters,
  setActivityLogPage,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetActivityLogQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import MessageCardLoader from '../../../notifications/NotificationsIndexView/shared/MessageCardLoader/MessageCardLoader';
import ActivityCardsList from '../../../shared/activities/ActivityCardsList';

const t = initTranslations('reports.activity_log');

const ActivityLogTab = () => {
  const tabPage = useAppSelector((state) => state.reports.activityLog.page);
  const tabFilters = useAppSelector((state) => state.reports.activityLog.filters);
  const dispatch = useAppDispatch();

  const { data, isFetching, isError } = useGetActivityLogQuery({
    activityType: tabFilters.activityType,
    userIds: tabFilters.userIds,
    curriculumIds: tabFilters.curriculumIds,
    timeRange: tabFilters.timeRange,
    page: tabPage,
  });

  if (isFetching) return <MessageCardLoader />;
  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    activities,
    limit_value: limitValue,
    total_pages: totalPages,
    total_activities_count: totalElementsCount,
  } = data;

  const groupKeys = Object.keys(activities);

  return (
    <>
      {groupKeys.length ? (
        <>
          <ActivityCardsList activityGroups={activities} />
          <Pagination
            activePage={tabPage}
            itemsCountPerPage={limitValue}
            onChange={(page) => {
              dispatch(setActivityLogPage(page));
            }}
            showPaginationDetails
            totalItemsCount={totalElementsCount}
            totalPages={totalPages}
          />
        </>
      ) : (
        <NoResults
          darkImage={EmptyStateDark}
          heading={t('empty_state')}
          iconWidth='45%'
          lightImage={EmptyState}
          subHeaderCta={{
            action: () =>
              dispatch(setActivityLogFilters({ activityType: '', userIds: [], curriculumIds: [] })),
            text: t('empty_state_advice'),
          }}
        />
      )}
    </>
  );
};

export default ActivityLogTab;
