import { darken } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import initTranslations from '../../../../lib/initTranslations';
import { useCreateFeedbackMutation } from '../../../../redux/services/resourceApis/aiFeedbacks/aiFeedbacksApi';
import { FeedbackCategory } from '../../../../redux/services/resourceApis/aiFeedbacks/types';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import { Option } from '../../../design_system/core/SelectOption/types';
import Icon from '../../../design_system/display/icons/Icon';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import TextareaField from '../../../design_system/Triage/fields/TextAreaField';
import { deprecated, fontSm5 } from '../../../styled/TypeSystem';
import { ComposeModalType } from '../ComposeModalFactory/ComposeModalFactory';

const FeedbackWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
`;

const FeedbackCTA = styled(DefaultButton)`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};

  svg {
    color: ${({ theme: { vars } }) => vars.stateError};
  }

  &:hover {
    svg {
      color: ${({ theme: { vars } }) => darken(0.2, vars.stateError)};
    }
  }
  ${deprecated.fontSm3};
`;

const FeedbackFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerMd3} ${constants.spacerMd2} ${constants.spacerMd2}`};
`;

const ActionContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const SuccessContainer = styled.div`
  display: flex;
  max-width: fit-content;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 ${constants.spacerLg1} 0`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};

  svg {
    color: ${({ theme: { vars } }) => vars.stateSuccess};
    padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }

  ${fontSm5};
`;

export type FeedbackStatus = 'started' | 'un-started' | 'complete';

const options: Option[] = formatOptions([
  { value: 'inaccurate', label: 'Inaccurate' },
  { value: 'bias_discrimination', label: 'Bias / Discrimination' },
  { value: 'generic_irrelevant', label: 'Too Generic / Irrelevant' },
  { value: 'other', label: 'Other (Please Explain)' },
]);

const t = initTranslations('compose.feedback');

type Props = {
  composeModalType: ComposeModalType;
  feedbackStatus: FeedbackStatus;
  setFeedbackStatus: (status: FeedbackStatus) => void;
  aiCompletionId: number;
};

const Feedback = ({
  aiCompletionId,
  composeModalType,
  feedbackStatus,
  setFeedbackStatus,
}: Props) => {
  const { cdpFeedbackLeft } = useAnalyticsUserEvents();
  const [feedbackCategory, setFeedbackCategory] = useState<FeedbackCategory | null>(null);
  const [comment, setComment] = useState<string | undefined>();
  const [createFeedback, result] = useCreateFeedbackMutation();
  const { isSuccess, data, isLoading } = result;

  useEffect(() => {
    if (data) {
      setFeedbackStatus('complete');
      cdpFeedbackLeft({
        ai_completion_id: aiCompletionId,
        compose_experience: composeModalType,
        feedbackId: data.id,
      });
    }
  }, [aiCompletionId, cdpFeedbackLeft, composeModalType, data, setFeedbackStatus]);

  return (
    <>
      {isSuccess ? (
        <SuccessContainer>
          <Icon name='check' weight='solid' />
          {t('submit_success')}
        </SuccessContainer>
      ) : (
        <FeedbackWrapper>
          <FeedbackCTA
            buttonType='tertiary'
            iconName='exclamation-circle'
            id='compose-start-feedback'
            onClick={() => {
              setFeedbackStatus(feedbackStatus === 'started' ? 'un-started' : 'started');
            }}
            text={t('report_an_issue_cta')}
          />
          {feedbackStatus === 'started' && (
            <FeedbackFormWrapper>
              <SingleSelectField
                className='compose-start-feedback-select'
                defaultValue={null}
                isClearable
                menuPosition='fixed'
                name='feedback-type'
                onDefaultSelected={() => setFeedbackCategory(null)}
                onNonDefaultSelected={(value: FeedbackCategory) => setFeedbackCategory(value)}
                options={options}
                placeholder={t('category_placeholder')}
                value={feedbackCategory}
              />
              <TextareaField
                onChange={(e) => setComment(e.target.value)}
                placeholder={t('comment_placeholder')}
                rows={4}
                value={comment}
              />
              <ActionContainer>
                <DefaultButton
                  buttonType='tertiary'
                  disabled={isLoading}
                  fullWidth
                  id='compose-feedback-cancelled'
                  loading={isLoading}
                  onClick={() => setFeedbackStatus('un-started')}
                  text={t('cancel_button_text')}
                />
                <DefaultButton
                  buttonType='secondary'
                  disabled={!feedbackCategory || isLoading}
                  fullWidth
                  id='compose-feedback-submitted'
                  loading={isLoading}
                  onClick={() =>
                    feedbackCategory &&
                    createFeedback({
                      aiCompletionId,
                      comment,
                      category: feedbackCategory,
                    })
                  }
                  text={t('submit_button_text')}
                />
              </ActionContainer>
            </FeedbackFormWrapper>
          )}
        </FeedbackWrapper>
      )}
    </>
  );
};

export default Feedback;
