import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';

export const BillingPagePlansWithTrainualPlusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CardGroupImage = styled.img`
  position: absolute;
  display: none;
  z-index: 1;

  @media (min-width: ${mediaBreakpointPxLg}) {
    display: block;
    left: calc(50% - 10px);
    top: calc(50% + 25px);
    transform: translate(-50%, -50%);
  }
`;
