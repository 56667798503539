import React, { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import useIdRegistry from '../../../../../hooks/useIdRegistry';
import useWindowResize from '../../../../../hooks/useWindowResize';
import Poppable from '../../../../Poppable';
import { mediaBreakpointXl } from '../../../../styled/Breakpoint';
import MenuOption from '../../../core/MenuOption/MenuOption';
import { MenuOptions } from '../../../core/MenuOption/shared';
import OutsideClickHandler from '../../../OutsideClickHandler';
import useActiveMenuHandler from '../../../useActiveMenuHandler';
import { MenuButton, MenuTriggerIcon } from './styles';
import { GetMenuButtonProps, ThreeDotMenuOptionProps, ThreeDotMenuProps } from './types';

function menuButtonIconName(isVertical: boolean) {
  return isVertical ? 'ellipsis-v' : 'ellipsis-h';
}

export enum MenuButtonType {
  Default,
  Navigation,
  Compact,
  PageHeader,
}

export const GetMenuButton = ({
  className = 'three-dot-menu-button',
  id,
  menuButtonType = MenuButtonType.Default,
  isVertical = true,
  iconFontSize = '1.25rem',
}: GetMenuButtonProps) => {
  useIdRegistry(id);
  const { constants } = useTheme();
  const { heightXs } = constants;

  switch (menuButtonType) {
    case MenuButtonType.Navigation:
      return (
        <MenuButton className={className} data-testid={id} id={id}>
          <MenuTriggerIcon iconFontSize='1rem' name={menuButtonIconName(false)} weight='regular' />
        </MenuButton>
      );
    case MenuButtonType.Compact:
      return (
        <MenuButton
          className={className}
          data-testid={id}
          id={id}
          menuButtonStyling={{ width: '1.5rem', height: '1.25rem' }}
        >
          <MenuTriggerIcon
            iconFontSize='1.1rem'
            name={menuButtonIconName(false)}
            weight='regular'
          />
        </MenuButton>
      );
    case MenuButtonType.PageHeader:
      return (
        <MenuButton
          className={className}
          data-testid={id}
          id={id}
          menuButtonStyling={{ width: '1.5rem', height: heightXs }}
        >
          <MenuTriggerIcon
            iconFontSize={iconFontSize}
            name={menuButtonIconName(isVertical)}
            weight='regular'
          />
        </MenuButton>
      );
    case MenuButtonType.Default:
    default:
      return (
        <MenuButton className={className} data-testid={id} id={id}>
          <MenuTriggerIcon
            iconFontSize={iconFontSize}
            name={menuButtonIconName(isVertical)}
            weight='regular'
          />
        </MenuButton>
      );
  }
};

const ThreeDotMenu = (props: ThreeDotMenuProps) => {
  const {
    isDisabled,
    menuOptions,
    menuModifiers,
    menuOptionsClassName = 'open-three-dot-menu',
    menuPlacement,
    menuStyling,
    menuStrategy,
    ...propsForMenuButton
  } = props;
  const { width } = useWindowResize();
  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId: propsForMenuButton.id,
  });

  const placement = menuPlacement
    ? menuPlacement
    : width >= mediaBreakpointXl
    ? 'bottom-start'
    : 'bottom-end';

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(e.target as Element).classList.contains('three-dot-menu-button')) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  const filteredArray: ThreeDotMenuOptionProps[] = useMemo(
    () => menuOptions.filter((option) => option.visible),
    [menuOptions]
  );

  if (!filteredArray.length) return null;

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <MenuOptions className={menuOptionsClassName} data-button-id={props.id}>
            {filteredArray.map((option, index) => {
              return (
                <MenuOption
                  iconName={option.iconName}
                  id={option.id}
                  isDisabled={option.isDisabled}
                  key={`three-dot-menu-option-${index}`}
                  onClick={(e) => {
                    e.preventDefault();
                    option.onClick(e);
                  }}
                  title={option.title}
                  tooltipText={option.tooltipText}
                />
              );
            })}
          </MenuOptions>
        </OutsideClickHandler>
      }
      menuStyling={menuStyling}
      modifiers={menuModifiers}
      onClick={() => {
        if (!isDisabled) {
          handleMenuClick();
        }
      }}
      placement={placement}
      strategy={menuStrategy}
      trigger={<GetMenuButton {...propsForMenuButton} />}
    />
  );
};

export default ThreeDotMenu;
