import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import {
  SearchesEmptyState as EmptyState,
  SearchesEmptyStateDark as EmptyStateDark,
} from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { setIndividualUserReportPage } from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetIndividualUserReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import IndividualUserReportTableHeader from './IndividualUserReportTableHeader';
import { tableReducer } from './reducer';
import TableRow from './TableRow';
import { IndividualUserReportTableProps, ManageIndividualUserReportOption } from './types';

const t = initTranslations('reports.individual_user_report.table');

const IndividualUserReportTable = ({ userId }: IndividualUserReportTableProps) => {
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/individual_user_report`;
  const [cookies, setCookie] = useCookies(['individual_user_report_selected_sort']);
  const isCookies = cookies.individual_user_report_selected_sort;
  const { page, filters, searchTerm } = useAppSelector(
    (state) => state.reports.individualUserReport
  );
  const dispatchSlice = useAppDispatch();
  const sortColumn = isCookies && cookies.individual_user_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.individual_user_report_selected_sort['dir'];
  const debouncedSearchValue = useDebounce<string>(searchTerm.trim(), 500);

  const initialState: ManageIndividualUserReportOption = {
    sortColumn: sortColumn ?? 'name',
    sortDirection: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
  };
  const [tableState, dispatch] = useReducer(tableReducer, initialState);

  useEffect(() => {
    setCookie(
      'individual_user_report_selected_sort',
      JSON.stringify({ column: tableState.sortColumn, dir: tableState.sortDirection }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableState, tableState.sortDirection, tableState.sortColumn]);

  const { data, isFetching, isError } = useGetIndividualUserReportQuery({
    ...filters,
    id: userId,
    page,
    searchTerm: debouncedSearchValue,
    sortCol: tableState.sortColumn,
    sortColDir: tableState.sortDirection,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    curriculums,
    limit_value: limitValue,
    total_pages: totalPages,
    total_curriculums_count: totalCurriculumsCount,
  } = data;

  return (
    <>
      <TableActionsHeader
        isFetching={isFetching}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        totalCurriculumsCount={totalCurriculumsCount}
        userId={userId}
      />
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {curriculums.length ? (
            <>
              <TableWrapper>
                <TableStyled className='individual-user-report-table'>
                  <IndividualUserReportTableHeader
                    dispatch={dispatch}
                    tableHeaderState={tableState}
                  />
                  <tbody>
                    {curriculums.map((curriculum) => {
                      return (
                        <TableRow
                          curriculum={curriculum}
                          key={`individual-user-report-tr-${curriculum.id}`}
                          userId={userId}
                        />
                      );
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatchSlice(setIndividualUserReportPage(page));
                }}
                showPaginationDetails
                totalItemsCount={totalCurriculumsCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('empty_state')}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default IndividualUserReportTable;
