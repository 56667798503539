import React from 'react';
import styled, { css } from 'styled-components';

import { ContentProps } from '../../../../../types/ContentProps';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { TruncatedText } from '../../../Triage/TruncatedText';
import { TooltipNeverCondition, TooltipPositiveCondition } from '../SharedLabelTypes';
import TooltipHoverIcon from './TooltipHoverIcon';

export type IconHoverTooltipProps =
  | (TooltipNeverCondition & { tooltipHoverIcon?: never })
  | (TooltipPositiveCondition & { tooltipHoverIcon: boolean });

export type Props = {
  htmlFor: string;
  disabled?: boolean;
  truncate?: boolean;
} & IconHoverTooltipProps &
  ContentProps;

const LabelWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledLabel = styled.label<{ disabled?: boolean; truncate?: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-bottom: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme: { vars } }) => vars.textDisabled};
      cursor: not-allowed;
    `};

  ${({ truncate }) => {
    if (truncate) return TruncatedText({});
  }};

  ${fontSm5};
`;

const ControlLabel = ({
  htmlFor,
  text,
  component,
  tooltipText,
  tooltipId,
  tooltipHoverIcon,
  disabled = false,
  truncate,
}: Props) => {
  return (
    <LabelWrapper>
      <StyledLabel disabled={disabled} htmlFor={htmlFor} truncate={truncate}>
        {text || component}
      </StyledLabel>
      {tooltipHoverIcon && tooltipId && tooltipText && (
        <TooltipHoverIcon tooltipId={tooltipId} tooltipText={tooltipText} />
      )}
    </LabelWrapper>
  );
};

export default ControlLabel;
