import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { useLocalesContext } from '../../../../../../contexts/LocalesContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { safeDecodeURIComponent } from '../../../../../../lib/safeDecodeURIComponent';
import useDebounce from '../../../../../../lib/useDebounce';
import { useGetQuickSearchesResultsMutation } from '../../../../../../redux/services/resourceApis/searches/searchesApi';
import { QuickSearchResultsResponse } from '../../../../../../redux/services/resourceApis/searches/types';
import { routes, useRouterHelper } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import QuickSearchDropdown from '../QuickSearchDropdown/QuickSearchDropdown';
import {
  OutsideClickWrapper,
  QuickSearchClearIconButton,
  QuickSearchFieldWrapper,
  QuickSearchIconButton,
  QuickSearchIconsWrapper,
  QuickSearchInput,
  QuickSearchLoadingIcon,
} from './styles';

const t = initTranslations('top_nav.quick_search.input_field');

export type QuickSearchFieldProps = {
  inputFocused: boolean;
  setInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  initialValue?: string;
};

const QuickSearchField = ({
  inputFocused,
  setInputFocused,
  initialValue = '',
}: QuickSearchFieldProps) => {
  const { slug } = useCurrentAccount();
  const [searchValue, setSearchValue] = useState(initialValue);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchIconAriaLabel = inputFocused
    ? t('aria_label_submit_search')
    : t('aria_label_open_search');
  const hasSearchValue = searchValue.length > 0;
  const [getQuickSearchResult, quickSearchResult] = useGetQuickSearchesResultsMutation();
  const { isLoading, data: quickSearchResultData } = quickSearchResult;
  const [savedQuickSearchResult, setSavedQuickSearchResult] = useState<
    QuickSearchResultsResponse | undefined
  >(quickSearchResultData);
  const hasSearchValueAndFocus = hasSearchValue && inputFocused;
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);
  const routeState = useRouterHelper();
  const { nonEnglishLocale } = useLocalesContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target && setSearchValue(event.target.value);
  };

  const clearSearchValue = () => {
    setSearchValue('');
  };

  const navigateToSearchPage = () => {
    routeTo(routes.search({ slug, search: safeDecodeURIComponent(searchValue), kind: 'company' }));
  };

  const handleClickOnSearchIcon = () => {
    if (hasSearchValueAndFocus) {
      navigateToSearchPage();
    } else {
      searchInputRef.current?.focus();
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && navigateToSearchPage();
  };

  useEffect(() => {
    debouncedSearchValue && getQuickSearchResult({ search: debouncedSearchValue });
  }, [debouncedSearchValue, getQuickSearchResult]);

  useEffect(() => {
    if (quickSearchResultData && !isLoading) {
      setSavedQuickSearchResult(quickSearchResultData);
    }
  }, [quickSearchResultData, isLoading]);

  useEffect(() => {
    const currentSearchInputRef = searchInputRef.current;

    return () => {
      // Reset search to initial state when navigating to another page
      currentSearchInputRef?.blur();
      clearSearchValue();
      setInputFocused(false);
    };
  }, [routeState?.route, setInputFocused]);

  return (
    <OutsideClickWrapper onOutsideClick={() => setInputFocused(false)}>
      <QuickSearchFieldWrapper
        id='top-nav-quick-search'
        isFocused={inputFocused}
        nonEnglishLocale={nonEnglishLocale}
      >
        <QuickSearchIconButton
          ariaLabel={searchIconAriaLabel}
          buttonSize='md'
          highlight={hasSearchValueAndFocus}
          name='search'
          onClick={handleClickOnSearchIcon}
          weight='regular'
        />
        <QuickSearchInput
          aria-controls='quick-search-dropdown'
          aria-expanded={hasSearchValueAndFocus}
          aria-haspopup='true'
          onChange={handleChange}
          onFocus={() => setInputFocused(true)}
          onKeyUp={handleKeyUp}
          placeholder={t('placeholder')}
          ref={searchInputRef}
          value={searchValue}
        />
        <QuickSearchIconsWrapper isFocused={inputFocused}>
          {isLoading ? (
            <QuickSearchLoadingIcon
              fixedWidth
              name='spinner-third'
              spinSpeed='fast'
              weight='solid'
            />
          ) : (
            hasSearchValue && (
              <QuickSearchClearIconButton
                ariaLabel={t('aria_label_clear_search')}
                buttonSize='sm'
                name='circle-xmark'
                onClick={clearSearchValue}
                weight='regular'
              />
            )
          )}
        </QuickSearchIconsWrapper>
        {hasSearchValueAndFocus && (
          <QuickSearchDropdown
            isLoading={isLoading}
            quickSearchResultData={savedQuickSearchResult}
            searchInputRef={searchInputRef}
            searchValue={searchValue}
          />
        )}
      </QuickSearchFieldWrapper>
    </OutsideClickWrapper>
  );
};

export default QuickSearchField;
