import autosize from '@github/textarea-autosize';
import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import capitalize from '../../../../../lib/capitalize';
import initTranslations from '../../../../../lib/initTranslations';
import { StyledErrorMessage } from '../../../../styled/Inputs';
import { deprecated, fontMd5, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import FieldLabel from '../../../core/FieldLabel';
import { TooltipProps } from '../../../core/Labels/SharedLabelTypes';

const StyledTextarea = styled.textarea<{ inputFontSize: InputFontSize }>(
  ({ theme: { vars }, inputFontSize }) => css`
    border: none;
    color: ${vars.textDefault};
    outline: none;
    resize: initial;
    width: 100%;

    &::placeholder {
      color: ${vars.textPlaceholder};
    }

    ${getFontStyles(inputFontSize)};
  `
);

const TextareaWrapper = styled.div<{ hasError: boolean; noMarginTop: boolean }>`
  border: ${({ theme: { constants, vars }, hasError }) =>
    hasError
      ? `${constants.borderWidthSm} solid ${vars.stateError}`
      : `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  margin-bottom: ${({ theme: { constants }, hasError }) =>
    hasError ? `${constants.spacerSm3}` : 0};
  border-radius: 0.313rem;
  display: inline-block;
  padding: 0.625rem 1rem;
  position: relative;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0' : '0.625rem')};
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  :focus-within {
    outline: none;
    border-color: ${({ theme: { vars }, hasError }) =>
      hasError ? vars.stateError : vars.accentPrimaryDefault};
    color: ${({ theme: { vars } }) => vars.textDefault};
    -webkit-box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
    caret-color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

const InputGroup = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 100%;
`;

//DS Override: ContextQuestionsPanel in AI Outliner anchors character count to the right
const StyledCharacterCount = styled.span<{ anchoredRightCharacterCount: boolean }>`
  bottom: 0;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-size: 0.703rem;
  ${({ anchoredRightCharacterCount }) =>
    anchoredRightCharacterCount
      ? css`
          right: ${({ theme: { constants } }) => constants.spacerSm2};
        `
      : css`
          left: ${({ theme: { constants } }) => constants.spacerMd2};
        `};
  position: absolute;
`;

export interface CharacterCountInterface {
  maxCharacters?: number;
  charactersUsed: number;
  anchoredRightCharacterCount: boolean;
  hasFieldError?: boolean;
  //anchor prop set to required for usability in AI outliner
}

const CharacterCount = ({
  charactersUsed,
  maxCharacters,
  anchoredRightCharacterCount,
}: CharacterCountInterface) => {
  if (!maxCharacters) {
    return <></>;
  }

  return (
    <StyledCharacterCount anchoredRightCharacterCount={anchoredRightCharacterCount}>
      {charactersUsed} / {maxCharacters}
    </StyledCharacterCount>
  );
};

const t1 = initTranslations('groups');
const t2 = initTranslations('curriculums.ai_outliner.slideout.custom');

export type InputFontSize = 'xs' | 'sm' | 'md' | 'lg';

export const getFontStyles = (inputFontSize: InputFontSize) => {
  switch (inputFontSize) {
    case 'xs':
      return css`
        ${fontSm4}
        line-height: 1.375rem;
      `;
    case 'sm':
      return css`
        ${fontSm5}
        line-height: 1.375rem;
      `;
    case 'md':
      return css`
        font-weight: ${({ theme }) => theme.constants.fontMedium};
        ${deprecated.fontMd4}
      `;
    case 'lg':
      return css`
        font-weight: ${({ theme }) => theme.constants.fontBold};
        ${fontMd5}
        line-height: normal;
      `;
  }
};

export type Props = {
  id?: string;
  disabled?: boolean;
  name?: string;
  attributeName: string;
  resourceName: string;
  placeholder: string;
  value: string | undefined;
  label?: string;
  maxCharacters?: number;
  rows?: number;
  submitChanges?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  autofocus?: boolean;
  noTopMargin?: boolean;
  required?: boolean;
  inputFontSize?: InputFontSize;
  aiOutliner?: boolean;
  anchoredRightCharacterCount?: boolean;
} & TooltipProps;

const TextareaWithCharacterCount = (props: Props) => {
  const {
    id,
    name,
    attributeName,
    resourceName,
    placeholder,
    value,
    maxCharacters,
    label,
    rows,
    submitChanges,
    onChange,
    autofocus = true,
    noTopMargin = false,
    tooltipText,
    tooltipId,
    required = false,
    aiOutliner = false,
    anchoredRightCharacterCount = false,
    inputFontSize = 'sm',
    onBlur,
    onFocus,
    ...standardProps
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const characters = value ?? '';
  const charactersUsed = characters.length;
  const noMarginTop = noTopMargin || !!label;
  const disabled = standardProps.disabled || false;
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const tooltipProps = tooltipId && tooltipText ? { tooltipId, tooltipText } : {};
  useLayoutEffect(() => {
    autosize(inputRef.current);
  }, []);

  useEffect(() => {
    if (maxCharacters && charactersUsed > maxCharacters) {
      setErrorMessage(
        aiOutliner
          ? t2('errors.max_characters_exceeded')
          : t1('inline_textarea.error_message_character_length', {
              resourceName: capitalize(resourceName),
              attributeName,
              maxCharacters,
            })
      );
    } else {
      setErrorMessage('');
    }
  }, [attributeName, aiOutliner, resourceName, charactersUsed, maxCharacters]);

  return (
    <InputGroup>
      {label && <FieldLabel {...tooltipProps} htmlFor={id} required={required} text={label} />}
      <TextareaWrapper hasError={!!errorMessage} noMarginTop={noMarginTop}>
        <StyledTextarea
          autoFocus={autofocus}
          cols={100}
          disabled={disabled}
          id={id}
          inputFontSize={inputFontSize}
          name={name}
          onBlur={(e) => {
            const timer = setTimeout(() => {
              submitChanges && submitChanges();
              onBlur && onBlur(e);
            }, 300);

            return () => clearTimeout(timer);
          }}
          onChange={onChange}
          onFocus={(e) => {
            e.target.select();
            onFocus && onFocus();
          }}
          onKeyDown={(e) => e.key === 'Enter' && submitChanges && submitChanges()}
          placeholder={placeholder}
          ref={inputRef}
          rows={rows || 2}
          value={value || ''}
        />
        <CharacterCount
          anchoredRightCharacterCount={anchoredRightCharacterCount}
          charactersUsed={charactersUsed}
          maxCharacters={maxCharacters}
        />
      </TextareaWrapper>
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </InputGroup>
  );
};

export default TextareaWithCharacterCount;
