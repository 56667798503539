import { PlansIntervals } from '../../components/application/billing/simple_pricing/BillingPage/BillingPagePlans/types';
import {
  SimplePricingBillingPlans,
  SimplePricingBillingPlansWithTrainualPlus,
} from '../../redux/services/resourceApis/billing/simplePricingTypes';
import { BillingInterval } from '../../types/BillingInterval';

export function getBillingPlansByInterval(
  selectedInterval: BillingInterval,
  billingPlans: SimplePricingBillingPlansWithTrainualPlus
) {
  const plansIntervals: PlansIntervals = {
    year: 'annual',
    month: 'monthly',
  };

  return billingPlans[plansIntervals[selectedInterval] as keyof SimplePricingBillingPlans];
}
