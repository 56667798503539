import styled, { css } from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import P from '../../../../../design_system/text/P';
import { mediaBreakpointPxMd } from '../../../../../styled/Breakpoint';
import { fontMd3 } from '../../../../../styled/TypeSystem';

export const CardsWrapper = styled.div<{ isTrainualPlusActive?: boolean }>(
  ({ theme: { constants }, isTrainualPlusActive }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: ${constants.spacerLg2};
    margin-bottom: ${constants.spacerLg1};

    @media (max-width: ${mediaBreakpointPxMd}) {
      flex-direction: column;
    }

    > div {
      flex-basis: 100%;

      @media (min-width: ${mediaBreakpointPxMd}) {
        ${!isTrainualPlusActive && 'flex-basis: 50%;'}
      }
    }
  `
);

export const CardTitle = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;

export const HighlightedBlock = styled.div`
  display: flex;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

export const TertiaryButton = styled(DefaultButton)<{ disabled?: boolean }>`
  min-width: 3rem;

  ${({ disabled, theme: { vars } }) =>
    !disabled && {
      borderColor: `${vars.borderDefault} !important`,
      color: `${vars.textSubdued} !important`,
      backgroundColor: `${vars.foundationSurface1}`,

      '&:hover': {
        backgroundColor: `${vars.foundationBase1} !important`,
      },
    }};
`;

export const PrimaryButton = styled(DefaultButton)<{ disabled?: boolean }>`
  ${({ disabled, theme: { vars } }) =>
    !disabled && {
      borderColor: `${vars.accentPrimaryDefault}`,
      color: `${vars.foundationSurface1}`,
      backgroundColor: `${vars.accentPrimaryDefault}`,

      '&:hover': {
        backgroundColor: `${vars.accentPrimaryHover}`,
        borderColor: `${vars.accentPrimaryHover}`,
      },
    }};
`;

export const StyledBoldP = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

export const StyledMediumP = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

export const StyledSpan = styled.span<{ isExceedsTotalUserSlotsCount?: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${({ isExceedsTotalUserSlotsCount, theme: { vars } }) =>
    isExceedsTotalUserSlotsCount && {
      color: vars.stateError,
    }};
`;

TertiaryButton.defaultProps = {
  buttonType: 'tertiary',
};
