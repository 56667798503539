import styled from 'styled-components';

import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import { deprecated, fontMd1, fontSm4, fontSm5 } from '../../../styled/TypeSystem';

export const Card = styled.a`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  padding: ${({ theme: { constants } }) => constants.spacerLg1};
  height: 20rem;
  width: 100%;
  color: inherit;
  text-decoration: none;
  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: calc(50% - 1rem);
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const EmojiWrapper = styled.div<{ clickable?: boolean }>`
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  display: flex;
  height: ${({ theme: { constants } }) => constants.spacerLg3};
  justify-content: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  min-width: ${({ theme: { constants } }) => constants.spacerLg3};
  ${deprecated.fontMd4};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  :hover {
    background: ${({ theme: { vars } }) => vars.borderSurface1};
  }
`;

export const LockedBadgeWrapper = styled.div`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const TitlesBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm2}`};
`;

export const CurriculumTitle = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0;
  text-decoration: none;
  word-break: break-all;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${MultilineTruncatedText({})};
  ${deprecated.fontMd2};
`;

export const CardTitle = styled.div`
  display: flex;
  text-transform: uppercase;
  text-decoration: none;
  word-break: break-all;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  ${MultilineTruncatedText({})};
  ${fontSm5};

  &:hover {
    color: ${({ theme: { vars } }) => vars.accentPrimaryHover};
    text-decoration: none;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const Description = styled.p`
  text-align: left;
  height: 3.75rem;
  ${MultilineTruncatedText({ lineClamp: 3 })};
  ${fontMd1};
`;

export const InfoLine = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const InfoIconWrapper = styled.span`
  display: inline-block;
  * {
    pointer-events: none;
  }
`;

export const EstimateWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const EstimateText = styled.span`
  display: inline-block;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm4};
`;

export const BadgesWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const TopRightBlock = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

export const CurriculumThreeDotWrapper = styled.div`
  margin-right: -${({ theme: { constants } }) => constants.spacerSm3};
`;
