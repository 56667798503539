import styled, { css } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { deprecated, fontMd1, fontSm5 } from '../../../../styled/TypeSystem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerMd2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }

  & > div:last-child {
    margin-left: auto;
  }
`;

export const DocumentInfoWrapper = styled.div<{ isClickable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const DocumentImage = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
  ${deprecated.fontMd4}
`;

export const DocumentTitle = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontMd1}
`;

export const DocumentTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ProgressTextStyles = css`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5}
`;

export const UploadStatusText = styled.span<{ isError?: boolean }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ isError, theme: { vars } }) => (isError ? vars.stateError : vars.textDefault)};
  ${ProgressTextStyles};
`;

export const ProgressPercentage = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  ${ProgressTextStyles}
`;

export const ProgressBarWrapper = styled.div`
  width: 4rem;
`;

export const SuccessIcon = styled(Icon)`
  color: ${({ theme }) => theme.vars.stateSuccess};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledLink = styled(Link)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm3};
  white-space: nowrap;
`;
