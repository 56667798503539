import styled, { css } from 'styled-components';

import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import P from '../../../../../../design_system/text/P';
import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';
import { fontLg2, fontMd1, fontSm4 } from '../../../../../../styled/TypeSystem';
import { getTrainualPlusBgImg } from '../../../AddonFullScreenOverlays/shared/styles';
import { ActivePlanIndicator } from '../../BillingPagePlans/PlanDetails/styles';

export const PlansCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const PlansCardContentWrapper = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerLg2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg2}`};
  }
`;

export const PlansCardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PlansCardTitle = styled(P)<{ planBrandColor: string }>`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ planBrandColor }) => planBrandColor};
  ${fontLg2};
`;

export const PlansCardPlanText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const PlansCardText = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontMd1};
`;

export const PlansCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  height: ${({ theme: { constants } }) => constants.heightLg};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const PlansCardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PlansCardPrice = styled.span`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontLg2};
`;

export const PlansCardBillingInterval = styled.span`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  ${fontSm4};
`;

export const UpgradeButton = styled(DefaultButton)`
  margin-left: auto;
`;

export const StyledActivePlanIndicator = styled(ActivePlanIndicator)`
  max-width: 9rem;
  height: ${({ theme: { constants } }) => constants.heightMd};
  margin-top: initial;
  margin-left: auto;
  padding: initial;
`;

export const TrainualPlusTitle = styled.span(
  ({ theme: { vars } }) => css`
    color: ${vars.textDefault};
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -3px;
      width: 10.3125rem;
      height: 3.625rem;
      z-index: -1;
      background: ${getTrainualPlusBgImg(vars.stateBadgeTrainual, 165, 59)};
    }
  `
);

export const TrainualPlusBadgeWraper = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerLg1}`};
`;

export const TrainualPlusBadge = styled.img`
  max-width: 18.25rem;
  display: none;

  @media (min-width: ${mediaBreakpointPxLg}) {
    display: block;
  }
`;
