import { darken } from 'polished';
import styled, { css } from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import BaseP from '../../../../design_system/text/P/P';
import { deprecated, fontSm4 } from '../../../../styled/TypeSystem';
import { ComposeModalType } from '../../ComposeModalFactory/ComposeModalFactory';
import { ComposeStepModalMetadata } from '../../ComposeStepModal/ComposeStepModal';
import { ComposeSubjectDescriptionModalMetadata } from '../../SubjectDescriptionModal/types';
import { ComposeSurveyDescriptionModalMetadata } from '../../SurveyDescriptionModal/types';

export type ComposeModalMetadata =
  | ComposeStepModalMetadata
  | ComposeSubjectDescriptionModalMetadata
  | ComposeSurveyDescriptionModalMetadata;

export type ComposeModalStateProps = {
  isComposeModalDisabled: boolean;
  isComposeModalLoading: boolean;
  setComposeModalDisabled: (isDisabled: boolean) => void;
  setComposeModalLoading: (isLoading: boolean) => void;
};

export type ComposeModalProps = {
  composeModalType: ComposeModalType;
  closeModal: () => void;
  metadata?: ComposeModalMetadata;
} & ComposeModalStateProps;

export const H1 = styled.h1(
  ({ theme: { constants, mode, vars } }) => css`
    display: inline-flex;
    align-items: center;
    gap: ${constants.spacerSm3};
    font-weight: ${constants.fontBold};
    margin: 0 0 ${constants.spacerSm3} 0;
    color: ${mode === 'light' ? darken(0.2, '#6D57A3') : vars.textDefault};
    ${deprecated.fontMd4};
  `
);

export const P = styled(BaseP)`
  ${({ theme: { mode } }) =>
    mode === 'light' &&
    css`
      color: darken(0.2, '#6D57A3');
    `};
  ${fontSm4};
`;

export const ModalHeaderContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: ${constants.spacerSm3};
  `
);

export const Button = styled(DefaultButton)<{ loading?: boolean }>(
  ({ theme: { vars } }) => css`
    background-color: ${vars.accentPrimaryDefault};
    color: ${vars.textSurface};

    &:hover,
    &:active,
    &:focus {
      background-color: ${darken(0.2, vars.accentPrimaryDefault)};
    }

    border: none;
  `
);
