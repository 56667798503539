import React, { useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useStatusBannerDataQuery } from '../../../../../../redux/services/resourceApis/accounts/accountsApi';
import { StatusBanner } from '../../../../../../redux/services/resourceApis/accounts/types';
import { useReactivateMutation } from '../../../../../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import ReactivatedAccountCelebrationModal from '../../../per_user_pricing/ReactivatedAccountCelebrationModal/ReactivatedAccountCelebrationModal';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.pending_cancellation');

const PendingCancellationBanner = () => {
  const [showPendingCancellationConfirmationModal, setShowPendingCancellationConfirmationModal] =
    useState(false);
  const { data } = useStatusBannerDataQuery({ type: StatusBanner.PendingCancellation });
  const [reactivate, reactivateResult] = useReactivateMutation();

  useDisplayFlashOnResponse({
    result: reactivateResult,
    successFunction: () => {
      setShowPendingCancellationConfirmationModal(true);
    },
    errorMessage: t('error.unable_to_reactivate_account'),
  });

  if (!data?.date) return <></>;

  return (
    <>
      <AccountStatusBanner
        buttonOnClick={reactivate}
        buttonText={t('button')}
        className='pending-cancellation-banner'
        emoji='🥲'
        isLoading={reactivateResult.isLoading}
        messageBody={t('body')}
        messageTitle={t('title', { date: data.date })}
      />
      {showPendingCancellationConfirmationModal && <ReactivatedAccountCelebrationModal />}
    </>
  );
};

export default PendingCancellationBanner;
