import React, { useContext } from 'react';

import { useLocalesContext } from '../../../../../contexts/LocalesContext';
import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { useDisableNavigationItem } from '../../../../../hooks/useDisableNavigationItem';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useSwitchAccountMutation } from '../../../../../redux/services/resourceApis/accounts/accountsApi';
import {
  useLogoutMutation,
  useToggleDarkModeMutation,
} from '../../../../../redux/services/resourceApis/users/usersApi';
import Avatar from '../../../../design_system/display/avatar';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import ReferAFriendModal from '../../../billing/shared/ReferAFriendModal/ReferAFriendModal';
import ProfileRouterLink from '../../../people/Profile/ProfileRouterLink';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import useTrainualUniversity from '../../../publicApplication/utils/useTrainualUniversity';
import MenuItem from '../MenuItem/MenuItem';
import { Props as UserItemProps } from '../MenuItem/types';
import { USER_MENU_ID } from '../UserMenu';
import { useShowReferralModal } from '../utils/useShowReferralModal';
import LanguageSelectionPoppable from './LanguageSelectionPoppable';
import {
  DownloadAppButton,
  DownloadAppButtonWrapper,
  StyledToggle,
  UserBlock,
  UserBlockInner,
  UserBlockName,
  UserBlockTitle,
  UserCompletion,
  UserCompletionPercent,
  UserCompletionText,
  UserCompletionWrapper,
  UserMenu,
  UserMenuHeader,
} from './styles';
import { Props } from './types';

const t = initTranslations('user_menu_navigation');

const UserMenuNavigation = ({ id }: Props) => {
  const {
    hasMultiLanguageFeature,
    nonBilling,
    slug,
    splitFeatures: { deprecateDarkModeEnabled },
    adminAccount: { accountType },
  } = useCurrentAccount();
  const { isDisabledNavItem, isDisabledItemOnHoldCondition } = useDisableNavigationItem();
  const { resetAnalyticsUser } = useAnalyticsUserEvents();
  const { closeMenu } = useActiveMenuHandler({ menuId: USER_MENU_ID });
  const { configs } = usePrivateConfigs();
  const { configs: PublicConfigs } = usePublicConfigs();
  const paywallCtx = useContext(PaywallContext);
  const displayCompletions = !paywallCtx.includes('completions');
  const helpPageUrl = PublicConfigs['HELP_PAGE_URL'];
  const appleUrl = configs['DOWNLOAD_APP_APPLE'];
  const androidUrl = configs['DOWNLOAD_APP_ANDROID'];
  const trainualUniversityAccountSlug = PublicConfigs['TRAINUAL_UNIVERSITY_ACCOUNT_SLUG'];
  const {
    avatarUrl,
    completionPercentage,
    name,
    id: userId,
    title,
    styleMode,
    hasMultipleAccounts,
  } = useCurrentUser();
  const { showReferralModal, setShowReferralModal, handleClickOnReferAFriendButton } =
    useShowReferralModal();
  const isPartnerAccount = accountType === 'partner';
  const showTrainualUniversityItem = slug != trainualUniversityAccountSlug && !isPartnerAccount;

  const [logout, result] = useLogoutMutation();
  const [toggleDarkMode, toggleDarkModeResult] = useToggleDarkModeMutation();
  const [switchAccount, switchAccountResult] = useSwitchAccountMutation();
  const { handleEnroll, isLoading: isTrainualULoading } = useTrainualUniversity(closeMenu);
  const { locale } = useLocalesContext();

  useDisplayFlashOnResponse({
    result,
    successFunction: () => {
      resetAnalyticsUser();
      result.data && result.data.redirect_url && routeTo(result.data.redirect_url);
    },
    errorMessage: messageFromError(result.error)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: toggleDarkModeResult,
    errorMessage:
      styleMode === 'dark' ? t('dark_mode_disable_failure') : t('dark_mode_enable_failure'),
  });

  useDisplayFlashOnResponse({
    result: switchAccountResult,
    successFunction: () => {
      resetAnalyticsUser();
      switchAccountResult.data && routeTo(switchAccountResult.data.redirectUrl);
    },
  });

  const referAFriendItem: UserItemProps[] = nonBilling
    ? []
    : [
        {
          iconName: 'handshake',
          onClick: handleClickOnReferAFriendButton,
          title: t('refer_a_friend'),
          id: 'top-nav-menu-refer-a-friend',
          isDisabled: isDisabledNavItem,
        },
      ];

  const switchAccountsItem: UserItemProps[] = hasMultipleAccounts
    ? [
        {
          iconName: 'random',
          onClick: () => switchAccount(),
          title: t('switch_account'),
          id: 'top-nav-menu-switch-account',
        },
      ]
    : [];

  const trainualUniversityLoginItem: UserItemProps[] = showTrainualUniversityItem
    ? [
        {
          iconName: 'graduation-cap',
          onClick: () => handleEnroll(),
          title: t('go_to_trainual_u'),
          isLoading: isTrainualULoading,
          id: 'top-nav-menu-enroll-trainual-university',
        },
      ]
    : [];

  const MenuItems: UserItemProps[] = [
    {
      children: (
        <StyledToggle
          checked={styleMode === 'dark'}
          handleToggle={() => toggleDarkMode()}
          id={`top-nav-menu-${styleMode}-mode-item`}
          name='dark_mode'
        />
      ),
      iconName: 'lightbulb',
      isClickable: false,
      title: t('dark_mode'),
      id: 'top-nav-menu-dark-mode',
      isShow: !deprecateDarkModeEnabled,
    },
    {
      iconName: 'gear',
      onClick: () => {
        routeTo(routes.mySettings({ slug }));
        closeMenu();
      },
      title: t('my_settings'),
      id: 'top-nav-menu-my-settings',
      isDisabled: isDisabledItemOnHoldCondition,
    },
    ...referAFriendItem,
    {
      iconName: 'circle-question',
      onClick: () => {
        window.open(helpPageUrl, '_blank');
        closeMenu();
      },
      title: t('help_center_and_chat'),
      id: 'top-nav-menu-help',
    },
    {
      children: (
        <DownloadAppButtonWrapper>
          <DownloadAppButton
            ariaLabel={t('aria_label_download_apple')}
            name='apple'
            onClick={() => {
              window.open(appleUrl, '_blank');
              closeMenu();
            }}
            weight='brands'
          />
          <DownloadAppButton
            ariaLabel={t('aria_label_download_android')}
            buttonSize='sm'
            name='google-play'
            onClick={() => {
              window.open(androidUrl, '_blank');
              closeMenu();
            }}
            weight='brands'
          />
        </DownloadAppButtonWrapper>
      ),
      isClickable: false,
      iconName: 'cloud-arrow-down',
      title: t('download_apps'),
      id: 'top-nav-menu-download-apps',
    },
    ...switchAccountsItem,
    ...trainualUniversityLoginItem,
    {
      children: <LanguageSelectionPoppable onClick={closeMenu} />,
      iconName: 'earth-americas',
      title: `Language: ${locale.label}`,
      id: 'top-nav-menu-language-select',
      isShow: hasMultiLanguageFeature,
    },
    {
      iconName: 'power-off',
      onClick: () => logout({}),
      title: t('sign_out'),
      id: 'top-nav-menu-sign-out',
    },
  ];

  return (
    <>
      {nonBilling && (
        <ReferAFriendModal
          onCloseRequest={() => setShowReferralModal(false)}
          showModal={showReferralModal}
        />
      )}
      <UserMenu id={id}>
        <UserMenuHeader>
          <ProfileRouterLink breadcrumb={routes.directory({ slug })} id={userId}>
            <UserBlock id='user-profile-block' onClick={closeMenu}>
              <Avatar image={avatarUrl || undefined} name={name} shape='circle' size='sm' />
              <UserBlockInner>
                <UserBlockName className='notranslate'>{name}</UserBlockName>
                <UserBlockTitle>{title}</UserBlockTitle>
              </UserBlockInner>
            </UserBlock>
          </ProfileRouterLink>
          {displayCompletions && (
            <UserCompletionWrapper>
              <UserCompletion>
                <UserCompletionText>{t('completion_rate')}</UserCompletionText>
                <UserCompletionPercent>{completionPercentage}%</UserCompletionPercent>
              </UserCompletion>
              <ProgressBar percent={completionPercentage} thickness='md' />
            </UserCompletionWrapper>
          )}
        </UserMenuHeader>
        <div id='user-menu-list'>
          {MenuItems.map(
            ({
              children,
              iconName,
              title,
              onClick,
              isClickable,
              id,
              isShow,
              isDisabled,
              isLoading,
            }) => (
              <MenuItem
                {...(isDisabled && { className: 'disabled', isDisabled })}
                iconName={iconName}
                id={id}
                isClickable={isClickable}
                isLoading={isLoading}
                isShow={isShow}
                key={id}
                onClick={onClick}
                title={title}
              >
                {children}
              </MenuItem>
            )
          )}
        </div>
      </UserMenu>
    </>
  );
};

export default UserMenuNavigation;
