import AccountTerminology from './AccountTerminology';

export enum CurriculumSectors {
  Company = 'company',
  Policy = 'policy',
  Process = 'process',
}

export const sectorsArray = ['company', 'policy', 'process'] as const;
export type Sector = (typeof sectorsArray)[number];

export function isValidSector(value: string) {
  const matchedSectors = sectorsArray.filter((sector) => sector === value);
  return matchedSectors.length != 0;
}

type SectorToAccountTerminologyArgs = [Sector, AccountTerminology];
export function sectorToAccountTerminology(...args: SectorToAccountTerminologyArgs) {
  const [sector, accountTerminology] = args;
  return sector === CurriculumSectors.Company
    ? accountTerminology[sector].singular
    : accountTerminology[sector].plural;
}

type SectorsToSelectOptionsArgs = [Sector[], AccountTerminology];
export function sectorsToSelectOptions(...args: SectorsToSelectOptionsArgs) {
  const [sectors, accountTerminology] = args;

  return sectors.map((sector) => {
    const label = sectorToAccountTerminology(sector, accountTerminology);

    return {
      label,
      value: sector as string,
      searchableTerm: label,
      hasSubOption: false,
    };
  });
}
