import useCurrentAccount from '../useCurrentAccount';
import usePublicConfigs from '../usePublicConfigs';

export function useGetTalkToSalesLink() {
  const {
    status,
    onLegacyPlan,
    onHoldPlan,
    onFree3SeatsPlan,
    splitFeatures: { billingPagePlansWithTrainualPlusEnabled },
  } = useCurrentAccount();
  const { configs: publicConfigs } = usePublicConfigs();
  const isNonSimplePricingPlan = onLegacyPlan || onHoldPlan || onFree3SeatsPlan;
  const isTrialingOrTrialEnded = ['trialing', 'trial_ended'].includes(status);

  const getTalkToSalesLinkKey = () => {
    if (isTrialingOrTrialEnded) {
      return 'TALK_TO_SALES_TRIAL';
    } else if (isNonSimplePricingPlan) {
      return 'TALK_TO_SALES_FOR_LEGACY_PLAN';
    } else if (billingPagePlansWithTrainualPlusEnabled) {
      return 'TALK_TO_SALES_WITH_TRAINUAL_PLUS';
    } else {
      return 'TALK_TO_SALES_ACTIVE';
    }
  };
  return publicConfigs[getTalkToSalesLinkKey()];
}
