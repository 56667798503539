import styled from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import OutsideClickHandler from '../../../../../design_system/OutsideClickHandler';
import { Input } from '../../../../../design_system/Triage/InputField';
import { mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { fontMd1 } from '../../../../../styled/TypeSystem';

const TRANSITION_DURATION = '0.3s';

export const OutsideClickWrapper = styled(OutsideClickHandler)`
  display: flex;

  @media (max-width: ${mediaBreakpointPxXl}) {
    flex-grow: 1;
  }
`;

export const QuickSearchFieldWrapper = styled.div<{
  isFocused: boolean;
  nonEnglishLocale?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 12;
  width: ${({ isFocused }) => (isFocused ? '100%' : '0')};
  min-width: 3.4rem;
  margin-left: ${({ theme: { constants }, nonEnglishLocale }) =>
    nonEnglishLocale ? constants.spacerLg4 : constants.spacerSm3};
  /* We have to subtract 1px to prevent the input from jumping when we change the border from 1px to 2px in focus */
  padding: ${({ isFocused, theme: { constants } }) =>
    isFocused ? `0 calc(${constants.spacerSm3} - 1px)` : `0 ${constants.spacerSm3}`};
  border: ${({ isFocused, theme: { constants, vars } }) =>
    isFocused
      ? `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`
      : `${constants.borderWidthSm} solid transparent`}; /* This transparent border is necessary for expand animation on tablets to prevent jumping of the input field */
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  transition: ${({ isFocused }) => (isFocused ? `width ${TRANSITION_DURATION}` : 'none')};

  @media (min-width: ${mediaBreakpointPxXl}) {
    width: 36.25rem;
    margin-left: ${({ theme: { constants }, nonEnglishLocale }) =>
      nonEnglishLocale ? constants.spacerLg4 : constants.spacerLg2};
    border: ${({ isFocused, theme: { constants, vars } }) =>
      isFocused
        ? `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`
        : `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  }
`;

export const QuickSearchInput = styled(Input)`
  border: none;
  padding: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  z-index: 1;

  &::placeholder {
    font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  }

  ${fontMd1};
`;

export const QuickSearchIconButton = styled(IconButton)<{ highlight: boolean }>`
  color: ${({ highlight, theme: { vars } }) =>
    highlight ? vars.accentPrimaryDefault : vars.textDefault};
  z-index: 1;
`;

export const QuickSearchClearIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const QuickSearchLoadingIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const QuickSearchIconsWrapper = styled.div<{ isFocused: boolean }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  z-index: 1;

  @media (max-width: ${mediaBreakpointPxXl}) {
    visibility: ${({ isFocused }) => (isFocused ? 'visible' : 'hidden')};
    transition: ${({ isFocused }) => (isFocused ? `visibility ${TRANSITION_DURATION}` : 'none')};
  }
`;
