import { collapseSidebar } from '../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { SlideoutArgs, closeSlideout, openSlideout } from '../redux/domains/slideOut/slideoutSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

type SlideControl = {
  isOpen: boolean;
  open: (args?: SlideoutArgs) => void;
  close: () => void;
  args: SlideoutArgs | undefined;
};

export default (type: string): SlideControl => {
  const dispatch = useAppDispatch();
  const currentSlideout = useAppSelector((state) => state?.slideout?.currentSlideout);

  return {
    isOpen: type === currentSlideout?.type,
    open: (args?) => {
      dispatch(collapseSidebar());
      dispatch(openSlideout({ type, args }));
    },
    close: () => {
      dispatch(closeSlideout());
    },
    args: currentSlideout?.args,
  };
};
