import React, { useState } from 'react';
import styled from 'styled-components';

import { LOCALE_OPTIONS, Locales } from '../../../../../components/application/locales/types';
import { useLocalesContext } from '../../../../../contexts/LocalesContext';
import Icon from '../../../../design_system/display/icons/Icon';
import OutsideClickHandler from '../../../../design_system/OutsideClickHandler';
import Poppable from '../../../../Poppable';
import { fontSm5 } from '../../../../styled/TypeSystem';
import PoppableDropdownMenu from '../../../shared/DropdownWithPoppableMenu/PoppableDropdownMenu';
import { POPPER_MODIFIERS } from './_data';

const LanguageDropdownMenuOption = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 225px;
  min-width: 184px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LanguageDropdownMenuOptionIcon = styled.div`
  align-items: center;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  padding-left: 8rem;
`;

const LanguageDropdownMenuOptionText = styled.p`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

type Props = {
  onClick: () => void;
};

const LanguageSelectionPoppable = ({ onClick }: Props) => {
  const toggleDropdown = () => setLanguageDropdownOpen((prevState) => !prevState);
  const { locale, updateLocale } = useLocalesContext();
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const getLanguageOptionsData = () => {
    return LOCALE_OPTIONS.map((option, index) => (
      <LanguageDropdownMenuOption key={option.value} onClick={onClick}>
        <LanguageDropdownMenuOptionText>{option.label}</LanguageDropdownMenuOptionText>
        {index === LOCALE_OPTIONS.indexOf(locale) && (
          <LanguageDropdownMenuOptionIcon>
            <StyledIcon name='check' size='xs' />
          </LanguageDropdownMenuOptionIcon>
        )}
      </LanguageDropdownMenuOption>
    ));
  };

  const setSelectedOption = (index: number) => {
    updateLocale(LOCALE_OPTIONS.find((option) => option.id === index)?.value as Locales);
    setLanguageDropdownOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setLanguageDropdownOpen(true)}>
      <Poppable
        isOpen={languageDropdownOpen}
        item={
          <PoppableDropdownMenu
            activeOptionIndex={locale.id}
            dropdownOpen={languageDropdownOpen}
            menuId='top-nav-menu-language-select-options'
            menuItemDirection='column'
            menuItemType='default'
            options={getLanguageOptionsData()}
            setSelectedOption={setSelectedOption}
            toggleDropdown={toggleDropdown}
          />
        }
        modifiers={POPPER_MODIFIERS}
        onClick={toggleDropdown}
        placement='left'
        strategy='absolute'
        trigger={<Icon name='chevron-right' />}
      />
    </OutsideClickHandler>
  );
};

export default LanguageSelectionPoppable;
