import React from 'react';

import Icon from '../../../../design_system/display/icons/Icon';
import { MenuIconWrapper, MenuInner, MenuItemWrapper } from './styles';
import { Props } from './types';

const MenuItem = ({
  className = '',
  children,
  onClick,
  id,
  isClickable = true,
  iconName,
  isShow = true,
  isDisabled,
  isLoading,
  title,
}: Props) => {
  return (
    <>
      {isShow ? (
        <MenuItemWrapper
          className={className}
          id={id}
          isClickable={isClickable}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (onClick) {
              onClick();
            }
          }}
        >
          {iconName == 'none' ? (
            <></>
          ) : (
            <MenuInner>
              <MenuIconWrapper>
                <Icon name={iconName} size='xs' />
              </MenuIconWrapper>
              {title}
            </MenuInner>
          )}
          {children && children}
        </MenuItemWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default MenuItem;
