import useCurrentAccount from '../useCurrentAccount';
import { useGetTrialDefaultPlan } from './useGetTrialDefaultPlan';

export function useGetDefaultAccountPlan() {
  const {
    onFree3SeatsPlan,
    onSmallPlan,
    onMediumPlan,
    onGrowthPlan,
    onCustomPlan,
    onOneOfUnlimitedPlans,
    status,
  } = useCurrentAccount();
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const trialDefaultPlan = useGetTrialDefaultPlan();

  function getActiveDefaultPlan() {
    if (onSmallPlan || onFree3SeatsPlan) {
      return 'small';
    }

    if (onMediumPlan) {
      return 'medium';
    }

    if (onGrowthPlan) {
      return 'growth';
    }

    if (onCustomPlan || onOneOfUnlimitedPlans) {
      return 'custom';
    }

    return 'growth';
  }

  return isTrialingOrTrialEnded ? trialDefaultPlan : getActiveDefaultPlan();
}
