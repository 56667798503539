import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { mediaBreakpointPxXl } from '../../../../styled/Breakpoint';
import { fontLg1, fontSm5 } from '../../../../styled/TypeSystem';
import Link from '../../../Link';
import { TruncatedText } from '../../TruncatedText';
import { LearnMoreProps } from '../Header/useHeaderRoute';

const StyledPageHeader = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const StyledPageTitle = styled.h1`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${TruncatedText({})};
  ${fontLg1};
  line-height: normal;

  // Google Translate trims whitespaces
  &:has(span):has(font) span {
    gap: ${({ theme: { constants } }) => constants.spacerSm2};
    min-width: unset;

    @media (max-width: ${mediaBreakpointPxXl}) {
      flex-wrap: wrap;
    }
  }
`;

const StyledPageSubtitle = styled.h2`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
  line-height: normal;
`;

export type PageHeaderProps = {
  title: string;
  subtitle?: string | ReactElement;
  className?: string;
  subtitleAddOnText?: string;
} & LearnMoreProps;

const PageHeader = ({
  title,
  subtitle,
  className = '',
  learnMoreText,
  learnMoreHref,
  subtitleAddOnText,
  learnMoreExternal = false,
}: PageHeaderProps) => {
  return (
    <StyledPageHeader className={className}>
      <StyledPageTitle>{title}</StyledPageTitle>
      {subtitle && (
        <StyledPageSubtitle>
          {subtitle}
          {!!subtitleAddOnText && <strong> {subtitleAddOnText}</strong>}
          {learnMoreText && learnMoreHref && (
            <Link external={learnMoreExternal} href={learnMoreHref} text={learnMoreText} />
          )}
        </StyledPageSubtitle>
      )}
    </StyledPageHeader>
  );
};

export default PageHeader;
