import React, { useState } from 'react';

import { useGetDefaultAccountPlan } from '../../../../../../hooks/billing/useGetDefaultAccountPlan';
import { getBillingPlansByInterval } from '../../../../../../lib/billing/getBillingPlansByInterval';
import { TrainualPlusGroup as GroupImage } from '../../../../../../lib/gcsImages';
import {
  SimplePricingBillingPlanData,
  SimplePricingBillingPlanDataWithTrainualPlus,
} from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { Addon } from '../../../../../../types/Addon';
import { BillingInterval } from '../../../../../../types/BillingInterval';
import { SimplePricingBillingPlanName } from '../../../../../../types/BillingPlanName';
import { CardsWrapper } from '../../../shared/BillingPage/shared/styles';
import PlanCardsHeader from '../shared/PlanCardHeader/PlanCardHeader';
import PlansCard from './PlansCard/PlansCard';
import { BillingPagePlansWithTrainualPlusWrapper, CardGroupImage } from './styles';
import TrainualPlusCard from './TrainualPlusCard/TrainualPlusCard';
import { BillingPagePlansWithTrainualPlusProps } from './types';

const BillingPagePlansWithTrainualPlus = ({
  billingPlansPriceData,
  defaultSelectedInterval = 'year',
}: BillingPagePlansWithTrainualPlusProps) => {
  const defaultPlanName = useGetDefaultAccountPlan();
  const [selectedPlanName, setSelectedPlanName] =
    useState<SimplePricingBillingPlanName>(defaultPlanName);
  const [selectedInterval, setSelectedInterval] =
    useState<BillingInterval>(defaultSelectedInterval);
  const plans = getBillingPlansByInterval(selectedInterval, billingPlansPriceData.billingPlans);
  const mainPlans = plans.filter(
    (plan) => plan.name !== Addon.TrainualPlus
  ) as SimplePricingBillingPlanData[];
  const selectedPlan = mainPlans.find((plan) => plan.name === selectedPlanName) || mainPlans[0];
  const trainualPlusPlan = plans.find(
    (plan) => plan.name === Addon.TrainualPlus
  ) as SimplePricingBillingPlanDataWithTrainualPlus;
  const isTrainualPlusActive = trainualPlusPlan?.active;

  return (
    <BillingPagePlansWithTrainualPlusWrapper className='billing-page-plans-with-trainual-plus'>
      <PlanCardsHeader
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <CardsWrapper isTrainualPlusActive={isTrainualPlusActive}>
        <PlansCard
          isTrainualPlusActive={isTrainualPlusActive}
          plans={mainPlans}
          selectedPlan={selectedPlan}
          setSelectedPlanName={setSelectedPlanName}
          trainualPlusAmount={trainualPlusPlan?.pricePerMonth}
        />
        {!isTrainualPlusActive && (
          <>
            <CardGroupImage src={GroupImage} />
            <TrainualPlusCard addon={trainualPlusPlan} selectedPlan={selectedPlan} />
          </>
        )}
      </CardsWrapper>
    </BillingPagePlansWithTrainualPlusWrapper>
  );
};

export default BillingPagePlansWithTrainualPlus;
