import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useContentModal } from '../../../../../contexts/ContentModalContext';
import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Hoverable from '../../../../design_system/Hoverable';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import InputField from '../../../../design_system/Triage/InputField';
import { CollapsibleContent } from '../../../../design_system/Triage/layout/CollapsibleSurface';
import RadioField from '../../../../design_system/Triage/RadioField';
import PaywallTooltip from '../../../shared/tooltips/PaywallTooltip';
import AdvancedSettingsButton from './AdvancedSettingsButton';
import { FormValues } from './ContentModalFormikWrapper';
import { dueDateOptions, expirationOptions, verifyContentOptions } from './RadioOptions';

const AdvancedSettingsWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  transition: visibility 0.5s linear;
`;

const NumDaysDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: ${({ theme: { constants } }) => constants.spacerLg1};
  width: 4rem;
`;

const RadioFieldWrapper = styled.div<{ isCustomInput?: boolean }>`
  margin-left: ${({ theme: { constants } }) => constants.spacerLg1};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ isCustomInput, theme: { constants } }) =>
    isCustomInput ? 0 : constants.spacerMd1};
`;

const PaywallContainer = styled.div<{ settingsExpanded: boolean }>`
  position: absolute;
  bottom: ${({ settingsExpanded }) => (settingsExpanded ? '27rem' : '13.4rem')};
`;

const CHECKBOX_BOTTOM_MARGIN = '1rem';

const t = initTranslations('curriculums_modal.advanced_settings');

type FormikValues = Pick<
  FormValues,
  | 'checkBoxes'
  | 'dueDate'
  | 'dueDateRadio'
  | 'expiration'
  | 'expirationRadio'
  | 'verifyContentFrequency'
  | 'verifyContentRadio'
>;

type AdvancedSettingsProps = {
  isAdminPlus: boolean;
  isOwner: boolean;
  signatureLoss: boolean;
  ownerAssignment: boolean;
};

const AdvancedSettings = ({
  isAdminPlus,
  isOwner,
  signatureLoss,
  ownerAssignment,
}: AdvancedSettingsProps) => {
  const { handleChange, values, touched, errors } = useFormikContext<FormikValues>();
  const { advancedSettingsOpen, contentTerm } = useContentModal();
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(advancedSettingsOpen);
  const {
    dueDateRadio,
    expirationRadio,
    verifyContentRadio,
    checkBoxes: {
      completionRequired,
      forcedOrder,
      dueDate,
      expiringCompletion,
      completionCertificates,
      locked,
      displayOwner,
      verifyContent,
      disableTranslation,
    },
  } = values;
  const { hasMultiLanguageFeature } = useCurrentAccount();
  const {
    constants: { spacerSm2 },
  } = useTheme();
  const showSignatureWarning = !completionRequired && signatureLoss;
  const [isStatusHovered, setIsStatusHovered] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const dueDateLocked = paywallCtx.includes('due_date');
  const advancedSettingsLocked = paywallCtx.includes('curriculum_advanced_settings');
  const lockedDueDateOnly = dueDateLocked && !advancedSettingsLocked;
  const renderCompletionCertificatesCheckbox = completionRequired;
  const renderDisableTranslationCheckbox = hasMultiLanguageFeature;
  const renderRestrictEditingCheckbox = isAdminPlus || isOwner;
  const renderDisplayOwnerCheckbox = isAdminPlus;
  const disableVerifyContent = advancedSettingsLocked || !ownerAssignment;
  const tooltipText = t(`verify_content_tooltip.${disableVerifyContent ? 'disabled' : 'enabled'}`, {
    curriculum: curriculumTermSingular.toLowerCase(),
  });
  const tooltipHeightOffset = showAdvancedSettings ? -65 : -101;

  return (
    <Hoverable setIsHovered={setIsStatusHovered}>
      <div id='advanced-settings'>
        {advancedSettingsLocked && isStatusHovered && (
          <PaywallContainer settingsExpanded={showAdvancedSettings}>
            <PaywallTooltip
              description={t('paywall.description')}
              modifiers={[{ name: 'offset', options: { offset: [285, tooltipHeightOffset] } }]}
              shouldBeVisible={isStatusHovered}
              title={t('title', { contentType: contentTerm.toLowerCase() })}
            />
          </PaywallContainer>
        )}

        <AdvancedSettingsButton
          advancedSettingsLocked={advancedSettingsLocked}
          setShowAdvancedSettings={setShowAdvancedSettings}
          showAdvancedSettings={showAdvancedSettings}
        />
        <CollapsibleContent isCollapsed={!showAdvancedSettings} isCollapsible>
          <AdvancedSettingsWrapper visible={showAdvancedSettings}>
            <Checkbox
              bottomMargin={showSignatureWarning ? spacerSm2 : CHECKBOX_BOTTOM_MARGIN}
              checked={completionRequired}
              disabled={advancedSettingsLocked}
              id='checkbox_completion_required'
              label={t('assignment_require_completion_default', {
                curriculum: curriculumTermSingular.toLowerCase(),
              })}
              name='checkBoxes.completionRequired'
              onCheck={handleChange}
              subLabel={showSignatureWarning ? t('signature_warning') : undefined}
              tooltipHoverIcon
              tooltipId='require-completion-tooltip'
              tooltipText={t('assignment_require_completion_tooltip', {
                curriculum: curriculumTermSingular.toLowerCase(),
              })}
            />
            <Checkbox
              bottomMargin={CHECKBOX_BOTTOM_MARGIN}
              checked={forcedOrder}
              disabled={advancedSettingsLocked}
              id='checkbox_enforced_order'
              label={t('enforce_order')}
              name='checkBoxes.forcedOrder'
              onCheck={handleChange}
              tooltipHoverIcon
              tooltipId='enforce-order-tooltip'
              tooltipText={t('enforce_order_tooltip')}
            />
            <Checkbox
              bottomMargin={Boolean(dueDate) ? '0' : CHECKBOX_BOTTOM_MARGIN}
              checked={dueDate}
              disabled={advancedSettingsLocked || dueDateLocked}
              id='checkbox_duedate'
              label={t('due_date')}
              name='checkBoxes.dueDate'
              onCheck={handleChange}
              tooltipHoverIcon
              tooltipId='due-date-tooltip'
              tooltipText={
                lockedDueDateOnly
                  ? t('due_date_locked_tooltip', {
                      curriculum: curriculumTermSingular.toLowerCase(),
                    })
                  : t('due_date_tooltip', { curriculum: curriculumTermSingular.toLowerCase() })
              }
            />
            {dueDate && (
              <>
                <RadioFieldWrapper isCustomInput={dueDateRadio === 'custom'}>
                  <RadioField
                    errorText={touched.dueDateRadio && errors.dueDateRadio}
                    name='dueDateRadio'
                    onChange={handleChange}
                    options={dueDateOptions}
                    value={dueDateRadio}
                  />
                </RadioFieldWrapper>
                {dueDateRadio === 'custom' && (
                  <NumDaysDiv>
                    <InputField
                      errorText={touched.dueDate && errors.dueDate}
                      id='dueDate'
                      label={t('radio_options.custom_days')}
                      name='dueDate'
                      onChange={handleChange}
                      type='text'
                      value={values.dueDate}
                    />
                  </NumDaysDiv>
                )}
              </>
            )}
            <Checkbox
              bottomMargin={Boolean(expiringCompletion) ? '0' : CHECKBOX_BOTTOM_MARGIN}
              checked={expiringCompletion}
              disabled={advancedSettingsLocked}
              id={t('expiration')}
              label={t('expiration')}
              name='checkBoxes.expiringCompletion'
              onCheck={handleChange}
              tooltipHoverIcon
              tooltipId='expiration-tooltip'
              tooltipText={t('expiration_tooltip')}
            />
            {expiringCompletion && (
              <>
                <RadioFieldWrapper isCustomInput={expirationRadio === 'custom'}>
                  <RadioField
                    errorText={touched.expirationRadio && errors.expirationRadio}
                    name='expirationRadio'
                    onChange={handleChange}
                    options={expirationOptions}
                    value={values.expirationRadio}
                  />
                </RadioFieldWrapper>
                {expirationRadio === 'custom' && (
                  <NumDaysDiv>
                    <InputField
                      errorText={touched.expiration && errors.expiration}
                      id='expiration'
                      label={t('radio_options.custom_days')}
                      name='expiration'
                      onChange={handleChange}
                      type='text'
                      value={values.expiration}
                    />
                  </NumDaysDiv>
                )}
              </>
            )}
            <>
              <Checkbox
                bottomMargin={Boolean(verifyContent) ? '0' : CHECKBOX_BOTTOM_MARGIN}
                checked={verifyContent}
                disabled={disableVerifyContent}
                id='checkbox_verify_content'
                label={t('verify_content')}
                name='checkBoxes.verifyContent'
                onCheck={handleChange}
                tooltipHoverIcon
                tooltipId='verify-content-tooltip'
                tooltipText={tooltipText}
              />
              {verifyContent && (
                <>
                  <RadioFieldWrapper isCustomInput={verifyContentRadio === 'custom'}>
                    <RadioField
                      disabled={disableVerifyContent}
                      errorText={touched.verifyContentRadio && errors.verifyContentRadio}
                      name='verifyContentRadio'
                      onChange={handleChange}
                      options={verifyContentOptions}
                      value={values.verifyContentRadio}
                    />
                  </RadioFieldWrapper>
                  {verifyContentRadio === 'custom' && (
                    <NumDaysDiv>
                      <InputField
                        disabled={disableVerifyContent}
                        errorText={touched.verifyContentFrequency && errors.verifyContentFrequency}
                        id='verifyContentFrequency'
                        label={t('radio_options.custom_days')}
                        name='verifyContentFrequency'
                        onChange={handleChange}
                        type='text'
                        value={values.verifyContentFrequency}
                      />
                    </NumDaysDiv>
                  )}
                </>
              )}
            </>
            {renderCompletionCertificatesCheckbox && (
              <Checkbox
                bottomMargin={CHECKBOX_BOTTOM_MARGIN}
                checked={completionCertificates}
                disabled={advancedSettingsLocked}
                id={t('completion_certificates')}
                label={t('completion_certificates')}
                name='checkBoxes.completionCertificates'
                onCheck={handleChange}
                tooltipHoverIcon
                tooltipId='completion-certificates-tooltip'
                tooltipText={t('completion_certificates_tooltip', {
                  curriculum: curriculumTermSingular.toLowerCase(),
                })}
              />
            )}
            {renderRestrictEditingCheckbox && (
              <Checkbox
                bottomMargin={CHECKBOX_BOTTOM_MARGIN}
                checked={locked}
                disabled={advancedSettingsLocked}
                id={t('restrict_editing')}
                label={t('restrict_editing')}
                name='checkBoxes.locked'
                onCheck={handleChange}
                tooltipHoverIcon
                tooltipId='restrict-editing-tooltip'
                tooltipText={t('restrict_editing_tooltip', {
                  curriculum: curriculumTermSingular.toLowerCase(),
                })}
              />
            )}
            {renderDisableTranslationCheckbox && (
              <Checkbox
                bottomMargin={CHECKBOX_BOTTOM_MARGIN}
                checked={disableTranslation}
                id={t('disable_translation')}
                label={t('disable_translation')}
                name='checkBoxes.disableTranslation'
                onCheck={handleChange}
                tooltipHoverIcon
                tooltipId='disable-translation-tooltip'
                tooltipText={t('disable_translation_tooltip')}
              />
            )}
            {renderDisplayOwnerCheckbox && (
              <Checkbox
                bottomMargin={CHECKBOX_BOTTOM_MARGIN}
                checked={displayOwner}
                disabled={advancedSettingsLocked}
                id={t('display_owner')}
                label={t('display_owner')}
                name='checkBoxes.displayOwner'
                onCheck={handleChange}
                tooltipHoverIcon
                tooltipId='display-owner-tooltip'
                tooltipText={t('display_owner_tooltip', {
                  curriculum: curriculumTermSingular.toLowerCase(),
                })}
              />
            )}
          </AdvancedSettingsWrapper>
        </CollapsibleContent>
      </div>
    </Hoverable>
  );
};

export default AdvancedSettings;
