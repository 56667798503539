import styled from 'styled-components';

import {
  mediaBreakpointPxLg,
  mediaBreakpointPxMd,
  mediaBreakpointPxSm,
} from '../../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const ActionsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: ${mediaBreakpointPxSm}) {
    align-items: unset;
    flex-direction: column;
    margin-top: unset;
    padding: unset;
  }

  @media (max-width: ${mediaBreakpointPxMd}) {
    box-shadow: none;
  }
`;

export const ElementsCount = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: 0;
  }
`;

export const RightAlignedHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: max-content;
  margin-left: auto;

  @media (max-width: ${mediaBreakpointPxSm}) {
    margin-left: unset;
    max-width: unset;
  }

  @media (max-width: ${mediaBreakpointPxMd}) {
    flex-direction: column;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  width: fit-content;
  margin-top: 0;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: space-between;

  @media (max-width: ${mediaBreakpointPxMd}) {
    width: 100%;
    margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
    margin-left: 0;
  }
`;

export const SearchFieldWrapper = styled.div`
  width: 100%;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 11.75rem;
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 16rem;
  }
`;
