import DOMPurify from 'dompurify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { ThreeDotLoaderGif } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useCreateChatCompletionMutation } from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import { CompletionResponse } from '../../../../redux/services/resourceApis/openAI/types';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../design_system/core/CoreModal';
import Feedback, { FeedbackStatus } from '../Feedback/Feedback';
import { Button, H1, ModalHeaderContainer } from '../shared/modals/shared';
import {
  ButtonGroup,
  GeneratedContentWrapper,
  ModalContentWrapper,
  StyledImage,
  StyledLoadingText,
} from './styles';
import { AiDescriptionModalProps, ButtonIconProps } from './types';

const AiDescriptionModal = ({
  promptMessages,
  localeKey,
  closeModal,
  modalTitle,
  entity,
  composeModalType,
  aiFeatureName,
  saveToEntity,
  loadingText,
  flashNotificationResult,
  flashNotificationMessage,
  currentCompletionResponse,
  setCurrentCompletionResponse,
  composeFeatureName,
  resetCompletion,
}: AiDescriptionModalProps) => {
  const t = initTranslations(localeKey);

  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatus>('un-started');
  const [inputErrorMessage, setInputErrorMessage] = useState<string | null>(null);
  const [trigger, result] = useCreateChatCompletionMutation();
  const { error } = result;

  const RetryButton = feedbackStatus === 'complete' ? Button : DefaultButton;

  const { completion, prevCompletion, hasGeneratedSuccessfully } = currentCompletionResponse;

  const isLoading = useMemo(() => completion?.status === 'pending', [completion]);

  const generatedContentIsPresent = hasGeneratedSuccessfully && !isLoading;

  const { cdpCompositionAccepted, cdpCompositionRegenerated, cdpCompositionGenerated } =
    useAnalyticsUserEvents();

  const generateTrigger = useCallback(() => {
    if (!entity) return;

    trigger({
      feature_name: aiFeatureName,
      model: 'gpt-3.5-turbo',
      messages: promptMessages,
      temperature: 0.6,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, trigger]);

  const primaryButtonIconProps: ButtonIconProps = useMemo(
    () => (completion?.status === 'completed' ? {} : { iconName: 'magic-wand-sparkles' }),
    [completion]
  );

  const buttonText = useMemo(() => {
    if (completion?.status === 'pending') return t('submit_title_loading');

    return completion?.status === 'completed' ? t('submit_title_use') : t('submit_title');
  }, [completion?.status, t]);

  const errorMessage = useMemo(() => {
    // If there are errors from API request or ActionCable response return them
    if (error) {
      return messageFromError(error)?.join(', ');
    } else if (completion?.status === 'failed') {
      return completion.error_message;
    }
  }, [completion, error]);

  const channelProps = useMemo(() => {
    return {
      channel: 'AiCompletionsChannel',
      compose_feature: composeFeatureName,
    };
  }, [composeFeatureName]);

  useEffect(() => {
    // Clear input error message if there is one and the user starts typing
    if (isLoading) {
      setInputErrorMessage(null);
      setFeedbackStatus('un-started');
    } else if (errorMessage) {
      setInputErrorMessage(errorMessage);
    }
  }, [errorMessage, isLoading]);

  useEffect(() => {
    generateTrigger();
  }, [generateTrigger, entity]);

  useDisplayFlashOnResponse({
    result: flashNotificationResult,
    successMessage: flashNotificationMessage,
    successFunction: () => {
      resetCompletion();
      closeModal();
    },
  });

  const received = useCallback(
    (completionResponse: CompletionResponse) => {
      setCurrentCompletionResponse((prevState) => {
        let hasGeneratedSuccessfully = prevState.hasGeneratedSuccessfully;
        if (completionResponse.status === 'completed') {
          if (hasGeneratedSuccessfully) {
            cdpCompositionRegenerated({
              ai_completion_id: completionResponse.id,
              compose_experience: composeModalType,
            });
          } else {
            cdpCompositionGenerated({
              ai_completion_id: completionResponse.id,
              compose_experience: composeModalType,
            });
          }
          hasGeneratedSuccessfully = true;
        }

        return {
          completion: completionResponse,
          prevCompletion: prevState.completion,
          hasGeneratedSuccessfully,
        };
      });
    },
    [
      cdpCompositionGenerated,
      cdpCompositionRegenerated,
      composeModalType,
      setCurrentCompletionResponse,
    ]
  );

  useActionCableChannel<CompletionResponse>(channelProps, received);

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='lg'
      heapModalName='curriculum-description-modal'
      onCloseRequest={closeModal}
    >
      <ModalContentWrapper isLoading={!hasGeneratedSuccessfully || isLoading}>
        <ModalHeaderContainer>
          {generatedContentIsPresent && <H1>{modalTitle}</H1>}
        </ModalHeaderContainer>

        {entity &&
          (generatedContentIsPresent ? (
            <GeneratedContentWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    completion?.completion || prevCompletion?.completion || ''
                  ),
                }}
                id='generated-content'
              />
              {completion && (
                <Feedback
                  aiCompletionId={completion.id}
                  composeModalType={composeModalType}
                  feedbackStatus={feedbackStatus}
                  setFeedbackStatus={setFeedbackStatus}
                />
              )}
            </GeneratedContentWrapper>
          ) : (
            <StyledImage alt='three dot loader' src={ThreeDotLoaderGif} />
          ))}

        <ButtonGroup>
          {generatedContentIsPresent && (
            <RetryButton
              buttonType={feedbackStatus === 'complete' ? 'primary' : 'tertiary'}
              disabled={feedbackStatus === 'started' || !!inputErrorMessage}
              fullWidth
              iconName='rotate-right'
              id='curriculum-description-button-regenerate'
              onClick={generateTrigger}
              text={t('regenerate_content')}
            />
          )}
          {feedbackStatus !== 'complete' &&
            (!hasGeneratedSuccessfully || isLoading ? (
              <StyledLoadingText>{loadingText}</StyledLoadingText>
            ) : (
              <Button
                disabled={isLoading || feedbackStatus === 'started' || !!inputErrorMessage}
                fullWidth
                id='curriculum-description-button-generate'
                onClick={() => {
                  if (completion?.status === 'completed') {
                    saveToEntity();
                    cdpCompositionAccepted({
                      ai_completion_id: completion.id,
                      compose_experience: composeModalType,
                    });
                  } else {
                    generateTrigger();
                  }
                }}
                text={buttonText}
                {...primaryButtonIconProps}
              />
            ))}
        </ButtonGroup>
      </ModalContentWrapper>
    </CoreModal>
  );
};

export default AiDescriptionModal;
