import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useLocalesContext } from '../../../../../contexts/LocalesContext';
import { useSuperShare } from '../../../../../contexts/SuperShareContext';
import { getUserImpliedCurriculumAssignmentPermission } from '../../../../../hooks/useSuperShareState';
import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumAssignmentWithAction } from '../../../../../redux/services/resourceApis/curriculumAssignments/types';
import { AccessLevel } from '../../../../../types/SuperShare';
import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import FlexContainer from '../../../../styled/FlexContainer';
import { fontSm5 } from '../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../shared/DropdownWithPoppableMenu';
import { Options } from '../../../shared/DropdownWithPoppableMenu/PoppableDropdownMenu/PoppableDropdownMenu';
import ContentAccessFlyoutMenuOption, {
  ContentAccessFlyoutMenuOptionData,
} from './ContentAccessFlyoutMenuOption';

const t = initTranslations('supershare.content_access_flyout');

const SelectedOption = styled.div<{ nonEnglishLocale: boolean }>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  width: ${({ nonEnglishLocale, theme: { constants } }) =>
    nonEnglishLocale ? 'auto' : constants.spacerLg3};
  text-align: left;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
  ${TruncatedText({})};
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

// DS Override: Pointer events block Tooltip
const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

const AdminAccess: Array<ContentAccessFlyoutMenuOptionData> = [
  {
    level: 'manage',
    description: t('access_description.full'),
  },
];

const NonAdminAccess: Array<ContentAccessFlyoutMenuOptionData> = [
  {
    level: 'view',
    description: t('access_description.view'),
  },
  {
    level: 'edit',
    description: t('access_description.edit'),
  },
  {
    level: 'manage',
    description: t('access_description.full'),
  },
];

const RemoveAccess: ContentAccessFlyoutMenuOptionData = {
  level: 'remove',
  description: t('access_description.remove'),
};

const adminOptions: ContentAccessFlyoutMenuOptionData[] = [...AdminAccess, RemoveAccess];
const nonAdminOptions: ContentAccessFlyoutMenuOptionData[] = [...NonAdminAccess, RemoveAccess];

const getPermissionIndex = (
  curriculumAssignmentWithAction: CurriculumAssignmentWithAction,
  options: ContentAccessFlyoutMenuOptionData[]
): number => {
  if (curriculumAssignmentWithAction.curriculumAssignableType === 'Group') {
    return options.findIndex((obj) => obj.level === curriculumAssignmentWithAction.permission);
  } else if (curriculumAssignmentWithAction.permission === null) {
    const {
      curriculumAssignable: { permission: userPermission },
    } = curriculumAssignmentWithAction;

    const impliedPermission = getUserImpliedCurriculumAssignmentPermission(userPermission);
    return options.findIndex((obj) => obj.level === impliedPermission);
  }

  return options.findIndex((obj) => obj.level === curriculumAssignmentWithAction.permission);
};

export type ContentAccessFlyoutProps = {
  assignmentWithAction: CurriculumAssignmentWithAction;
};

const ContentAccessFlyout = (props: ContentAccessFlyoutProps) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);

  const { modifyCurriculumAssignment, removeAddedCurriculumAssignment } = useSuperShare();

  const { assignmentWithAction } = props;

  const { curriculumAssignable } = assignmentWithAction;

  const { nonEnglishLocale } = useLocalesContext();

  const isAdmin = useMemo(() => {
    if (curriculumAssignable.type === 'Group') return false;
    return (
      assignmentWithAction.permission === 'owner' ||
      curriculumAssignable.permission === 'billing_admin' ||
      curriculumAssignable.permission === 'admin'
    );
  }, [curriculumAssignable, assignmentWithAction.permission]);

  const contentAccessFlyoutMenuOptions: Array<ContentAccessFlyoutMenuOptionData> = useMemo(() => {
    if (isAdmin) return adminOptions;
    return nonAdminOptions;
  }, [isAdmin]);

  useEffect(() => {
    const initialSelectedOption = isAdmin
      ? 0
      : getPermissionIndex(assignmentWithAction, contentAccessFlyoutMenuOptions);

    setSelectedOptionIndex(initialSelectedOption);
  }, [assignmentWithAction, contentAccessFlyoutMenuOptions, isAdmin]);

  const handleFlyoutMenuItemClick = (level: AccessLevel) => {
    const isDisabled = isAdmin && level === 'manage';

    if (level === 'remove') {
      if (assignmentWithAction.action === 'add') {
        removeAddedCurriculumAssignment(assignmentWithAction);
      } else {
        modifyCurriculumAssignment({ ...assignmentWithAction, action: 'remove' });
      }
    } else if (!isDisabled) {
      modifyCurriculumAssignment({
        ...assignmentWithAction,
        permission: level,
      });
    } else {
      return true;
    }
  };

  const { options, disabledOptionIndexes } = useMemo(() => {
    const disabledOptionIndexes: number[] = [];
    const options: Options = [];

    contentAccessFlyoutMenuOptions.forEach(({ level, description }, index) => {
      if (isAdmin && level === 'manage') {
        disabledOptionIndexes.push(index);
      }

      options.push(
        <ContentAccessFlyoutMenuOption
          assignmentWithAction={props.assignmentWithAction}
          description={description}
          isAdmin={isAdmin}
          isSelected={index === selectedOptionIndex}
          key={level}
          level={level}
        />
      );
    });

    return { options, disabledOptionIndexes };
  }, [contentAccessFlyoutMenuOptions, isAdmin, props, selectedOptionIndex]);

  return (
    <FlexContainer>
      <DropdownWithPoppableMenu
        disabledOptionIndexes={disabledOptionIndexes}
        id='supershare-content-access-flyout'
        lastItemDivider
        menuId='supershare-content-access-flyout-menu'
        menuPlacement='bottom-start'
        options={options}
        selectedOption={
          <SelectedOption nonEnglishLocale={nonEnglishLocale}>
            <span>
              {t(`access_level.${contentAccessFlyoutMenuOptions[selectedOptionIndex].level}`)}
            </span>
            {isAdmin && (
              <>
                <StyledDiv data-for={`content-access-tooltip-${curriculumAssignable.id}`} data-tip>
                  <StyledIcon name='lock' size='2xs' weight='regular' />
                </StyledDiv>
                <Tooltip
                  id={`content-access-tooltip-${curriculumAssignable.id}`}
                  place='top'
                  text={t('access_tooltip.full', { permission: 'Admin' })}
                />
              </>
            )}
          </SelectedOption>
        }
        setSelectedOption={(index: number) => {
          handleFlyoutMenuItemClick(contentAccessFlyoutMenuOptions[index].level);
        }}
        style='supershare'
      />
    </FlexContainer>
  );
};

export default ContentAccessFlyout;
