import {
  addDays,
  differenceInCalendarDays,
  differenceInDays,
  format,
  isPast,
  isToday,
  isTomorrow,
  subDays,
} from 'date-fns';

import initTranslations from './initTranslations';

const t = initTranslations('relative_time');

/**
 * Time Helpers
 * Before creating a new time helper, please review the date-fns package
 * Read More: https://date-fns.org/v3.6.0/docs/
 */

/**
 * Calculates the number of days until a given date from today.
 * Returns 0 if the date is in the past.
 * Returns 1 if the date it tomorrow or within 24 hours.
 *
 * @param {Date} date - The target date.
 * @returns {number} - The number of days until the target date (0 or greater).
 */
export const daysUntil = (date: Date): number => {
  const targetDate = new Date(date);
  if (isTomorrow(targetDate)) {
    return 1;
  }

  const today = new Date();
  const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const targetDateUTC = Date.UTC(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  );

  const daysDifference = differenceInDays(targetDateUTC, todayUTC);

  return Math.max(0, daysDifference);
};

/**
 * Formats a UNIX timestamp into a human-readable date string.
 *
 * @param unixTimestamp - The UNIX timestamp to format.
 * @returns The formatted date string in 'MMM DD, YYYY' format.
 */
export const formatDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000);

  return format(date, 'MMM d, yyyy');
};

/**
 * Formats a UNIX timestamp into a relative time string (e.g., 'just now', 'today', 'yesterday').
 *
 * @param unixTimestamp - The UNIX timestamp to format.
 * @returns The relative time string.
 */
export const relativeTimeFormat = (unixTimestamp: number): string => {
  const timestampMs = unixTimestamp * 1000;
  const now = Date.now();
  const todayMidnight = new Date(now);
  todayMidnight.setHours(0, 0, 0, 0);
  const yesterdayMidnight = new Date(todayMidnight);
  yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

  if (now - timestampMs < 60 * 1000) {
    return t('just_now');
  } else if (timestampMs >= todayMidnight.getTime()) {
    return t('today');
  } else if (timestampMs >= yesterdayMidnight.getTime()) {
    return t('yesterday');
  } else {
    return formatDate(unixTimestamp);
  }
};

/**
 * Represents the possible states of a due date.
 */
type DueDateState = 'due_in' | 'due_today' | 'past_due' | 'no_due_date';

/**
 * Represents the result of the due date status calculation.
 */
type DueDateResult = {
  state: DueDateState;
  number_of_days: number;
};

type InputDate = Date | string | null;
/**
 * Calculates the status of a due date relative to the current date.
 *
 * @param date_string - The due date to evaluate, provided as a string or null.
 * @returns An object containing the state of the due date and the number of days difference.
 *
 * @example
 * const dueDate = '2024-08-18';
 * const result = getDueDateStatus(dueDate);
 * // result = { state: 'due_in', number_of_days: 2 }
 */
export const getDueDateStatus = (date_string: InputDate): DueDateResult => {
  if (!date_string) {
    return { state: 'no_due_date', number_of_days: 0 };
  }

  const dueDate = new Date(date_string);
  const today = new Date();
  const daysDifference = differenceInCalendarDays(new Date(dueDate), today);

  if (isToday(dueDate)) {
    return { state: 'due_today', number_of_days: 0 };
  } else if (isPast(dueDate)) {
    return { state: 'past_due', number_of_days: Math.abs(daysDifference) };
  } else {
    return { state: 'due_in', number_of_days: daysDifference };
  }
};
/**
 * Mimics the Rails `#days_ago` helper.
 * Subtracts the specified number of days from the current date.
 *
 * @param days - Number of days to subtract from today.
 * @returns A Date object representing the date `days` days ago.
 */
export const daysAgo = (days: number): Date => {
  return subDays(new Date(), days);
};

/**
 * Mimics the Rails `#from_now` helper.
 * Adds the specified number of days to the current date.
 *
 * @param days - Number of days to add to today.
 * @returns A Date object representing the date `days` days from now.
 */
export const daysFromNow = (days: number): Date => {
  return addDays(new Date(), days);
};

const ordinalSuffix = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd']; // Array of suffixes for different cases
  const v = n % 100; // Get the last two digits of the number to handle special cases like 11, 12, 13

  // Return the number followed by the correct suffix. The logic:
  // - If the number ends in 11, 12, 13, use 'th' (handled by s[v] || s[0])
  // - Otherwise, use 'st' for 1, 'nd' for 2, 'rd' for 3, and 'th' for other numbers
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Renders a formatted date string from a given timestamp.
 *
 * @param {number} timestamp - A numeric timestamp representing the date and time (milliseconds since January 1, 1970).
 * @param {string} [monthFormat='long'] - Optional flag to format the month name as 'short' or 'long'.
 * @returns {string} A formatted date string in the format: "Month Day<ordinal>, HH:MM AM/PM" (e.g., "September 18th, 12:00 pm").
 */
export const renderFormattedDate = (
  timestamp: number,
  monthFormat: 'short' | 'long' = 'long'
): string => {
  // Convert the input number (timestamp) into a Date object
  const date = new Date(timestamp);

  // Format the date part (e.g., September 18th)
  const options: Intl.DateTimeFormatOptions = { month: monthFormat, day: 'numeric' };
  const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(date);

  // Get the day and apply the ordinal suffix
  const day: number = date.getDate();
  const formattedDay: string = ordinalSuffix(day);

  // Format the time part (e.g., 12:00 pm)
  const formattedTime: string = date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();

  // Replace the numeric day with the formatted day (e.g., 18 -> 18th)
  return `${formattedDate.replace(day.toString(), formattedDay)}, ${formattedTime}`;
};
