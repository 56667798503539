import { DocumentPdfImage, GoogleDocsLogoImage, GoogleLogoImage } from '../../lib/gcsImages';

export default function getDocumentImageSrc(mimeType: string) {
  switch (mimeType) {
    case 'text/html':
      return GoogleLogoImage;
    case 'application/pdf':
      return DocumentPdfImage;
    default:
      return GoogleDocsLogoImage;
  }
}
