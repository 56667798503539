import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useSuperShare } from '../../../../../contexts/SuperShareContext';
import initTranslations from '../../../../../lib/initTranslations';
import { AccessControl, accessControls } from '../../../../../types/SuperShare';
import Icon from '../../../../design_system/display/icons/Icon';
import { deprecated, fontSm5 } from '../../../../styled/TypeSystem';
import { Title } from '../../../shared/DetailedTitle/Title';
import DropdownWithPoppableMenu from '../../../shared/DropdownWithPoppableMenu';

const t = initTranslations('supershare.general_access_settings');

const SettingsContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm3};
    background-color: ${vars.foundationBase1};
    padding: ${constants.spacerMd2};
    border-radius: ${constants.borderRadiusLg};
  `
);

const SectionOverview = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedName = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerSm3};
    color: ${vars.accentPrimaryDefault};
    font-weight: ${constants.fontSemibold};
    ${fontSm5};
  `
);

const SelectedDescription = styled.div(
  ({ theme: { constants, vars } }) => css`
    text-align: left;
    color: ${vars.textDefault};
    padding-left: ${constants.spacerMd3};
    ${fontSm5};
  `
);

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const OptionName = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const OptionDescription = styled.div`
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${deprecated.fontSm3};
`;

const StyledIcon = styled(Icon)`
  width: ${({ theme: { constants } }) => constants.spacerMd2};
  height: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const getIcon = (setting: AccessControl): IconName => {
  switch (setting) {
    case 'discoverable':
      return 'search';
    case 'request':
      return 'hand-point-up';
    case 'private':
      return 'lock';
  }
};

const convertOptionToIndex = (option: AccessControl) =>
  ({
    request: 1,
    discoverable: 0,
    private: 2,
  }[option]);

const convertIndexToOption = (index: number): AccessControl => {
  if (index === 0) return 'discoverable';
  else if (index === 2) return 'private';
  else return 'request';
};

const GeneralAccessSettings = () => {
  const { generalAccessSetting, setGeneralAccessSetting } = useSuperShare();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    convertOptionToIndex(generalAccessSetting)
  );

  useEffect(() => {
    setGeneralAccessSetting(convertIndexToOption(selectedOptionIndex));
  }, [selectedOptionIndex, setGeneralAccessSetting]);

  const options = accessControls.map((setting) => (
    <FlexWrapper id={`${setting}-menu-option`} key={setting}>
      <OptionWrapper>
        <StyledIcon name={getIcon(setting)} />
        <OptionName>{t(`setting.${setting}`)}</OptionName>
      </OptionWrapper>
      <OptionDescription>{t(`description.${setting}`)}</OptionDescription>
    </FlexWrapper>
  ));

  return (
    <SettingsContainer>
      <Title fontSize='sm5' fontWeight='semibold' title={t('title')} />
      <SectionOverview>{t('overview')}</SectionOverview>
      <DropdownWithPoppableMenu
        id='supershare-general-access-settings'
        menuId='supershare-general-access-settings-menu'
        options={options}
        selectedOption={
          <FlexWrapper>
            <SelectedName>
              <StyledIcon name={getIcon(accessControls[selectedOptionIndex])} />
              {t(`setting.${accessControls[selectedOptionIndex]}`)}
            </SelectedName>
            <SelectedDescription>
              {t(`description.${accessControls[selectedOptionIndex]}`)}
            </SelectedDescription>
          </FlexWrapper>
        }
        setSelectedOption={setSelectedOptionIndex}
        style='generalaccess'
        useMenuWidth
      />
    </SettingsContainer>
  );
};

export default GeneralAccessSettings;
