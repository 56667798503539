import { StrictModifier } from 'react-popper';

const mediaQuery = window.matchMedia('(min-width: 500px)');

export const POPPER_MODIFIERS: StrictModifier[] = [
  {
    name: 'offset',
    options: {
      offset: () => (mediaQuery.matches ? [-63, 249] : [110, -20]), // Adjust offset values as needed
    },
  },
];
