import styled from 'styled-components';

export const FeatureVideoWrapper = styled.div`
  position: absolute;
  width: 12.375rem;
  height: 6.375rem;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthLg} solid ${vars.trainualBrandPurpleStrong}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  box-shadow: ${({ theme: { vars } }) => `4px 4px 0 0 ${vars.trainualBrandPurpleStrong}`};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  overflow: hidden;
`;

export const FeatureVideoContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
