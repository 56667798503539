import styled, { css } from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import P from '../../../../../design_system/text/P';
import { fontMd3, fontSm4 } from '../../../../../styled/TypeSystem';

export const TrainualPlusBannerWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${constants.spacerMd3} ${constants.spacerMd3} ${constants.spacerMd3} 5.5rem;
    margin-bottom: ${constants.spacerLg3};
    background-color: ${vars.foundationSurface1};
    border: ${constants.borderWidth2xl} solid ${vars.trainualBrandPurpleStrong};
    border-radius: ${constants.borderRadiusXl};
    box-shadow: 8px 8px 0 0 ${vars.trainualBrandPurpleStrong};
  `};
`;

export const TrainualPlusBannerIcon = styled.img`
  position: absolute;
  width: 6.25rem;
  height: auto;
  top: 50%;
  left: -28px;
  transform: translateY(-50%);
`;

export const TrainualPlusBannerMainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme: { constants } }) => constants.spacerLg1};
  flex-grow: 1;
`;

export const TrainualPlusBannerTitle = styled(P)`
  ${({ theme: { constants, vars } }) => css`
    font-weight: ${constants.fontBold};
    color: ${vars.trainualBrandPurpleStrong};
    margin-bottom: ${constants.spacerSm2};
    ${fontMd3};
  `};
`;

export const TrainualPlusBannerDescription = styled(P)`
  ${fontSm4};
`;

export const TrainualPlusBannerActionButton = styled(DefaultButton)`
  width: fit-content;
`;
