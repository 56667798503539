import styled from 'styled-components';

import IconButton from '../../../../design_system/buttons/IconButton';
import Toggle from '../../../../design_system/input/controls/Toggle';
import { deprecated, fontMd1, fontSm5 } from '../../../../styled/TypeSystem';

export const UserMenu = styled.div`
  width: 277px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  z-index: 10;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const UserMenuHeader = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const UserBlock = styled.div`
  display: inline-flex;
`;

export const UserBlockInner = styled.div`
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const UserBlockTitle = styled.div`
  ${deprecated.fontSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const UserBlockName = styled.h5`
  ${fontMd1};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const UserCompletion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const UserCompletionText = styled.span`
  ${deprecated.fontSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

export const UserCompletionPercent = styled.span`
  ${fontSm5};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const UserCompletionWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const DownloadAppButtonWrapper = styled.div`
  display: flex;
`;

export const DownloadAppButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  background-color: transparent;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`};
  &:first-child {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  }
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
  }
`;

export const StyledToggle = styled(Toggle)`
  label {
    margin: 0;
  }
`;
