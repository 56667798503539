import React, { ReactNode, createContext, useContext } from 'react';

import { ContentModalTypes } from '../components/application/curriculums/modals/CurriculumModal/ContentModal';
import { Option } from '../components/design_system/core/SelectOption/types';

type ContentModalType = {
  modalTitle: string;
  modalSubtitle: string;
  closeRequest: () => void;
  advancedSettingsOpen: boolean;
  isUpdate: boolean;
  userOptions: Option[];
  modalVisible: boolean;
  contentType: ContentModalTypes;
  contentTerm: string;
};

const ContentModalContext = createContext({} as ContentModalType);

type ContentModalProviderProps = {
  children: ReactNode;
} & ContentModalType;

const useContentModal = () => useContext(ContentModalContext);

const ContentModalProvider = ({ children, ...props }: ContentModalProviderProps) => {
  return <ContentModalContext.Provider value={props}>{children}</ContentModalContext.Provider>;
};

export { ContentModalProvider, useContentModal };
