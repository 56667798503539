import { Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu as TiptapBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import {
  activeButtonsFilter,
  useEditorToolbarContext,
} from '../../../../../contexts/EditorToolbarContext';
import { WRAPPER_Z_INDEX } from '../../shared/styles';
import Toolbar from '../../toolbar';

const StyledBubbleMenu = styled(TiptapBubbleMenu)`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: 0;
`;

type ShouldShowProps = {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  oldState?: EditorState;
  from: number;
  to: number;
};

const BubbleMenu = () => {
  const { editor } = useEditorContext();
  const { buttons, context } = useEditorToolbarContext();

  const shouldShow = useCallback(
    ({ editor }: ShouldShowProps) => {
      if (!editor.isEditable) return false;
      // We have to recalculate the active buttons here because the TiptapBubbleMenu component uses the tippy library
      // and does not update the shouldShow function after the first render.
      const activeButtons = activeButtonsFilter(editor, context, buttons);
      return activeButtons.length > 0;
    },
    [buttons, context]
  );

  return (
    <div>
      <StyledBubbleMenu
        editor={editor}
        shouldShow={shouldShow}
        tippyOptions={{ zIndex: WRAPPER_Z_INDEX + 1, duration: 0, appendTo: 'parent' }}
      >
        <Toolbar />
      </StyledBubbleMenu>
    </div>
  );
};

export default BubbleMenu;
