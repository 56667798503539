import React from 'react';

import StatusChanged, { StatusChangedProps } from '../StatusChanged';
import { Activity, CurriculumElementTrackable } from '../types';
import CommonTemplate, { VideoCommonTemplateProps } from './CommonTemplate';

interface VideoActivitiesProps {
  'video.create': React.FC<VideoCommonTemplateProps>;
  'video.status_changed': React.FC<StatusChangedProps>;
  'video.destroy': React.FC<VideoCommonTemplateProps>;
  'video.complete': React.FC<VideoCommonTemplateProps>;
  'video.restore': React.FC<VideoCommonTemplateProps>;
}

const ACTIVITY_KEYS: VideoActivitiesProps = {
  'video.create': CommonTemplate,
  'video.status_changed': StatusChanged,
  'video.destroy': CommonTemplate,
  'video.complete': CommonTemplate,
  'video.restore': CommonTemplate,
};

interface VideoActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: CurriculumElementTrackable;
  parameters: {
    curriculum_id: number;
    curriculum_title: string;
    status_was: string;
    status_now: string;
  };
}

interface VideoCardProps {
  activity: VideoActivity;
}

const VideoActivitiesDetails = ({ activity }: VideoCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof VideoActivitiesProps];
  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default VideoActivitiesDetails;
