import React from 'react';
import styled, { css } from 'styled-components';

import { forceCapitalization } from '../../../../helpers/cssTranslation';
import { fontMd3, fontSm5 } from '../../../../styled/TypeSystem';
import ModalHeaderContainer from '../../../core/Layout/ModalContainers/ModalHeaderContainer';
import Badge from '../../../display/badge';
import Icon from '../../../display/icons/Icon';
import Tag from '../../../display/Tag';
import HeaderContent from './HeaderContent';
import Subtitle from './Subtitle';
import { TaskHead } from './TaskModalTypes';

const TaskTitle = styled.h2(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    font-weight: ${constants.fontExtrabold};
    color: ${vars.textDefault};
    margin-top: 0;
    margin-bottom: 0;
    ${fontMd3};
    ${forceCapitalization};
  `
);

const HeaderDisclaimerContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    background-color: ${vars.foundationBase1};
    padding: ${constants.spacerSm3};
    margin-top: ${constants.spacerSm3};
    border-radius: ${constants.borderWidth2xl};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
  `
);

const TaskDisclaimer = styled.h5(
  ({ theme: { constants, vars } }) => css`
    font-weight: ${constants.fontMedium};
    color: ${vars.textDefault};
    margin-top: 0;
    margin-bottom: 0;
    ${fontSm5};
  `
);

const StyleBadge = styled(Badge)`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const StyleIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledTag = styled(Tag)`
  align-self: flex-end;
  margin-left: auto;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export type TaskModalHeaderProps = TaskHead & {
  forwardRef?: React.RefObject<HTMLDivElement>;
};

const TaskModalHeader = (props: TaskModalHeaderProps) => {
  const {
    title,
    subtitle,
    subtitleLink,
    subtitleWithBoldedText,
    iconName,
    iconWeight = 'regular',
    badgeText,
    badgeType,
    badgeFontWeight,
    headerChildren,
    headerDisclaimer,
    forwardRef,
    withBetaTag,
  } = props;

  return (
    <ModalHeaderContainer id='modal-header-container' ref={forwardRef}>
      <TaskTitle>
        {iconName && iconWeight && <StyleIcon name={iconName} size='md' weight={iconWeight} />}
        {title}
        {badgeText && badgeType && (
          <StyleBadge fontWeight={badgeFontWeight} size='md' text={badgeText} type={badgeType} />
        )}
        {withBetaTag && <StyledTag type='accent' />}
      </TaskTitle>
      {(subtitle || subtitleLink || subtitleWithBoldedText) && (
        <Subtitle
          subtitle={subtitle}
          subtitleLink={subtitleLink}
          subtitleWithBoldedText={subtitleWithBoldedText}
          withBetaTag={withBetaTag}
        />
      )}
      {headerDisclaimer && (
        <HeaderDisclaimerContainer>
          <TaskDisclaimer>{headerDisclaimer}</TaskDisclaimer>
        </HeaderDisclaimerContainer>
      )}
      {headerChildren && <HeaderContent>{headerChildren}</HeaderContent>}
    </ModalHeaderContainer>
  );
};

export default TaskModalHeader;
