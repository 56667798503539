import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Locales } from '../../../../components/application/locales/types';
import { useLocalesContext } from '../../../../contexts/LocalesContext';
import { ReferAFriendProvider } from '../../../../contexts/ReferAFriendContext';
import { TeammateModalProvider } from '../../../../contexts/TeammateContext';
import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFlashNoticeCookie from '../../../../hooks/useFlashNoticeCookie';
import { closeCurriculumModal } from '../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useToggleDarkModeMutation } from '../../../../redux/services/resourceApis/users/usersApi';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import AccountStatusBannerFactory from '../../billing/shared/AccountStatusBanner/AccountStatusBannerFactory';
import ContentModal from '../../curriculums/modals/CurriculumModal/ContentModal';
import ImportFromDocumentModal from '../../curriculums/modals/ImportFromDocumentModal/ImportFromDocumentModal';
import Icons from './Icons/Icons';
import LogoAndSearchBar from './LogoAndSearchBar';

export const navigationHeightDefault = 5;
export const navigationHeightBreakpointXl = 3.75;

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${navigationHeightDefault}rem;
  background-color: ${({ theme }) => theme.vars.foundationSurface1};
  width: 100%;
  border-bottom: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} ${vars.borderSurface1}`}
    solid;

  @media (min-width: ${mediaBreakpointPxXl}) {
    height: ${navigationHeightBreakpointXl}rem;
  }
`;

const TopLevelNavModals = () => {
  const dispatch = useAppDispatch();
  const { cdpDocumentImportDisplayed } = useAnalyticsUserEvents();
  const { showDocumentImportModal, modalLaunchedFrom } = useAppSelector(
    (state) => state.documentUpload
  );
  const { showCurriculumModal } = useAppSelector((state) => state.curriculumModal);

  const {
    splitFeatures: { deprecateDarkModeEnabled },
    hasMultiLanguageFeature,
  } = useCurrentAccount();
  const { styleMode, language } = useCurrentUser();
  useFlashNoticeCookie();

  const [toggleDarkMode] = useToggleDarkModeMutation();
  const { initLocale } = useLocalesContext();

  useEffect(() => {
    initLocale(language as Locales);
  }, [initLocale, language, hasMultiLanguageFeature]);

  useEffect(() => {
    //Enable light mode if dark mode is enabled
    deprecateDarkModeEnabled && styleMode === 'dark' && toggleDarkMode();
  }, [deprecateDarkModeEnabled, styleMode, toggleDarkMode]);

  useEffect(() => {
    if (showDocumentImportModal) {
      cdpDocumentImportDisplayed({ launched_from: modalLaunchedFrom });
    }
  }, [showDocumentImportModal, cdpDocumentImportDisplayed, modalLaunchedFrom]);

  return (
    <>
      {showDocumentImportModal && <ImportFromDocumentModal />}
      {showCurriculumModal && (
        <TeammateModalProvider>
          <ContentModal closeRequest={() => dispatch(closeCurriculumModal())} />
        </TeammateModalProvider>
      )}
    </>
  );
};

const TopLevelNavigation = () => {
  return (
    <>
      <TopLevelNavModals />
      <ReferAFriendProvider>
        <AccountStatusBannerFactory />
        <StyledHeader className='top-navbar' id='top-level-nav'>
          <LogoAndSearchBar />
          <Icons />
        </StyledHeader>
      </ReferAFriendProvider>
    </>
  );
};

export default TopLevelNavigation;
