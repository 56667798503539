import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';
import { deprecated, fontSm5 } from '../../../../../styled/TypeSystem';

export const DocumentPlaceholderContainer = styled.div<{ isError?: boolean }>`
  width: 100%;
  min-height: 5rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars }, isError }) =>
    `${constants.borderWidthSm} solid ${isError ? vars.stateError : vars.borderSurface1}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  overflow: hidden;
`;

export const DocumentImage = styled.img`
  width: 3.5rem;
  max-height: 4rem;
`;

export const DocumentName = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${deprecated.fontMd2}
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const SourceIconWrapper = styled.div`
  width: 20%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
`;

export const DocumentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme: { constants } }) => constants.spacerMd3} 0;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.vars.stateError};
`;

export const ErrorText = styled.span`
  color: ${({ theme: { vars } }) => vars.stateError};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};

  ${fontSm5}
`;

export const SectorSelectionWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 ${constants.spacerSm3}`};
`;

export const SectorsTitle = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5}
`;

export const SectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};

  > button {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;

    > button {
      margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
      margin-bottom: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }
`;
