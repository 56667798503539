import React, { ReactNode } from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { HeapModalName, heapModalNameActionIds } from '../../../../../types/TaskTracking';
import { mediaBreakpointPxSm, mediaBreakpointSm } from '../../../../styled/Breakpoint';
import DefaultButton from '../../../buttons/DefaultButton';
import ModalFooterContainer from '../../../core/Layout/ModalContainers/ModalFooterContainer';
import CancelControlButton from './CancelControlButton';
import FooterContent from './FooterContent';
import { PrimaryButton, TertiaryButton } from './TaskModalTypes';
import TertiaryButtonControl from './TertiaryButtonControl';

const t = initTranslations('task_modal.footer');

const MainButtonWrapper = styled.span`
  width: 100%;
  margin-top: 1rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
    margin-left: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  margin-bottom: 1rem;
  padding-right: 1rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-bottom: 0;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type TaskModalFooterProps = PrimaryButton & {
  footerContent?: ReactNode;
  forwardRef?: React.RefObject<HTMLDivElement>;
  heapModalName: HeapModalName;
  processing: boolean;
  secondaryButtonText?: string;
  tertiaryButton?: TertiaryButton;
  onCloseRequest: () => void;
};

const TaskModalFooter = (props: TaskModalFooterProps) => {
  const {
    processing,
    primaryButtonTask,
    tertiaryButton,
    heapModalName,
    secondaryButtonText = t('cancel'),
    onCloseRequest,
    primaryButtonText,
    isDisabled = false,
    footerContent,
    forwardRef,
  } = props;
  const heapActionIds = heapModalNameActionIds(heapModalName);

  const { width } = useWindowResize();

  return (
    <FooterWrapper id='modal-footer-wrapper' ref={forwardRef}>
      {footerContent && <FooterContent>{footerContent}</FooterContent>}
      <ModalFooterContainer>
        {!!tertiaryButton && tertiaryButton.text && (
          <ButtonWrapper>
            <TertiaryButtonControl
              {...tertiaryButton}
              heapModalActionId={heapActionIds.alt}
              processing={processing}
            />
          </ButtonWrapper>
        )}
        <CancelControlButton
          heapModalActionId={heapActionIds.cancel}
          onCloseRequest={onCloseRequest}
          processing={processing}
          secondaryButtonText={secondaryButtonText}
        />
        {primaryButtonTask && primaryButtonText && (
          <MainButtonWrapper>
            <DefaultButton
              buttonType='primary'
              className={heapActionIds.submit}
              disabled={isDisabled}
              fullWidth={width < mediaBreakpointSm}
              id={heapActionIds.submit}
              loading={processing}
              onClick={() => !processing && primaryButtonTask()}
              size='md'
              text={primaryButtonText}
            />
          </MainButtonWrapper>
        )}
      </ModalFooterContainer>
    </FooterWrapper>
  );
};

export default TaskModalFooter;
