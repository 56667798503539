import { Addon } from '../../types/Addon';
import TrainingPathPreviewSrc from '../../videos/training-paths-preview.mp4';
import TrainualPlusPreviewSrc from '../../videos/trainual-plus-preview.mp4';
import { gcsReactVideosBaseUrl } from '../gcsVideos';

export function getAddonVideoPreview(addon: Addon) {
  switch (addon) {
    case Addon.DelegationPlanner:
      return `${gcsReactVideosBaseUrl}/delegation_planner/preview.mp4`;
    case Addon.TrainingPath:
      return TrainingPathPreviewSrc;
    case Addon.TrainualPlus:
      return TrainualPlusPreviewSrc;
  }
}
