import styled from 'styled-components';

import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';
import { fontLg2, fontMd1, fontSm4 } from '../../../../../../styled/TypeSystem';
import { getTrainualPlusBgImg } from '../../../AddonFullScreenOverlays/shared/styles';
import { FeatureVideoWrapper } from '../../shared/FeatureVideo/styles';

export const TrainualPlusCardWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  position: relative;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXl} solid ${vars.trainualBrandPurpleSurface}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
`;

export const TrainualPlusCardTopHeader = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

export const TrainualPlusCardTitle = styled.div<{ planBrandColor: string }>`
  position: relative;
  color: ${({ planBrandColor }) => planBrandColor};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 ${constants.spacerMd3} 0`};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  z-index: 1;
  ${fontLg2};

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -20px;
    width: 11.938rem;
    height: 4.125rem;
    z-index: -1;
    background: ${({ theme: { vars } }) =>
      getTrainualPlusBgImg(vars.trainualBrandPurpleSurface, 191, 66)};
  }
`;

export const TrainualPlusCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg2}`};
  }
`;

export const TrainualPlusCardText = styled.p`
  ${fontMd1};
`;

export const TrainualPlusCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  height: ${({ theme: { constants } }) => constants.heightLg};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TrainualPlusCardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const TrainualPlusCardPrice = styled.span`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontLg2};
  &::before {
    content: '+ ';
  }
`;

export const TrainualPlusCardInterval = styled.span`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  ${fontSm4};
`;

export const TrainualPlusCardActionButton = styled(DefaultButton)`
  margin-left: auto;
`;

export const TrainualPlusVideoWrapper = styled(FeatureVideoWrapper)`
  position: absolute;
  right: -20px;
  top: 14px;
  border: none;
  z-index: 2;

  @media (max-width: ${mediaBreakpointPxLg}) {
    display: none;
  }
`;
