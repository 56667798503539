import React, { useState } from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../../hooks/usePublicConfigs';
import { useRedirectToAddonOverlay } from '../../../../../../../hooks/useRedirectToAddonOverlay';
import { getAddonVideoPreview } from '../../../../../../../lib/billing/getAddonVideoPreview';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import {
  SimplePricingBillingPlanData,
  SimplePricingBillingPlanDataWithTrainualPlus,
} from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { Addon } from '../../../../../../../types/Addon';
import FeatureVideo from '../../shared/FeatureVideo/FeatureVideo';
import {
  TrainualPlusCardActionButton,
  TrainualPlusCardContentWrapper,
  TrainualPlusCardFooter,
  TrainualPlusCardInterval,
  TrainualPlusCardPrice,
  TrainualPlusCardPriceWrapper,
  TrainualPlusCardText,
  TrainualPlusCardTitle,
  TrainualPlusCardTopHeader,
  TrainualPlusCardWrapper,
  TrainualPlusVideoWrapper,
} from './styles';

type TrainualPlusCardProps = {
  addon: SimplePricingBillingPlanDataWithTrainualPlus;
  selectedPlan: SimplePricingBillingPlanData;
};

const t = initTranslations(
  'simple_pricing.billing_page.plans_with_trainual_plus.trainual_plus_card'
);

const USER_SLOTS_LIMIT = 101;

const TrainualPlusCard = ({ addon, selectedPlan }: TrainualPlusCardProps) => {
  const { name, pricePerMonth, ctaKey } = addon;
  const { color: planBrandColor, backgroundColor } = usePlanBrandColors({ name });
  const { configs: publicConfigs } = usePublicConfigs();
  const { totalUserSlotsCount, status } = useCurrentAccount();
  const [, setIsOpenedLightboxVideoPlayer] = useState(false);
  const { configs } = usePublicConfigs();
  const videoLink = configs['TRAINUAL_PLUS_VIDEO_URL'];
  const videoPreviewSrc = getAddonVideoPreview(Addon.TrainualPlus);
  const showTrainualPlusVideo = videoLink && videoPreviewSrc;
  const isActiveAndUserLimitExceeded =
    status === 'active' && totalUserSlotsCount >= USER_SLOTS_LIMIT;
  const hideTotalAmount = selectedPlan.name === 'custom' || isActiveAndUserLimitExceeded;
  const navigateToAddonOverlay = useRedirectToAddonOverlay();
  const href = publicConfigs['INTEREST_IN_TRAINUAL_PLUS_EMAIL_HREF'];
  const handleTrainualPlusCardAction = () => {
    navigateToAddonOverlay({ name: Addon.TrainualPlus });
  };

  return (
    <TrainualPlusCardWrapper backgroundColor={backgroundColor} id='trainual-plus-card'>
      {showTrainualPlusVideo && (
        <TrainualPlusVideoWrapper>
          <FeatureVideo
            closePlayerCallback={() => setIsOpenedLightboxVideoPlayer(false)}
            openPlayerCallback={() => setIsOpenedLightboxVideoPlayer(true)}
            overviewVideoLinks={videoLink}
            videoPreviewSrc={videoPreviewSrc}
          />
        </TrainualPlusVideoWrapper>
      )}
      <TrainualPlusCardContentWrapper>
        <TrainualPlusCardTopHeader> {t('top_header')} </TrainualPlusCardTopHeader>
        <TrainualPlusCardTitle className='notranslate' planBrandColor={planBrandColor}>
          {t('title')}
        </TrainualPlusCardTitle>
        <TrainualPlusCardText dangerouslySetInnerHTML={{ __html: t('description') }} />
        <TrainualPlusCardFooter>
          {!hideTotalAmount && (
            <TrainualPlusCardPriceWrapper>
              <TrainualPlusCardPrice>{getFormattedPrice(pricePerMonth)}</TrainualPlusCardPrice>
              <TrainualPlusCardInterval>{t('per_month')}</TrainualPlusCardInterval>
            </TrainualPlusCardPriceWrapper>
          )}
          <TrainualPlusCardActionButton
            buttonType='brand'
            id='billing-page-trainual-plus-card-add-to-plan-button'
            size='md'
            text={t(`cta.${ctaKey}`)}
            {...(ctaKey === 'talk_to_us'
              ? { href, behavesAs: 'a', target: '_blank' }
              : { onClick: handleTrainualPlusCardAction })}
          />
        </TrainualPlusCardFooter>
      </TrainualPlusCardContentWrapper>
    </TrainualPlusCardWrapper>
  );
};

export default TrainualPlusCard;
