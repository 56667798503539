import styled from 'styled-components';

import { BillingPlanBrandColor } from '../../../../../../../types/SimplePricing';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import P from '../../../../../../design_system/text/P';
import { deprecated, fontSm5 } from '../../../../../../styled/TypeSystem';
import { BillingIntervalText, TitleUnderline } from '../../../../shared/styles';

export const PlanDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
`;

export const PlanCardTitle = styled(P)<{
  planBrandColor: BillingPlanBrandColor;
}>`
  position: relative;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontHeavy};
  color: ${({ planBrandColor }) => planBrandColor.color};
  &:after {
    ${TitleUnderline};
  }
  ${deprecated.fontMd2};
`;

export const PlanEmployees = styled.span`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  ${fontSm5};
`;

// DS Override - to match design
export const UpgradePlanButton = styled(DefaultButton)`
  margin-top: auto;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const ActivePlanIndicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};

  &:before {
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface2};
  }
  ${fontSm5};
`;

export const AnnuallyBilledText = styled(BillingIntervalText)`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;
