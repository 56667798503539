import { Formik, FormikErrors } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useContentModal } from '../../../../../contexts/ContentModalContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { stringifyNumber } from '../../../../../lib/convertValues';
import initTranslations from '../../../../../lib/initTranslations';
import { isSector } from '../../../../../lib/isSector';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  useCreateCurriculumMutation,
  useUpdateCurriculumMutation,
  useUpdateCurriculumWithoutInvalidatingTagsMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumShowResponse } from '../../../../../types';
import { Sector } from '../../../../../types/Sector';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { ContentModalProps } from './ContentModal';
import ContentModalForm from './ContentModalForm';
import { ProcessChipType } from './SectorChipSelect';

type AdvancedSettings = {
  completionRequired: boolean;
  disableTranslation: boolean;
  dueDate: boolean;
  forcedOrder: boolean;
  expiringCompletion: boolean;
  locked: boolean;
  displayOwner: boolean;
  completionCertificates: boolean;
  verifyContent: boolean;
};

const DEFAULT_ADVANCED_SETTINGS: AdvancedSettings = {
  completionRequired: true,
  disableTranslation: false,
  dueDate: false,
  forcedOrder: false,
  expiringCompletion: false,
  locked: false,
  displayOwner: true,
  completionCertificates: false,
  verifyContent: false,
};

export type FormValues = {
  title: string;
  ownerAssignment: string | null;
  description: string;
  dueDateRadio: string;
  dueDate: string;
  expirationRadio: string;
  expiration: string;
  checkBoxes: AdvancedSettings;
  sectorChip: ProcessChipType;
  verifyContentFrequency: string;
  verifyContentRadio: string;
};

type FormData = {
  title: string;
  owner_assignment: string | null;
  description: string | null;
  due_date: string | null;
  expiring_completion_in_days_after_completed: string | null;
  completion_required: string | undefined;
  forced_order: string;
  expiring_completion: string;
  locked: string;
  display_owner: string;
  completion_certificates: boolean;
  disable_translation: boolean;
  sector?: Sector | null;
  verify_content: string | null;
  verify_content_frequency_in_days: string | null;
};

const t = initTranslations('curriculums_modal');

type ContentModalFormikWrapperProps = {
  curriculum: CurriculumShowResponse | undefined;
  errorMessage: string | undefined;
} & Pick<ContentModalProps, 'sector' | 'afterCreateCurriculumAction'>;

const ContentModalFormikWrapper = ({
  curriculum,
  errorMessage,
  sector,
  afterCreateCurriculumAction,
}: ContentModalFormikWrapperProps) => {
  const { isUpdate, closeRequest } = useContentModal();
  const ability = useCurrentUserAbilities();
  const { id: currentUserId } = useCurrentUser();
  const { slug, paywalledFeatures } = useCurrentAccount();
  const {
    id,
    title,
    description,
    sector: curriculumSector,
    due_date: dueDateNumber,
    expiring_completion_in_days_after_completed: expirationNumber,
    verify_content_frequency_in_days: verifyContentNumber,
    advanced_settings: advancedSettings,
    owner_id: ownerId,
    locked,
  } = curriculum || {};

  const dueDate = useMemo(() => stringifyNumber(dueDateNumber), [dueDateNumber]);
  const expiration = useMemo(() => stringifyNumber(expirationNumber), [expirationNumber]);
  const verifyContentFrequency = useMemo(
    () => stringifyNumber(verifyContentNumber),
    [verifyContentNumber]
  );
  const dueDateRadio = useMemo(
    () => (dueDate !== '7' && dueDate !== '14' ? 'custom' : dueDate),
    [dueDate]
  );
  const expirationRadio = useMemo(() => {
    return expiration !== '30' && expiration !== '90' && expiration !== '365'
      ? 'custom'
      : expiration;
  }, [expiration]);
  const verifyContentRadio = useMemo(() => {
    return verifyContentFrequency !== '30' && verifyContentFrequency !== '365'
      ? 'custom'
      : verifyContentFrequency;
  }, [verifyContentFrequency]);
  const advancedSettingsLocked = paywalledFeatures.includes('curriculum_advanced_settings');

  const lockedSettings = useMemo(
    () =>
      advancedSettingsLocked
        ? {
            completionRequired: false,
            displayOwner: false,
            availableInLibrary: false,
            verifyContent: false,
          }
        : {},
    [advancedSettingsLocked]
  );

  const initialSector = useMemo(
    () => curriculumSector || sector || null,
    [curriculumSector, sector]
  );

  const [selectedChip, setSelectedChip] = useState<ProcessChipType>(initialSector);
  const [updateCurriculum, updateResult] = useUpdateCurriculumMutation();
  const [updateCurriculumWithoutInvalidatingTags, updateWithoutTagsResult] =
    useUpdateCurriculumWithoutInvalidatingTagsMutation();
  const [createCurriculum, createResult] = useCreateCurriculumMutation();

  useDisplayFlashOnResponse({
    result: isUpdate ? updateResult : createResult,
    errorMessage: messageFromError(isUpdate ? updateResult.error : createResult.error)?.join(', '),
  });

  const canEditNew = useMemo(() => {
    return (
      createResult.isSuccess && ability.can('update', `EditCurriculum-${createResult.data.id}`)
    );
  }, [createResult, ability]);

  useEffect(() => {
    if (updateResult.isSuccess) {
      closeRequest();
    }

    if (updateWithoutTagsResult.isSuccess) {
      window.location.reload();
      closeRequest();
    }

    if (canEditNew && createResult.data) {
      closeRequest();
      if (afterCreateCurriculumAction) {
        afterCreateCurriculumAction(createResult.data.id, createResult.data.title);
        return;
      }

      routeTo(routes.curriculumEdit({ slug, id: createResult.data.id }));
    }
  }, [
    createResult,
    id,
    slug,
    canEditNew,
    closeRequest,
    updateResult.isSuccess,
    updateWithoutTagsResult.isSuccess,
    afterCreateCurriculumAction,
  ]);

  const validateForm = (values: FormValues) => {
    const {
      title,
      checkBoxes,
      dueDateRadio,
      dueDate,
      expirationRadio,
      expiration,
      verifyContentFrequency,
      verifyContentRadio,
    } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!title) errors.title = t('inputs.errors.required_field');
    if (title.length > 100) errors.title = t('inputs.errors.title_too_long');
    if (checkBoxes.dueDate) {
      if (dueDateRadio == 'custom' && !dueDate) {
        errors.dueDate = t('inputs.errors.required_field');
      } else if (!dueDateRadio) {
        errors.dueDateRadio = t('inputs.errors.required_field');
      }
    }
    if (checkBoxes.verifyContent) {
      if (verifyContentRadio == 'custom' && !verifyContentFrequency) {
        errors.verifyContentFrequency = t('inputs.errors.required_field');
      } else if (!verifyContentRadio) {
        errors.verifyContentRadio = t('inputs.errors.required_field');
      } else if (Number(verifyContentFrequency) > 365) {
        errors.verifyContentFrequency = t('inputs.errors.maximum_verification_period');
      }
    }
    if (checkBoxes.expiringCompletion) {
      if (expirationRadio == 'custom' && !expiration) {
        errors.expiration = t('inputs.errors.required_field');
      } else if (!expirationRadio) {
        errors.expirationRadio = t('inputs.errors.required_field');
      }
    }
    return errors;
  };

  const submitForm = useCallback(
    (values: FormValues) => {
      let calcDueDate: string | null = values.dueDateRadio;
      if (!values.checkBoxes.dueDate) {
        calcDueDate = null;
      } else if (calcDueDate === 'custom') {
        calcDueDate = values.dueDate;
      }
      let calcExpiration: string | null = values.expirationRadio;
      if (!values.checkBoxes.expiringCompletion) {
        calcExpiration = null;
      } else if (calcExpiration === 'custom') {
        calcExpiration = values.expiration;
      }
      let calcVerifyContentFrequency: string | null = values.verifyContentRadio;
      if (!values.checkBoxes.verifyContent) {
        calcVerifyContentFrequency = null;
      } else if (calcVerifyContentFrequency === 'custom') {
        calcVerifyContentFrequency = values.verifyContentFrequency;
      }
      const formattedData: FormData = {
        title: values.title,
        owner_assignment: values.ownerAssignment,
        description: values.description,
        due_date: calcDueDate,
        expiring_completion_in_days_after_completed: calcExpiration,
        completion_required: values.checkBoxes.completionRequired ? '1' : '0',
        forced_order: values.checkBoxes.forcedOrder ? '1' : '0',
        expiring_completion: values.checkBoxes.expiringCompletion ? '1' : '0',
        locked: values.checkBoxes.locked ? '1' : '0',
        display_owner: values.checkBoxes.displayOwner ? '1' : '0',
        completion_certificates: values.checkBoxes.completionCertificates,
        disable_translation: values.checkBoxes.disableTranslation,
        sector: values.sectorChip,
        verify_content: values.checkBoxes.verifyContent ? '1' : '0',
        verify_content_frequency_in_days: calcVerifyContentFrequency,
        ...lockedSettings,
      };

      const skipTranslation =
        values.checkBoxes.disableTranslation != advancedSettings?.disable_translation;

      if (id) {
        skipTranslation
          ? updateCurriculumWithoutInvalidatingTags({ id, ...formattedData })
          : updateCurriculum({ id, ...formattedData });
      } else {
        createCurriculum(formattedData);
      }
    },
    [
      lockedSettings,
      id,
      updateCurriculum,
      createCurriculum,
      updateCurriculumWithoutInvalidatingTags,
      advancedSettings?.disable_translation,
    ]
  );

  const initialValues = {
    title: title || '',
    ownerAssignment: !isUpdate ? stringifyNumber(currentUserId) : stringifyNumber(ownerId),
    description: description || '',
    dueDateRadio: dueDate ? dueDateRadio : '7',
    dueDate: dueDate || '',
    expirationRadio: expiration ? expirationRadio : '30',
    expiration: expiration || '',
    verifyContentFrequency: verifyContentFrequency || '',
    verifyContentRadio: verifyContentFrequency ? verifyContentRadio : '30',
    checkBoxes: !advancedSettingsLocked
      ? advancedSettings
        ? {
            completionRequired: advancedSettings.completion_required,
            disableTranslation: Boolean(advancedSettings.disable_translation),
            displayOwner: advancedSettings.display_owner,
            expiringCompletion: advancedSettings.expiring_completion,
            forcedOrder: advancedSettings.forced_order,
            locked: locked || false,
            completionCertificates: advancedSettings.completion_certificates,
            verifyContent: Boolean(advancedSettings.verify_content),
            dueDate: Boolean(dueDate),
          }
        : DEFAULT_ADVANCED_SETTINGS
      : {
          ...DEFAULT_ADVANCED_SETTINGS,
          ...lockedSettings,
        },
    sectorChip: selectedChip && isSector(selectedChip) ? selectedChip : 'process',
  };
  const validate = (values: FormValues) => validateForm(values);
  const onSubmit = (values: FormValues) => submitForm(values);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <ContentModalForm
        createOrUpdateResultLoading={createResult.isLoading || updateResult.isLoading}
        curriculum={curriculum}
        errorMessage={errorMessage}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
      />
    </Formik>
  );
};

export default ContentModalFormikWrapper;
