import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { ReactivateAccountModalHeader as HeaderImage } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';

const t = initTranslations(
  'per_user_pricing.account_status_banners.pending_cancellation.reactivated_account_celebration_modal'
);

const ReactivatedAccountCelebrationModal = () => {
  // Modal will be closed after refetchCurrentAccount, because of account status change
  const { refetch: refetchCurrentAccount } = useCurrentAccount();
  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    subtitle: t('subtitle'),
    onCloseRequest: refetchCurrentAccount,
    headerImage: HeaderImage,
    heapModalName: 'reactivated-account-celebration-modal',
    processing: false,
    secondaryButtonText: t('button'),
    disabledPadding: {
      vertical: { top: true },
    },
  };

  return <TaskModal {...taskModalArgs} desktopSize='md' />;
};

export default ReactivatedAccountCelebrationModal;
