import styled, { css } from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import { deprecated } from '../../../styled/TypeSystem';

export const FullScreenOverlayWrapper = styled.div<{ isAbsolutePosition: boolean }>`
  display: flex;
  position: ${({ isAbsolutePosition }) => (isAbsolutePosition ? 'absolute' : 'fixed')};
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

// DS Override: Need to match design color

export const MAIN_CONTENT_MAX_WIDTH = '30.625rem';

export const mainSectionStyles = css`
  display: flex;
  flex-basis: calc(100% - 20rem);

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: calc(100% - 26.5rem);
  }
`;

export const FullScreenOverlayCloseIconButton = styled(IconButton)<{
  fixed: boolean;
  isSlideoutOpen: boolean;
}>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  margin-right: ${({ isSlideoutOpen }) => (isSlideoutOpen ? '20rem' : 0)};
  top: ${({ theme: { constants } }) => constants.spacerSm3};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textDefault};

  svg {
    pointer-events: none;
  }
`;

// Shared styles for overlay content
export const MainSectionWrapper = styled.div`
  justify-content: center;
  padding: ${({ theme: { constants } }) =>
    `4.625rem ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2}`};

  ${mainSectionStyles};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) =>
      `4.625rem ${constants.spacerLg1} ${constants.spacerMd2} ${constants.spacerMd3}`};
  }
`;

export const MainSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAIN_CONTENT_MAX_WIDTH};
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const OverlayContentHeaderTitle = styled.h4<{ withBottomMargin?: boolean }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-top: 0;
  margin-bottom: ${({ withBottomMargin, theme: { constants } }) =>
    withBottomMargin ? constants.spacerLg1 : 0};
  ${deprecated.fontMd4};
`;

export const OverlayContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const rightSectionStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 20rem;
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) =>
    `4.125rem ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2} `};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: 26.5rem;
    padding: ${({ theme: { constants } }) =>
      `4.125rem  ${constants.spacerMd3} ${constants.spacerMd2} ${constants.spacerLg1}`};
  }
`;
