import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../hooks/usePublicConfigs';
import trainualPlusWidgetIcon from '../../../../../../images/addons/trainual_plus_icon.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  TrainualPlusBannerActionButton,
  TrainualPlusBannerDescription,
  TrainualPlusBannerIcon,
  TrainualPlusBannerMainContentWrapper,
  TrainualPlusBannerTitle,
  TrainualPlusBannerWrapper,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.trainual_plus_banner');

const BillingPageTrainualPlusBanner = () => {
  const { configs: publicConfigs } = usePublicConfigs();
  const {
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();

  if (simplePricingEnabled) return <></>;

  return (
    <TrainualPlusBannerWrapper className='trainual-plus-banner-for-legacy-plans'>
      <TrainualPlusBannerIcon alt={t('image_alt')} src={trainualPlusWidgetIcon} />

      <TrainualPlusBannerMainContentWrapper>
        <TrainualPlusBannerTitle text={t('title')} />
        <TrainualPlusBannerDescription text={t('description')} />
      </TrainualPlusBannerMainContentWrapper>

      <TrainualPlusBannerActionButton
        behavesAs='a'
        buttonType='brand'
        fullWidth
        href={publicConfigs['INTEREST_IN_TRAINUAL_PLUS_EMAIL_HREF']}
        id='trainual-plus-talk-to-us-button'
        target='_blank'
        text={t('talk_to_us')}
      />
    </TrainualPlusBannerWrapper>
  );
};

export default BillingPageTrainualPlusBanner;
