import React from 'react';
import styled, { css } from 'styled-components';

import translationClassname from '../../../../lib/translationClassname';
import { InputFontSize, getFontStyles } from '../../input/fields/TextareaWithCharacterCount';

export const TEXT_DISPLAY_Z_INDEX = 10;

const ClickEditable = styled.div`
  cursor: text;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerMd1}`};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  word-break: break-word;
  white-space: normal;
  z-index: ${TEXT_DISPLAY_Z_INDEX};

  &:hover {
    border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;

const StyledTextDisplay = styled.div<{
  isPlaceholder: boolean;
  inputFontSize: InputFontSize;
}>(
  ({ theme: { vars, constants }, isPlaceholder, inputFontSize }) => css`
    color: ${isPlaceholder ? vars.textPlaceholder : vars.textDefault};
    margin-top: ${constants.spacerMd1};
    ${getFontStyles(inputFontSize)}
  `
);

interface TextDisplay {
  attributeName: string;
  inputFontSize: InputFontSize;
  isPlaceholder: boolean;
  setEditState: () => void;
  text: string | undefined;
  disableTranslation?: boolean;
}

const TextDisplay = ({
  inputFontSize,
  isPlaceholder,
  setEditState,
  text,
  disableTranslation,
}: TextDisplay) => {
  return (
    <ClickEditable className='clickable-edit' onClick={setEditState}>
      <StyledTextDisplay
        className={translationClassname(disableTranslation)}
        inputFontSize={inputFontSize}
        isPlaceholder={isPlaceholder}
      >
        {text}
      </StyledTextDisplay>
    </ClickEditable>
  );
};

export default TextDisplay;
