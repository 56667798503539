import React from 'react';

import { useGetTalkToSalesLink } from '../../../../../../../hooks/billing/useGetTalkToSalesLink';
import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../../hooks/usePublicConfigs';
import { useRedirectToBillingOverlay } from '../../../../../../../hooks/useRedirectToBillingOverlay';
import { TrainualPlusPowerup } from '../../../../../../../lib/gcsImages';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import PlansCardTabs, { PlansCardTabsProps } from '.././PlansCardTabs/PlansCardTabs';
import {
  PlansCardBillingInterval,
  PlansCardContentWrapper,
  PlansCardFooter,
  PlansCardPlanText,
  PlansCardPrice,
  PlansCardPriceWrapper,
  PlansCardText,
  PlansCardTitle,
  PlansCardTitleWrapper,
  PlansCardWrapper,
  StyledActivePlanIndicator,
  TrainualPlusBadge,
  TrainualPlusBadgeWraper,
  TrainualPlusTitle,
  UpgradeButton,
} from './styles';

const t = initTranslations('simple_pricing.billing_page.plans_with_trainual_plus');

type PlansCardProps = PlansCardTabsProps & {
  trainualPlusAmount?: number;
  isTrainualPlusActive: boolean;
};

const PlansCard = ({
  trainualPlusAmount,
  selectedPlan,
  plans,
  setSelectedPlanName,
  isTrainualPlusActive,
}: PlansCardProps) => {
  const { configs: publicConfigs } = usePublicConfigs();
  const { name, employeesSize, pricePerMonth, active, ctaKey, interval } = selectedPlan;
  const planTitle = usePlanTitle({ name });
  const { hasFreeTrainualPlusAddon } = useCurrentAccount();
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const talkToSalesLink = useGetTalkToSalesLink();
  const isCustomPlan = name === 'custom';
  const plansCardText = t(
    isTrainualPlusActive ? 'description_with_trainual_plus_html' : 'description'
  );

  const totalAmountWithTrainualPlus =
    isTrainualPlusActive && trainualPlusAmount && !hasFreeTrainualPlusAddon;
  const totalPlanAmount = totalAmountWithTrainualPlus
    ? pricePerMonth + trainualPlusAmount
    : pricePerMonth;

  const href = publicConfigs['DOWNGRADE_HELP_EMAIL_HREF'];

  const getCardTitle = () => {
    return (
      <>
        {isTrainualPlusActive && <TrainualPlusTitle>{t('trainual_plus')}</TrainualPlusTitle>}
        {planTitle}
        <PlansCardPlanText>{t('plan')}</PlansCardPlanText>
      </>
    );
  };

  const handleClickUpgradePlan = () => {
    if (ctaKey === 'talk_to_sales') {
      window.open(talkToSalesLink, '_blank');
    } else {
      navigateToBillingOverlay({ plan: name, interval });
    }
  };

  return (
    <PlansCardWrapper id='plans-card'>
      <PlansCardTabs
        plans={plans}
        selectedPlan={selectedPlan}
        setSelectedPlanName={setSelectedPlanName}
      />
      <PlansCardContentWrapper>
        <div>
          <PlansCardTitleWrapper>
            <PlansCardTitle planBrandColor={planBrandColor} text={getCardTitle()} />
            <PlansCardText id='employees-size'>{t('employees', { employeesSize })}</PlansCardText>
          </PlansCardTitleWrapper>
          <PlansCardText dangerouslySetInnerHTML={{ __html: plansCardText }} />
          <PlansCardFooter>
            {!isCustomPlan && (
              <PlansCardPriceWrapper>
                <PlansCardPrice>{getFormattedPrice(totalPlanAmount)}</PlansCardPrice>
                <PlansCardBillingInterval>{t('per_month')}</PlansCardBillingInterval>
              </PlansCardPriceWrapper>
            )}

            {active ? (
              <StyledActivePlanIndicator>{t('cta.active_plan')}</StyledActivePlanIndicator>
            ) : (
              <UpgradeButton
                buttonType='brand'
                id={`upgrade-to-${name}-billing-plan`}
                size='md'
                text={t(`cta.${ctaKey}`)}
                {...(ctaKey === 'contact_us'
                  ? { href, behavesAs: 'a', target: '_blank' }
                  : { onClick: handleClickUpgradePlan })}
              />
            )}
          </PlansCardFooter>
        </div>
        {isTrainualPlusActive && (
          <TrainualPlusBadgeWraper>
            <TrainualPlusBadge alt={t('alt_power_up')} src={TrainualPlusPowerup} />
          </TrainualPlusBadgeWraper>
        )}
      </PlansCardContentWrapper>
    </PlansCardWrapper>
  );
};

export default PlansCard;
